<template>
  <el-button :icon="btnIcon" @click="clickHandle()" :disabled="disabled">{{ btnName }}</el-button>
</template>
<script>
// 适用顶部工具栏里的按钮
export default {
  name: 'ToolbarButton',
  data () {
    return {

    }
  },
  /**
   * query：查询
   * view：查看
   * add：新增
   * addRoot:新增根节点
   * edit：修改
   * delete：删除
   * save：保存
   * import：导入
   * export：导出
   * upload：上传
   * download：下载
   * refresh：刷新
   * copy：复制
   * lock：锁定
   * unlock：解锁
   * print：打印
   */
  props: {
    role: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  created () {
  },
  computed: {
    btnName() {
      if (this.role == 'query') {
        return '查询'
      } else if (this.role == 'view') {
        return '查看'
      } else if (this.role == 'add') {
        return '新增'
      } else if (this.role == 'addRoot') {
        return '新增根节点'
      }else if (this.role == 'edit' || this.role == 'update') {// 兼容了this.role="update"的情况
        return '修改'
      } else if (this.role == 'delete') {
        return '删除'
      } else if (this.role == 'save') {
        return '保存'
      } else if (this.role == 'import') {
        return '导入'
      } else if (this.role == 'export') {
        return '导出'
      } else if (this.role == 'upload') {
        return '上传'
      } else if (this.role == 'download') {
        return '下载'
      } else if (this.role == 'refresh') {
        return '刷新'
      } else if (this.role == 'copy') {
        return '复制'
      } else if (this.role == 'lock') {
        return '锁定'
      } else if (this.role == 'unlock') {
        return '解锁'
      } else if (this.role == 'print') {
        return '打印'
      } else {
        return this.name
      }
    },
    btnIcon() {
      if (this.role == 'query') {
        return 'el-icon-search'
      } else if (this.role == 'view') {
        return 'el-icon-view'
      } else if (this.role == 'add') {
        return 'el-icon-plus'
      } else if (this.role == 'addRoot') {
        return 'el-icon-circle-plus-outline'
      }else if (this.role == 'edit' || this.role == 'update') {// 兼容了this.role="update"的情况
        return 'el-icon-edit'
      } else if (this.role == 'delete') {
        return 'el-icon-delete'
      } else if (this.role == 'save') {
        return 'el-icon-finished'
      } else if (this.role == 'import') {
        return 'el-icon-top-left'
      } else if (this.role == 'export') {
        return 'el-icon-top-right'
      } else if (this.role == 'upload') {
        return 'el-icon-upload2'
      } else if (this.role == 'download') {
        return 'el-icon-download'
      } else if (this.role == 'refresh') {
        return 'el-icon-refresh'
      } else if (this.role == 'copy') {
        return 'el-icon-document-copy'
      } else if (this.role == 'lock') {
        return 'el-icon-lock'
      } else if (this.role == 'unlock') {
        return 'el-icon-unlock'
      } else if (this.role == 'print') {
        return 'el-icon-printer'
      } else {
        return this.icon
      }
    }
  },
  methods: {
    clickHandle (evt) {
      this.$emit('click', evt)
    },
  }
}
</script>
