<template>
  <el-main>
    <div class="scale">
      <el-button icon="el-icon-plus" size="small" @click="scale += 10" :disabled="scale >= 150" circle></el-button>
      <span>{{ scale }}%</span>
      <el-button icon="el-icon-minus" size="small" @click="scale -= 10" :disabled="scale <= 40" circle></el-button>
    </div>
    <div class="design" :style="'transform: scale('+ scale / 100 +');'">
      <process-tree-viewer ref="process-tree" @selectedNode="nodeSelected"/>
    </div>
    <el-drawer class="my-drawer" :title="selectedNode.name" :visible.sync="showConfig" :modal-append-to-body="false" size="500px" direction="rtl" :modal="false" destroy-on-close>
      <el-card style="margin-bottom: 20px;">
        <div class="item-title">
          候选用户
        </div>
        <el-row>
          <el-col style="margin-top: 10px;" :span="6" v-for="u in candidateUsers" :key="u.id">
            <div v-if="u.type == 'user'" style="text-align: center;">
              <el-avatar :size="50" :src="u.headUrl"></el-avatar>
            </div>
            <div v-if="u.type == 'user'" class="user-name">{{ u.realName }}</div>
            <div v-if="u.type == 'tooMany'" class="too-many-user">用户太多，无法全部显示...</div>
          </el-col> 
        </el-row>
      </el-card>

      <el-card>
        <div class="item-title">
          已办用户
        </div>
        <el-row>
          <el-col style="margin-top: 10px;" :span="6" v-for="u in realUsers" :key="u.id">
            <div style="text-align: center;">
              <el-avatar :size="50" :src="u.headUrl"></el-avatar>
            </div>
            <div class="user-name">{{ u.realName }}</div>
          </el-col> 
        </el-row>
      </el-card>
    </el-drawer>
  </el-main>
</template>

<script>
import NodeConfig from '../../common/process/config/NodeConfig'
import ProcessTreeViewer from "@/views/modules/flowable/admin/layout/process/ProcessTreeViewer"
import avatar from '@/assets/img/avatar.png'
export default {
  name: "ProcessDesign",
  components: { ProcessTreeViewer, NodeConfig },
  data() {
    return {
      scale: 100,
      selected: {},
      showInput: false,
      showConfig: false,
      candidateUsers: [], // 候选办理人
      realUsers: [], // 实际办理过的人
    }
  },
  props: {
    processInstanceId: {
      default: '',
      type: String
    },
  },
  computed:{
    selectedNode(){
      return this.$store.state.selectedNode
    },
  },
  created() {
    this.$store.state.diagramMode = 'viewer'
  },
  beforeDestroy() {
    this.$store.state.diagramMode = 'design'
  },
  beforeMount() {
    // 改为prop组件属性传递
    //this.processInstanceId = this.$route.params.processInstanceId
  },
  mounted() {
    this.$http.post('/flowable/process/instanceInfo',
        {
          processInstanceId: this.processInstanceId
        }
    ).then(({ data: res }) => {
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      const form = { ...res.data.processTemplates }
      //form.logo = JSON.parse(form.logo)
      //form.settings = JSON.parse(form.settings)
      form.process = JSON.parse(form.process)
      this.$store.state.design = form
      this.$store.state.endList = res.data.endList
      this.$store.state.runningList = res.data.runningList
      this.$store.state.noTakeList = res.data.noTakeList
    })
  },
  
  methods: {
    // 点击各个节点，右侧显示该节点的办理人详情
    nodeSelected(node){
      if (node.type === 'ROOT' || node.type === 'APPROVAL') {
        this.showConfig = true
        this.candidateUsers = []
        this.realUsers = []
        // node.id -> definitionKey
        let loading = this.$loading({
          lock: true,
          target: '.my-drawer',
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.get(`/flowable/process/nodeUsers/${this.processInstanceId}/${node.id}`).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          let _candidateUsers = null // 候选办理人
          let _realUsers = null // 实际办理人
          if (res.data) {
            _candidateUsers = res.data.candidateUsers
            _realUsers = res.data.realUsers
          } else {
            _candidateUsers = node.props ? node.props.assignedUser : []
          }
          // 解析候选办理人
          if (_candidateUsers && _candidateUsers.length > 0) {
            _candidateUsers.forEach((item) => {
              this.candidateUsers.push({
                id: item.id,
                realName: item.realName,
                headUrl: item.headUrl ? item.headUrl : avatar,
                type: item.type // user 或 tooMany
              })
            })
          } else {
            this.candidateUsers = []
          }
          // 解析实际办理人
          if (_realUsers && _realUsers.length > 0) {
            _realUsers.forEach((item) => {
              this.realUsers.push({
                id: item.id,
                realName: item.realName,
                headUrl: item.headUrl ? item.headUrl : avatar,
              })
            })
          } else {
            this.realUsers = []
          }
        }).finally(() => {
          loading.close()
        })
      } else {
        this.showConfig = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
.design {
  margin-top: 100px;
  display: flex;
  transform-origin: 50% 0px 0px;
  height: calc(100vh - 250px);
}

.scale {
  z-index: 999;
  position: fixed;
  top: 130px;
  right: 100px;

  span {
    margin: 0 10px;
    font-size: 15px;
    color: #7a7a7a;
    width: 50px;
  }
}

.node-config-content{
  padding: 0 20px 20px;
}

/deep/ .el-drawer__body{
  overflow-y: auto;
}

/deep/ .el-drawer__header {
  font-size: 16px;
  font-weight: bold;
  color: #264653;
}
.item-title2 {
    width: 160px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOwAAAAoCAYAAAD9qha2AAAEIUlEQVR4nO3dz1MTZxwG8GeXmJAELAWGApFYdaRwsDrT/gXSmV6lM71ovfcf6LF4F8892bO0MziMHU8S1Bq0HTVhOnpqKF40ATb8MNsGyDTxYCdD3vfd5N3dvpyezwneHztvgO9k8+7DrvXtD68/++nh+rNGAy2++/okvvy8H7oszQ7PcYf72wxSdlmdj21JX3Q+ptfxLI9vPMd7dEjNHV6H6mcTeO3/17qP8PU3+3R/h/91aP1d+D2u5vEVx3kNIA2gDgsY7NGYfIg992D9+XgqsSV2ZPJSExGFlwJwMehkGwBODcfnxY78agXObi3EuojIw9WgE20A+LA3ci12zG7paDSApZXtkOsiIoVpAMkgE20AuHV/vTiZTr4SO+/leFpMZEAvgEtBJjbfVtNDsZti51qpitU31RDrIiIPgU6LmwVrW9aNvp5IXRywyM0nIhO+ADDid1KzYBceb+5PjCXz4oBMfhv1ekNsJqJwugBc9jupZacpNRC7IQ7YqtSQK1RCrIuIPHzjd0JLwc5nN+ZGB2J74qDFHHeLiQy44Lj41M8EW2wYTyWWxLbsyx1UD6SPt0QUnq93WalgB44fuyZGyfYO6si+2Am3LCJSueK4ch16kQbOZzeenVVEFRd5TZbIhFEAU7qDlZX98UfdUlQxV6ig/JZRRSIDtK/JKgu2LxmZUUUVM3luPhEZMO24elFFZcH+/OtGaTKdeCW2M0RBZEQPgK90Bnp+2D0x2P2j2PZXsYrVIqOKRAZo7RZ7Fmyky5r9IClHFTPcfCIyYcpxMdppkGfBLjze3J9MJ1fE9swKo4pEBmhFFdte/xnuj0pRxfLbGnIFN8S6iMhDx9PitgW7sLx5SxVV5O1jiIw477g4325Ax4TF2VT8vtj26MUO9hhVJDKh7btsx4L1iio+YlSRyITLZRddXp0dC/Z2dvPpmZE476pIdDTaRhW1QsenRuK3xbZcwWVUkcgMz6iiVsHGo/b3YlSxXm8gw7sqEpkwXXahvMW4VsHeeeKUPhmTo4oMURAZkcT7W6FKtP8P78RgTIoqrharWCsxqkhkgPK0WLtg/97797oqqsh7FxMZMVVWRBW1C/bhHzsHE2MJKaq4tLIN8UFaRBSaDeCKqlHbUF90VmxzdmvI866KRCZIp8W+CvaX35y54f6oFFW8x2uyRCacK7u4cLjBV8ECwHgq8UBsW2ZUkciUlqii74Lt64nMiFHFf/brWH65G25ZRKTSElX0XbB3njhPTyuiijwtJjJiBO+fwwMgQMECwMmhbimq+PzPCrYqjCoSGdDcfApUsPFY10w0IkcV+QBoIiMulV30AgEL9u7vTnFCEVVkiILIiGZUMVDBAsDogBxVLLypYq0kXfUhovCuAiEKdqtSmz2eYFSR6IhcLLtIvQN5OTAKay0LdwAAAABJRU5ErkJggg==) no-repeat;
    background-size: 100% 100%;
    padding: 0 23px 0 33px;
}

.item-title {
    color: #1056ce;
    font-size: 15px;
    font-weight: bold;
    border-left: 6px solid #1056ce;
    padding-left: 15px;
}

.user-name {
  text-align: center;
  font-size: 12px;
}
.too-many-user {
  line-height: 80px;
  height: 80px;
  width: 180px;
  vertical-align: bottom;
  color: red;
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
}
</style>
