<template>
  <!--
  显示下级数量
  show-count
  仅显示叶节点数量
  showCountOf="LEAF_DESCENDANTS"
  多选时，value数组以叶节点（而不是分支节点）组成
  valueConsistsOf="LEAF_PRIORITY"
  兄弟节点之间，分支节点排在叶节点前面
  branchNodesFirst
  不允许选中分支节点（只能选中叶节点）
  disableBranchNodes
  避免被el-dialog的header和footer遮住
  appendToBody
  zIndex="9999"
   -->
  <treeselect
      :multiple="multiple"
      show-count
      showCountOf="LEAF_DESCENDANTS"
      :options="options"
      :placeholder="myPlaceholder"
      :noOptionsText="noOptionsText"
      v-model="myValue"
      :deleteRemoves="false"
      :backspaceRemoves="false"
      :normalizer="normalizer"
      valueConsistsOf="LEAF_PRIORITY"
      :clearable="clearable"
      :searchable="filterable"
      :disabled="disabled"
      branchNodesFirst
      noResultsText="没有匹配项"
      disableBranchNodes
      @input="inputHandler"
      appendToBody
      zIndex="9999"
      clearAllText="全部清除"
  >
    <template v-slot:value-label="{node}">
      <div v-if="!node.raw.name">组织已删除</div>
      <div v-else-if="valueShowParent">{{ (deptMap.has(node.raw.pid) ? (deptMap.get(node.raw.pid).name + " / ") : '') + node.label }}</div>
      <div v-else>{{ node.label }}</div>
    </template>
  </treeselect>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: 'DeptSelect',
  data () {
    return {
      options: [],
      myValue: null,
      normalizer(node) {
        return {
          label: node.name,
        }
      },
      myPlaceholder: '数据加载中...',
      noOptionsText: '数据加载中...',
      deptMap: new Map()
    }
  },
  components: { Treeselect },
  mounted () {
    this.$http.get('/sys/dept/openapi/cascadeSelectData')
      .then(({ data: res }) => {
        if (res.code === 0) {
          // 组装选项树
          let deptList = res.data.deptList
          deptList.forEach((item) => {
            this.deptMap.set(item.id, item)
          })
          let root = []
          deptList.forEach((item) => {
            let pid = item.pid
            // pid == 0的部门为租户同名部门，设为根节点，并且不在选项中列出
            if (pid === '0') {
              root.push(item)
            }
            if (this.deptMap.has(pid)) {
              let parent = this.deptMap.get(pid)
              if (parent.children) {
                parent.children.push(item)
              } else {
                parent.children = [item]
              }
            }
          })

          let participantDeptList = res.data.participantDeptList
          participantDeptList.forEach((item) => {
            // 将children属性先删除（因为后台数据集中，没有子节点的数据也会包含children属性，值为空数组）
            item.children = undefined
            // 将deptId属性赋予pid，便于后续的显示
            item.pid = item.deptId
            this.deptMap.set(item.id, item)
            if (this.deptMap.has(item.deptId)) {
              let parent = this.deptMap.get(item.deptId)
              if (parent.children) {
                parent.children.push(item)
              } else {
                parent.children = [item]
              }
            }
          })

          this.options = root
          // 回显（此处回显是由于http请求的异步原因，必须在组装完选项树后进行一次手动的数据回显）
          if (this.multiple) {
            let idArr = []
            if (this.value) {
              idArr = this.value.split(',')
            }
            this.myValue = idArr
          } else {
            if (this.value) {
              this.myValue = this.value
            }
          }
          this.myPlaceholder = this.disabled ? '' : (this.placeholder || '请选择')
          this.noOptionsText = '无数据'
        }
      })
      .catch(() => {})
  },
  props: {
    value: String,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    filterable: {
      type: Boolean,
      default: true
    },
    // 默认单选，传入此属性则多选
    multiple: {
      type: Boolean,
      default: false
    },
    // 显示value时同时显示其父节点名称，默认显示
    valueShowParent: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    inputHandler(value, instanceId) {
      // 分为单选和多选的情况，进行双向绑定
      if (this.multiple) {
        this.$emit('input', value.join(','))
      } else {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    // 数据回显，分为单选和多选的情况，将绑定的value转换为级联选择组件所需的数据格式
    value: function (data) {
      // 组件加载时如果已经有value值，而选项数据还未组装完成，则组件框内会显示unknown，用户体验较差。因此在组装好选项数据后再进行回显
      if (this.options.length > 0) {
        if (this.multiple) {
          let idArr = []
          if (data) {
            idArr = data.split(',')
          }
          this.myValue = idArr
        } else {
          this.myValue = data
        }
      }
    },
  }
}
</script>
