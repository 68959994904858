window.SITE_CONFIG = {};
window.SITE_CONFIG['version'] = 'v3.2.0';
window.SITE_CONFIG['nodeEnv'] = process.env.VUE_APP_NODE_ENV;
window.SITE_CONFIG['apiURL'] = '';
window.SITE_CONFIG['poURL'] = '';
window.SITE_CONFIG['storeState'] = {};
window.SITE_CONFIG['contentTabDefault'] = {
  'name': '',
  'params': {},
  'query': {},
  'menuId': '',
  'title': '',
  'isTab': true,
  'iframeURL': ''
};
window.SITE_CONFIG['menuList'] = [];
window.SITE_CONFIG['permissions'] = [];
window.SITE_CONFIG['dynamicRoutes'] = [];
window.SITE_CONFIG['dynamicMenuRoutes'] = [];
window.SITE_CONFIG['dynamicMenuRoutesHasAdded'] = false;
window.SITE_CONFIG['subsystems'] = [
  {
    id: '0',
    name: ' ',
    isActive: true
  }
];

// AirCloud云渲染服务器地址和端口
window.SITE_CONFIG['dtsAssetsPath'] = 'D:/AirCityAssets';
// window.SITE_CONFIG['dtsHost'] = '58.246.177.206:12345';
var protocol = window.location.protocol
// 开发环境
if (process.env.VUE_APP_NODE_ENV === 'dev') {
  window.SITE_CONFIG['apiURL'] = 'http://192.168.0.3:8080';
  // window.SITE_CONFIG['apiURL'] = 'http://58.246.177.206:10006';
  // window.SITE_CONFIG['apiURL'] = 'http://api.opm.pmbim.com.cn';
  window.SITE_CONFIG['dtsHost'] = '192.168.0.9:8080';
  window.SITE_CONFIG['poURL'] = 'http://localhost:8080';
  //window.SITE_CONFIG['onlyofficeDocumentServerUrl'] = 'https://onlyoffice.pmbim.com.cn';
  window.SITE_CONFIG['onlyofficeDocumentServerUrl'] = 'https://onlyoffice.pmbim.com.cn';
}
// 集成测试环境
if (process.env.VUE_APP_NODE_ENV === 'prod:sit') {
  window.SITE_CONFIG['apiURL'] = protocol + '//api.opm.pmbim.com.cn';
  if (protocol == 'https:') {
    window.SITE_CONFIG['dtsHost'] = 'wss.pmbim.com.cn/wss';
  } else {
    window.SITE_CONFIG['dtsHost'] = 'ws.pmbim.com.cn/ws';
  }
  window.SITE_CONFIG['onlyofficeDocumentServerUrl'] = 'https://onlyoffice.pmbim.com.cn';
}
// 验收测试环境
if (process.env.VUE_APP_NODE_ENV === 'prod:uat') {
  window.SITE_CONFIG['apiURL'] = protocol + '//api.opm.pmbim.com.cn';
  if (protocol == 'https:') {
    window.SITE_CONFIG['dtsHost'] = 'wss.pmbim.com.cn/wss';
  } else {
    window.SITE_CONFIG['dtsHost'] = 'ws.pmbim.com.cn/ws';
  }
  window.SITE_CONFIG['onlyofficeDocumentServerUrl'] = 'https://onlyoffice.pmbim.com.cn';
}
// 生产环境
if (process.env.VUE_APP_NODE_ENV === 'prod') {
  window.SITE_CONFIG['apiURL'] = protocol + '//api.opm.pmbim.com.cn';
  if (protocol == 'https:') {
    window.SITE_CONFIG['dtsHost'] = 'wss.pmbim.com.cn/wss';
  } else {
    window.SITE_CONFIG['dtsHost'] = 'ws.pmbim.com.cn/ws';
  }
  window.SITE_CONFIG['onlyofficeDocumentServerUrl'] = 'https://onlyoffice.pmbim.com.cn';
}

// 东莞路桥
if (process.env.VUE_APP_NODE_ENV === 'prod:dglq') {
  // TODO 正式部署后，需要配置东莞路桥正式系统api网关的地址！
  window.SITE_CONFIG['apiURL'] = protocol + '//192.168.0.64:7000';
  if (protocol == 'https:') {
    window.SITE_CONFIG['dtsHost'] = 'wss.pmbim.com.cn/wss';
  } else {
    window.SITE_CONFIG['dtsHost'] = 'ws.pmbim.com.cn/ws';
  }
  window.SITE_CONFIG['onlyofficeDocumentServerUrl'] = 'https://onlyoffice.pmbim.com.cn';
}