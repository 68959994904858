export const BAR_MAP = {
  vertical: {
    offset: 'offsetHeight',
    key: 'vertical',
    size: 'height',
    wide: 'width',
    axis: 'Y',
    client: 'clientY',
    scroll: 'scrollTop',
    scrollSize: 'scrollHeight',
    direction: 'top',
  },
  horizontal: {
    offset: 'offsetWidth',
    key: 'horizontal',
    size: 'width',
    wide: 'height',
    axis: 'X',
    client: 'clientX',
    scroll: 'scrollLeft',
    scrollSize: 'scrollWidth',
    direction: 'left',
  },
};