<template>
  <el-dialog destroy-on-close title="选择给谁减签" :visible.sync="show" width="500px" v-bind="$attrs" v-on="$listeners" @close="handleCancel" @opened="handleOpen">
    <el-form v-loading="loading" ref="formRef" :model="formValue" :rules="rules">
      <el-form-item label="" prop="executionIds" required>
        <el-select size="small" v-model="formValue.executionIds" :multiple="true" placeholder="选择减签用户" style="width: 440px">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="mini" @click="handleCancel">取 消</el-button>
      <el-button size="mini" type="primary" :loading="loading" @click="handleConfirm">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "DeleteMultiModal",
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false,
    },
    processInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      formValue: {
        executionIds: []
      },
      rules: {
        executionIds: [
          { required: true, message: "请选择给谁减签", trigger: "change" },
        ],
      },
      options: [],
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      },
    },
  },
  methods: {
    handleConfirm() {
      this.$refs.formRef.validate((valid) => {
        if (!valid) return
        const params = {
          ...this.processInfo,
          ...this.formValue,
          currentUserInfo: this.$store.state.user
        }
        this.loading = true
        this.$http.post(`/flowable/process/deleteMulti`, params).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success("操作成功")
          this.handleCancel()
          this.$emit("success")
        }).catch(e => {
          return this.$message.error("运行出错：" + e)
        }).finally(() => {
          this.loading = false
        })
      })
    },
    // 取消操作
    handleCancel() {
      this.options = []
      this.$refs.formRef.resetFields()
      this.formValue = {
        executionIds: []
      }
      this.show = false
    },
    // 打开弹框操作
    handleOpen() {
      const { processInstanceId, taskId } = this.processInfo
      this.loading = true
      // 查询签上的人
      this.$http.post(`/flowable/process/queryMultiUsersInfo`, {processInstanceId, taskId}).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.options = (res.data || []).map((item) => {
          return {
            label: item.userName || item.userId,
            // 注意：选项的值是executionId与人员姓名拼接起来的，提交到后台需要拆分解析
            value: item.executionId + '_' + (item.userName || item.userId)
          }
        })
      }).finally(() => {
        this.loading = false
      })
    },
  },
};
</script>
