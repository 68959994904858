<template>
  <div>
		<span :class="['resrun-control','resrun-control-move','this-click']"
          :style="{left:element.position.left+'px',top:element.position.top+'px',
            width: element.size.width + 'px',
            height: element.size.height + 'px',
            '--primary':'#1F56B8',
            '--translucent':'#5589E2',
            '--background':'#ACC5F1',
            '--background-rgb':'172 197 241',
            ...cursorStyle}"
          @mousedown.salf="controlMovemousedown($event,element)"
          @mouseenter.stop="controlMouseover"
          @mouseleave="mouseoverMouseout">
			<div ref="input" :class="['control-signature','control-item','arow-content',
				element.controlClick?'click':'default']">
					<img :src="'data:image/png;base64,'+element.value" :height="element.size.height" style="pointer-events: none;"/>
			</div>
			<div class="control-handles">
				<div class="handle handle-top-left receive-color this-click"
             v-on:mousedown.salf="controlZoomMousedown($event,element,1)"
             v-if="element.controlClick && element.zoom"
        ></div>
				<div class="closeControl handle-top-right receive-color this-click"
             v-on:click.stop="controlDelete">
          <i class="el-icon-error" style="color:red;cursor:pointer;font-size: 18px"></i>
				</div>
				 
				<div class="handle handle-bottom-left receive-color this-click"
             v-on:mousedown.salf="controlZoomMousedown($event,element,2)"
             v-if="element.controlClick && element.zoom"
        ></div>
				<div class="handle handle-bottom-right receive-color this-click"
             v-on:mousedown.salf="controlZoomMousedown($event,element,3)"
             v-if="element.controlClick && element.zoom"
        ></div>
			</div>
		</span>
    <div class="signer-tips" v-if="element.controlClick && element.user.index != -1"
         :style="['left:' + element.position.left + 'px;top:' + (element.position.top + 10 + element.size.height) + 'px;', 'width:' + element.size.width + 'px']">
    </div>
  </div>
</template>

<script>
import {moveRange, currentPosition, currentPositionReverse, CanvasZoom} from "@/components/signature/components/ControlerMoveRange"

export default {
  name: 'control-item',
  data() {
    return {
      cursorStyle: 'cursor:move;',
      zoomType: 0,
      input: null,
      lineInput: null,
      linesInput: null,
      datePicker: null,
      dbclick: false,
      datePickerOpen: false,
      sealVisible: false,
      moveIn: false,
      moveTimeout: null,
      nowPoint: {
        x: 0,
        y: 0
      },
      eventMove: {
        x: 0,
        y: 0
      },
    }
  },
  props: {
    element: {
      type: Object
    },
    isSign: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    CanvasZoom() {
      return CanvasZoom
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.element.position.top = currentPositionReverse(this.element.position.top, this.element.position.page)
    },
    controlDelete(e) {
      this.$emit('controlDelete', this.element)
    },
    clearSeal() {
      this.$emit('clearSeal', this.element)
    },
    controlMovemousedown(e, element, falg) {
      this.nowPoint = {
        x: e.clientX,
        y: e.clientY
      }
      document.addEventListener('mousemove', this.moveControl)
      //松开鼠标清除移动函数事件
      document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', this.moveControl)
            this.removeMousedown()
          },
          {
            // 只绑定一次事件
            once: true,
          })
    },
    moveControl(e) {
      //当前鼠标位置
      let nowPos = {
        x: e.clientX,
        y: e.clientY,
      }
      //计算鼠标移动过的距离
      let dis = {
        x: nowPos.x - this.nowPoint.x,
        y: nowPos.y - this.nowPoint.y,
      }
      this.nowPoint = nowPos
      this.element.position.left += dis.x
      this.element.position.top += dis.y
    },
    removeMousedown() {
      let x = this.element.position.left + this.eventMove.x
      let y = this.element.position.top + this.eventMove.y
      const opt = {
        x: x,
        y: y,
        pageSize: this.element.pageSize,
        size: this.element.size,
        currentPage: 0,
      };
      moveRange(opt)
      const pageLeft = currentPosition(opt.y, opt.currentPage)
      this.element.position.left = opt.x
      this.element.position.top = opt.y
      this.element.position.page = opt.currentPage
    },
    controlZoomMousedown(e, element, type) {
      //移动方位不一致，位置计算也不相同  通过type区分
      this.zoomType = type
      //点击当前坐标点
      this.nowPoint = {
        x: e.clientX,
        y: e.clientY
      }
      //绑定移动事件
      document.addEventListener('mousemove', this.zoomControl)
      //松开鼠标清除移动函数事件
      document.addEventListener('mouseup', () => {
            //还原让控件可拖动
            document.removeEventListener('mousemove', this.zoomControl)
          },
          {
            // 只绑定一次事件
            once: true,
          })
    },
    zoomControl(e) {
      e.stopPropagation()
      //移动到新的坐标点
      let nowPos = {
        x: e.clientX,
        y: e.clientY,
      }
      //计算鼠标移动过的距离
      let dis = {
        x: nowPos.x - this.nowPoint.x,
        y: nowPos.y - this.nowPoint.y,
      }
      //将旧的坐标更换成新的坐标
      this.nowPoint = nowPos
      const nowPon = {
        width: 0,
        height: 0,
        left: 0,
        top: 0
      }
      if (this.zoomType == 3) {
        //横纵坐标 直接+
        nowPon.width = this.element.size.width + dis.x
        nowPon.height = this.element.size.height + dis.y
        nowPon.top = this.element.position.top
        nowPon.left = this.element.position.left
      }
      if (this.zoomType == 2) {
        nowPon.width = this.element.size.width + dis.x * -1
        nowPon.height = this.element.size.height + dis.y
        nowPon.left = this.element.position.left + dis.x
        nowPon.top = this.element.position.top
      }
      if (this.zoomType == 1) {
        nowPon.width = this.element.size.width + dis.x * -1
        nowPon.left = this.element.position.left + dis.x
        nowPon.height = this.element.size.height + dis.y * -1
        nowPon.top = this.element.position.top + dis.y
      }
      if (nowPon.width < this.element.size.minWidth) {
        nowPon.width = this.element.size.minWidth
        nowPon.left = this.element.position.left
      }

      if (nowPon.height < this.element.size.minHeight) {
        nowPon.height = this.element.size.minHeight
        nowPon.top = this.element.position.top
      }
      if (nowPon.left < 0) {
        nowPon.left = 0
      }
      if (nowPon.top < 0) {
        nowPon.top = 0
      }

      if (nowPon.width > this.CanvasZoom.width) {
        nowPon.width = this.CanvasZoom.width
      }
      if (nowPon.height > this.CanvasZoom.height) {
        nowPon.height = this.CanvasZoom.height
      }
      this.element.size.width = nowPon.width
      this.element.size.height = nowPon.height
      this.element.position.left = nowPon.left
      this.element.position.top = nowPon.top

    },
    controlMouseover(e) {
      //防止鼠标快速划过控件显示
      this.moveTimeout = setTimeout(() => {
        this.moveIn = true
      }, 100)
    },
    mouseoverMouseout(e) {
      if (!this.moveIn) {
        clearTimeout(this.moveTimeout)
      }
      this.moveIn = false
    },
  },
}
</script>

<style lang="less" scoped>
.resrun-control {
  user-select: none;
  cursor: move;
  position: absolute;
}

.resrun-control :hover {

}

.signer-tips {
  width: 100%;
  border: 1px solid #e3e3e3;
  background-color: #f8f8f8;
  position: absolute;
  top: calc(100% + 10px);
  text-align: center;
}

.control-required {
  position: absolute;
  height: 100%;
  left: -10px;
  top: 0;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: red
}

.control-item {
  position: relative;
}

.setting-sign {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  line-height: 30px;
  background-color: #eee;
}

.control-item.default {
  width: 100%;
  height: 100%;
  border: 1px;
  border-style: dashed;
  border-radius: 1px;
  border-color: var(--translucent);
  background-color: rgb(var(--background-rgb), 0.5)
}

.control-item.click {
  width: 100%;
  height: 100%;
  border: 1px;
  border-radius: 2px;
  border-style: solid;
  border-color: var(--translucent);
  background-color: #fff;
  background-color: transparent;
}

.control-item label {
  color: #a9a9a9;
  padding-left: 5px;
}

.control-handles {
  .handle {
    width: 5px;
    height: 5px;
    background-color: var(--primary);
    position: absolute;
    z-index: 999;
  }

  .closeControl {
    width: 18px;
    height: 18px;
    position: absolute;
    font-size: 20px;
    cursor: default;
    background-color: #fff;
    display: flex;
    border-radius: 50%;
  }

  .handle-top-left {
    top: -2px;
    left: -2px;
    cursor: nw-resize;
  }

  .handle-top-right {
    top: -7px;
    right: -7px;
  }

  .handle-bottom-left {
    bottom: -2px;
    left: -2px;
    cursor: sw-resize;
  }

  .handle-bottom-right {
    bottom: -2px;
    right: -2px;
    cursor: se-resize;
  }
}

.control-signature {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  span {
    pointer-events: none;
    display: block;
  }

  span:nth-child(1) {
    //line-height: 30px;
    height: 40px;
    padding-top: 5px;
  }
}

.control-seal {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  span {
    display: block;
    pointer-events: none;
  }

  span:nth-child(1) {
    height: 40px;
    padding-top: 5px;
  }

  span:nth-child(2) {
    line-height: 30px;
  }
}

.seal-modal {
  width: 200px;

  .seal-modal-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
  }

  .seal-item {
    border-style: dashed;
    border-color: #e0e0e0;
    border-width: 1px;
    padding: 20px 20px 0 20px;
    margin-bottom: 20px;
    cursor: pointer;

    .seal-img {
      width: 160px;
      height: 160px;
    }

    .seal-name {
      line-height: 30px;
      text-align: center;
    }
  }

  .seal-item:hover {
    border-color: #5589E2
  }
}

.control-sign-date, .control-date {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  span {
    line-height: 30px;
    pointer-events: none;
  }
}
</style>
<style>
.seal-popover .ant-popover-message-title {
  padding-left: 0px !important;
}
</style>