import { render, staticRenderFns } from "./TriggerNodeConfig.vue?vue&type=template&id=0353cfda&scoped=true"
import script from "./TriggerNodeConfig.vue?vue&type=script&lang=js"
export * from "./TriggerNodeConfig.vue?vue&type=script&lang=js"
import style0 from "./TriggerNodeConfig.vue?vue&type=style&index=0&id=0353cfda&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0353cfda",
  null
  
)

export default component.exports