<template>
  <el-cascader
    v-model="cascaderValue"
    :options="options"
    :props="props"
    :clearable="clearable"
    :filterable="filterable"
    :placeholder="placeholder"
    :disabled="disabled"
    :show-all-levels="JSON.parse(showAllLevels)"
    size="mini"
  >
    <template v-slot="{ data }">
      <span :style="data.type == 2 ? {fontWeight: 'bold'} : {fontWeight: 'light'}">{{ data.name }}</span>
    </template>
  </el-cascader>
</template>
<script>
export default {
  name: 'RoleSelect',
  data () {
    return {
      op: false,
      options: [],
      cascaderValue: [],
      props: {
        multiple: this.multiple,
        expandTrigger: 'hover',
        value: 'id',
        label: 'name',
        emitPath: false
      }
    }
  },
  created: function () {
    this.$http.get('/sys/role/openapi/briefs')
      .then(({ data: res }) => {
        if (res.code === 0) {
          this.options = res.data
          // 回显（此处回显是由于http请求的异步原因，必须在组装完选项树后进行一次手动的数据回显）
          if (this.props.multiple) {
            let idArr = this.value.split(',')
            this.cascaderValue = idArr
          } else {
            this.cascaderValue = this.value
          }
        }
      })
      .catch(() => {})
  },
  props: {
    value: String,
    placeholder: String,
    disabled: Boolean,
    clearable: Boolean,
    filterable: Boolean,
    multiple: Boolean, // 默认单选，传入此属性则多选
    // 默认显示完整组织-人员路径，传入false则只显示姓名
    showAllLevels: {
      type: [String, Boolean],
      default: true
    }
  },
  methods: {
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  watch: {
    // 数据回显，分为单选和多选的情况，将绑定的value转换为级联选择组件所需的数据格式
    value: function (data) {
      if (this.props.multiple) {
        let idArr = []
        if (data) {
          idArr = data.split(',')
        }
        this.cascaderValue = idArr
      } else {
        this.cascaderValue = data
      }
    },
    cascaderValue: function(data) {
      // 分为单选和多选的情况，进行双向绑定
      if (this.props.multiple) {
        if (data) {
          data = data.join(',')
        }
        this.$emit('change', data)
      } else {
        this.$emit('change', data)
      }
    }
  }
}
</script>
