<template>
  <!--
  文档
  https://www.javasoho.com/vuetreeselect/index_cn.html#node

  显示下级数量
  show-count

  仅显示叶节点数量
  showCountOf="LEAF_DESCENDANTS"

  多选时，value数组以叶节点（而不是分支节点）组成
  valueConsistsOf="LEAF_PRIORITY"

  兄弟节点之间，分支节点排在叶节点前面
  branchNodesFirst

  不允许选中分支节点（只能选中叶节点）（使用此属性，是因为人员数量太多（1.5W），若选中某些部门，会同时选中大量的人，造成浏览器卡死）
  disableBranchNodes

  避免被el-dialog的header和footer遮住
  appendToBody
  zIndex="9999"
   -->
  <treeselect
      :multiple="multiple"
      show-count
      showCountOf="LEAF_DESCENDANTS"
      :options="options"
      :placeholder="myPlaceholder"
      :noOptionsText="noOptionsText"
      v-model="myValue"
      :deleteRemoves="false"
      :backspaceRemoves="false"
      :normalizer="normalizer"
      valueConsistsOf="LEAF_PRIORITY"
      :clearable="clearable"
      :searchable="filterable"
      :disabled="disabled"
      branchNodesFirst
      noResultsText="没有匹配项"
      disableBranchNodes
      @input="inputHandler"
      appendToBody
      zIndex="9999"
      clearAllText="全部清除"
      :limit="100"
      :limitText="count => `等${count}人`"
  >
    <template v-slot:value-label="{node}">
      <div v-if="!node.raw.name">用户已删除</div>
      <div v-else-if="valueShowParent">{{ (deptMap.has(node.raw.deptId) ? (deptMap.get(node.raw.deptId).name + " / ") : '') + node.label }}</div>
      <div v-else>{{ node.label }}</div>
    </template>
  </treeselect>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'UserSelect',
  data() {
    return {
      options: [],
      myValue: null,
      normalizer(node) {
        return {
          label: node.name,
        }
      },
      myPlaceholder: '数据加载中...',
      noOptionsText: '数据加载中...',
      deptMap: new Map()
    }
  },
  components: {Treeselect},
  props: {
    value: String,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    filterable: {
      type: Boolean,
      default: true
    },
    // 默认单选，传入此属性则多选
    multiple: {
      type: Boolean,
      default: false
    },
    // 显示value时同时显示其父节点名称，默认显示
    valueShowParent: {
      type: Boolean,
      default: true
    },
    prjId: String, // 项目id。根据项目ID只查询该项目下的参建单位
    onlyParticipantUser: {  // 仅加载参建单位下的人员数据
      type: Boolean,
      default: false
    },
    onlyOwnerUser: {  // 仅加载业主组织结构下的人员数据
      type: Boolean,
      default: false
    }
  },
  watch: {
    // 数据回显，分为单选和多选的情况，将绑定的value转换为本组件所需的数据格式
    value: function (data) {
      // 因为人员数据量较大，组件加载时如果已经有value值，而选项数据还未组装完成，则组件框内会显示unknown，用户体验较差。因此在组装好选项数据后再进行回显
      if (this.options.length > 0) {
        if (this.multiple) {
          let userIdArr = []
          if (data) {
            userIdArr = data.split(',')
          }
          this.myValue = userIdArr
        } else {
          this.myValue = data
        }
      }
    },
  },
  mounted() {
    let userTypeId = '' // 人员类型 1：业主 2：参建单位
    if (this.onlyOwnerUser) {
      userTypeId = '1'
    }
    if (this.onlyParticipantUser) {
      userTypeId = '2'
    }
    // 父组件可以通过beforeLoad开启人员加载前的动作，比如：显示loading
    this.$emit('beforeLoad')
    this.$http.get('/sys/user/openapi/cascadeData', {
      params: {
        prjId: this.prjId,
        userTypeId: userTypeId
      }
    }).then(({data: res}) => {
      if (res.code === 0) {
        // 组装选项树
        let root = []
        let deptList = res.data.deptList
        // 先将所有部门节点设为不可选中，防止空部门被作为叶子节点选中
        deptList.forEach((item) => {
          item.isDisabled = true
          this.deptMap.set(item.id, item)
        })
        deptList.forEach((item) => {
          let pid = item.pid
          // pid == 0的部门为租户同名部门，设为根节点，并且不在选项中列出
          if (pid === '0') {
            root.push(item)
          }
          if (this.deptMap.has(pid)) {
            let parent = this.deptMap.get(pid)
            if (parent.children) {
              parent.children.push(item)
            } else {
              // 将有子部门的部门节点的不可选中状态取消
              parent.isDisabled = false
              parent.children = [item]
            }
          }
        })

        let participantDeptList = res.data.participantDeptList
        participantDeptList.forEach((item) => {
          item.isDisabled = true
          // 将children属性先删除（因为后台数据集中，没有子节点的数据也会包含children属性，值为空数组）
          item.children = undefined
          this.deptMap.set(item.id, item)
          if (this.deptMap.has(item.deptId)) {
            let parent = this.deptMap.get(item.deptId)
            if (parent.children) {
              parent.children.push(item)
            } else {
              // 将有子部门的部门节点的不可选中状态取消
              parent.isDisabled = false
              parent.children = [item]
            }
          } else {
            root.push(item)
          }
        })

        let userList = res.data.userList
        userList.forEach((item) => {
          item.name = item.realName
          let deptId = item.deptId
          if (this.deptMap.has(deptId)) {
            let dept = this.deptMap.get(deptId)
            if (dept.children && dept.children.length > 0) {
              dept.children.push(item)
            } else {
              // 将有人员的部门节点的不可选中状态取消
              dept.isDisabled = false
              dept.children = [item]
            }
          }
        })
        this.options = root
        // 回显（此处回显是由于http请求的异步原因，必须在组装完选项树后进行一次手动的数据回显）
        if (this.multiple) {
          let userIdArr = []
          if (this.value) {
            userIdArr = this.value.split(',')
          }
          this.myValue = userIdArr
        } else {
          if (this.value) {
            this.myValue = this.value
          }
        }
        this.myPlaceholder = this.disabled ? '' : (this.placeholder || '请选择')
        this.noOptionsText = '无数据'
      }
      // 父组件可以通过afterLoad实现人员加载后的动作，比如：关闭loading
      this.$emit('afterLoad')
    }).catch(() => {
      // 父组件可以通过afterLoad实现人员加载后的动作，比如：关闭loading
      this.$emit('afterLoad')
    })
  },
  methods: {
    inputHandler(value, instanceId) {
      // 分为单选和多选的情况，进行双向绑定
      if (this.multiple) {
        this.$emit('input', value.join(','))
      } else {
        this.$emit('input', value)
      }
    }
  },
}
</script>
