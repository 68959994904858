import dialogDrag from '@/directives/dialog-drag'
import echartResize from '@/directives/echart-resize'

// 定义全局的过滤器、全局指令、全局混入
export default {
  install (Vue) {
    // 示例：定义全局过滤器
    Vue.filter('过滤器名称', function (value) {

    })

    // 示例：定义全局指令
    Vue.directive('指令名称', {
      bind (element, binding) {

      },
      inserted (element, binding) {

      },
      update (element, binding) {

      }
    })

    // 注册指令 el-dialog 放大缩小和拖拽的指定
    Vue.directive('dialog-drag', dialogDrag)
    // 注册指令 echart图表大小的自适应功能
    Vue.directive('echart-resize', echartResize)

    // 定义全局的混入
    Vue.mixin({
      // ...
    })

    // 在Vue原型上添加方法，这样所有组件就都能使用这个方法
    Vue.prototype.test = () => {
      // ...
    }
  }
}
