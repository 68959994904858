<template>
  <!--Vue中transition没有name属性时，会自动匹配v-enter-active和v-leave-active样式-->
  <!--所以这里为transition设置name属性的目的是避免与其他功能中也用到动画样效果时的额样式冲突-->
  <transition name="el-fade-in-linear">
    <router-view />
  </transition>
</template>

<script>
import Cookies from 'js-cookie'
import { messages } from '@/i18n'
export default {
  watch: {
    '$i18n.locale': 'i18nHandle'
  },
  created () {
    this.i18nHandle(this.$i18n.locale)
  },
  methods: {
    i18nHandle (val, oldVal) {
      Cookies.set('language', val)
      document.querySelector('html').setAttribute('lang', val)
      document.title = messages[val].brand.lg
      // 非登录页面，切换语言刷新页面
      if (this.$route.name !== 'login' && oldVal) {
        window.location.reload()
      }
    }
  }
}
</script>
<!--以下样式可以配合Vue的transition标签实现左侧菜单显示的动画效果-->
<!--
<style>
  .v-enter-active {
    animation: test 1s;
  }

  .v-leave-active {
    animation: test 1s reverse;
  }

  @keyframes test {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0px);
    }
  }
</style>
-->
<!--
  但一般我们不自己手写动画样式，因为有更优秀的第三方库，比如，animate.css 这个库。
  （1）npm i animate.css --save
  （2）import 'animate.css'
  （3）<transition
        name="animate__animated animate__bounce"
        enter-active-class="animate__swing"
        leave-active-class="animate__backOutUp"
      >...
-->
