<template>
  <vxe-modal ref="vxeModal" width="1030" height="580" :position="position" :lockView="false" :mask="false" v-model="visible" :resize="false" v-on="$listeners">
    <template #title>
      <span>
        <!-- <i class="el-icon-location-outline"></i> -->
        <div>{{ dialogTitle }}</div>
      </span>
    </template>
    <template #default>
      <div v-if="ifcContainerVisible" ref="ifcContainer" style="width: 1000px; height: 500px"></div>
      <!-- 构件属性 -->
      <ifc-prop v-if="ifcPropVisible" ref="ifcProp" @hide="closeIfcPropHandle"></ifc-prop>
    </template>
  </vxe-modal>
</template>
<script>
  import Cookies from 'js-cookie'
  export default {
    name: 'IfcViewer',
    data () {
      return {
        dataForm: {
          prjId: '',
          wbsId: '',
        },
        visible: false,
        position: {
          top: 60,
          left: `calc(100% - 1100px)`
        },
        typeId: 'ifc',
        loading: null,
        ifcUrl: '', // 加载ifc时的文件路径
        ifcPropVisible: false,
        ifcContainerVisible: false,
        dialogTitle: '查看模型',
        ifcInstance: null
      }
    },
    mounted () {
      this.ifcContainerVisible = true
      this.$nextTick(() => {
        
      })
    },
    async beforeDestroy () {
      if(this.ifcInstance) {
        await this.ifcInstance.releaseMemory()
      }
    },
    methods: {
      init(wbsId) {
        this.dataForm.wbsId = wbsId
        this.visible = true
        this.$nextTick(async () => {
          if (!this.ifcInstance) {
            this.ifcInstance = new threeIfcScene.Instance({
              ele: this.$refs.ifcContainer,
              width: 1000,
              height: 500,
              startLoading: (txt)=>{
                this.loading = this.$loading({
                  lock: true,
                  text: txt,
                  spinner: 'el-icon-loading',
                  customClass: 'my-loading',
                  background: 'rgba(0, 0, 0, 0.7)'
                })
              },
              stopLoading: ()=>{
                this.loading.close()
              },
              onPickCallback: (expressId, props, propSets)=>{
                this.openPropModal(expressId, props, propSets)
              }
            });
          }
          
          this.loading = this.$loading({
            text: '',
            spinner: "aircity-loading",
            customClass: 'aircity-loading',
            background: 'rgba(0, 0, 0, 0.4)'
          })
          
          // 从服务器获取wbs最新的ifc文件地址URL
          let {data: res} = await this.$http.get(`/mps/wbs/${this.dataForm.wbsId}?withIfcUrl=1`)
          if (res.code !== 0) {
            this.loading.close()
            return this.$message.error(res.msg)
          }
          if (!res.data) {
            this.loading.close()
            return this.$message.error('没有相关数据')
          }
          this.dataForm.prjId = res.data.prjId
          let ifcUrl = res.data ? res.data.ifc : ''
          if (!ifcUrl) {
            this.loading.close()
            this.dialogTitle = '未上传模型文件'
            return this.$message.warning("未上传模型！")
          } else {
            this.dialogTitle = '模型查看'
            // 查询wbs和ifc的关系
            let wbsIfcData = null
            let {data: res} = await this.$http.get(`/mps/wbsIfc?wbsId=${this.dataForm.wbsId}`)
            if (res.code === 0) {
              wbsIfcData = res.data
            }
            // 渲染模型
            await this.renderModel(ifcUrl, wbsIfcData)
          }
        })
      },
      /**
       * 渲染模型
       * @param ifcUrl ifc文件路径
       * @param wbsIfcData wbs和ifc的关系
       */
      async renderModel (ifcUrl, wbsIfcData) {
        this.ifcUrl = ifcUrl
        if (ifcUrl) {
          // 获取ifc文件地址的签名URL
          let ifcSignedUrl = await this.getIfcSignedUrl(ifcUrl)
          if(ifcSignedUrl) {
            await this.ifcInstance.loadIfc(ifcSignedUrl, wbsIfcData, ()=>{
              if (this.loading) {
                this.loading.close()
              }
            })
          } else {
            if (this.loading) {
              this.loading.close()
            }
          }
        } else {
          if (this.loading) {
            this.loading.close()
          }
        }
      },
      // 获取ifc文件地址的签名URL
      async getIfcSignedUrl (ifcUrl) {
        let {data: res} = await this.$http.get('/oss/file/signedUrl?url=' + ifcUrl)
        if (res.code !== 0) {
          this.$message.error(res.msg)
          return null
        } else {
          return res.data
        }
      },
      // 打开构件属性面板
      openPropModal(expressId, props, propSets) {
        this.ifcPropVisible = true
        this.$nextTick(() => {
          this.$refs.ifcProp.init(this.dataForm.prjId, expressId, this.ifcUrl, props, propSets)
        })
      },
      closeIfcPropHandle () {
        this.ifcPropVisible = false
      },
    }
  }
</script>
