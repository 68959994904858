var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{class:['resrun-control','resrun-control-move','this-click'],style:({left:_vm.element.position.left+'px',top:_vm.element.position.top+'px',
            width: _vm.element.size.width + 'px',
            height: _vm.element.size.height + 'px',
            '--primary':'#1F56B8',
            '--translucent':'#5589E2',
            '--background':'#ACC5F1',
            '--background-rgb':'172 197 241',
            ..._vm.cursorStyle}),on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"salf",undefined,$event.key,undefined))return null;return _vm.controlMovemousedown($event,_vm.element)},"mouseenter":function($event){$event.stopPropagation();return _vm.controlMouseover.apply(null, arguments)},"mouseleave":_vm.mouseoverMouseout}},[_c('div',{ref:"input",class:['control-signature','control-item','arow-content',
				_vm.element.controlClick?'click':'default']},[_c('img',{staticStyle:{"pointer-events":"none"},attrs:{"src":'data:image/png;base64,'+_vm.element.value,"height":_vm.element.size.height}})]),_c('div',{staticClass:"control-handles"},[(_vm.element.controlClick && _vm.element.zoom)?_c('div',{staticClass:"handle handle-top-left receive-color this-click",on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"salf",undefined,$event.key,undefined))return null;return _vm.controlZoomMousedown($event,_vm.element,1)}}}):_vm._e(),_c('div',{staticClass:"closeControl handle-top-right receive-color this-click",on:{"click":function($event){$event.stopPropagation();return _vm.controlDelete.apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-error",staticStyle:{"color":"red","cursor":"pointer","font-size":"18px"}})]),(_vm.element.controlClick && _vm.element.zoom)?_c('div',{staticClass:"handle handle-bottom-left receive-color this-click",on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"salf",undefined,$event.key,undefined))return null;return _vm.controlZoomMousedown($event,_vm.element,2)}}}):_vm._e(),(_vm.element.controlClick && _vm.element.zoom)?_c('div',{staticClass:"handle handle-bottom-right receive-color this-click",on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"salf",undefined,$event.key,undefined))return null;return _vm.controlZoomMousedown($event,_vm.element,3)}}}):_vm._e()])]),(_vm.element.controlClick && _vm.element.user.index != -1)?_c('div',{staticClass:"signer-tips",style:(['left:' + _vm.element.position.left + 'px;top:' + (_vm.element.position.top + 10 + _vm.element.size.height) + 'px;', 'width:' + _vm.element.size.width + 'px'])}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }