<template>
    <el-container direction="vertical">
      <div style="text-align: center;">
        <vxe-button v-if="btnVisible" size="mini" @click="clickHandle()" :status="btnType" style="width: 95px;">
          <template #icon>
            <i :class="btnIcon"></i> {{btnText}}
          </template>
        </vxe-button>

        <el-dialog top="5vh" title="发起审批" :width="dialogWidth" :visible.sync="bindingDefinitionsVisible" append-to-body :close-on-click-modal="false">
          <sponsor-process ref="sponsorProcess" :templateList="templateList" v-if="bindingDefinitionsVisible"></sponsor-process>
          <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="bindingDefinitionsVisible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="sendProcessHandle">提 交</el-button>
          </span>
        </el-dialog>
        <!-- 流程审批记录和流程图 -->
        <process-detail v-if="processDetailComponentVisible" ref="processDetail" @close="closeProcessDetailHandle()"></process-detail>
      </div>
    </el-container>
</template>
<script>
import debounce from 'lodash/debounce'
import SponsorProcess from "./SponsorProcess"
export default {
  name: 'ProcessStatus',
  components: { SponsorProcess },
  props: {
    biz: Object, // 业务表单对象（id、processInstanceId、processStatus）
    procTitle: String, // 流程主题
    btnSize: {
      type: String,
		  default: 'mini'
    },
    btnClass: {
      type: String,
      default: 'my-btn-class'
    }
  },
  data () {
    return {
      businessKey: '', // 业务主表ID，对应prop中的bizId
      processInstanceId: '', // 流程实例ID
      processStatus: -1, // 流程状态
      btnVisible: false,
      btnText: '发起流程',
      btnType: 'primary', // 该参数作废（el-button换成了vxe-button）
      btnIcon: '',
      // 审批记录（带有流程图）组件
      processDetailComponentVisible: false,
      // 发起流程时选择流程模板、填写流程主题的组件
      bindingDefinitionsVisible: false,
      templateList: [], // 当前用户可用的流程模板列表
      dialogWidth: '1000px' // 动态计算弹窗的宽度
    }
  },
  created () {
    this.$nextTick(() => {
      this.businessKey = this.biz.id
      this.processInstanceId = this.biz.processInstanceId
      this.processStatus = this.biz.processStatus
      // 根据流程状态动态控制各个按钮的显示
      this.buttonsVisibleHandle()
    })
  },
  mounted() {
    // 分辨率大和分辨率小的时候，发起流程的弹窗宽度都要合理
    if (window.innerWidth <= 1024) {
      this.dialogWidth = (window.innerWidth * 0.9) + 'px'
    } else {
      this.dialogWidth = '1024px'
    }
  },
  watch: {
    biz (row) {
      this.refresh(row)
    },
  },
  methods: {
    refresh(e){
      this.businessKey = e.id
      this.processInstanceId = e.processInstanceId
      this.processStatus = e.processStatus
      this.buttonsVisibleHandle()
    },
    buttonsVisibleHandle () {
      if (this.processStatus == null) { // 无状态信息
        this.btnVisible = true
        this.btnText = '发起流程'
        this.btnType = ''
        this.btnIcon = ''
      } else if (this.processStatus === -1) { // 未发起
        this.btnVisible = true
        this.btnText = '发起流程'
        this.btnType = ''
        this.btnIcon = ''
      } else if (this.processStatus === 0) { // 已发起
        this.btnVisible = true
        this.btnText = '已发起'
        this.btnType = 'primary'
        this.btnIcon = '' // my-icon-filesearch
      } else if (this.processStatus === 1) { // 运行中
        this.btnVisible = true
        this.btnText = '审批中'
        this.btnType = 'primary'
        this.btnIcon = '' // my-icon-filesearch
      } else if (this.processStatus === 2) { // 已通过
        this.btnVisible = true
        this.btnText = '已通过'
        this.btnType = 'success'
        this.btnIcon = '' // my-icon-filesearch
      } else if (this.processStatus === 3) { // 未通过
        this.btnVisible = true
        this.btnText = '未通过'
        this.btnType = 'danger'
        this.btnIcon = '' // my-icon-filesearch
      } else if (this.processStatus === 4) { // 被驳回
        this.btnVisible = true
        this.btnText = '驳回中'
        this.btnType = 'warning'
        this.btnIcon = '' // my-icon-filesearch
      } else if (this.processStatus === 5) { // 被退回
        this.btnVisible = true
        this.btnText = '退回中'
        this.btnType = 'warning'
        this.btnIcon = '' // my-icon-filesearch
      } else if (this.processStatus === 7) { // 撤销
        this.btnVisible = true
        this.btnText = '已撤销'
        this.btnType = 'danger'
        this.btnIcon = ''
      } else if (this.processStatus === 9) { // 流程已删除
        this.btnVisible = true
        this.btnText = '被删除'
        this.btnType = 'danger'
        this.btnIcon = ''
      } else { // 未知状态
        this.btnVisible = false
        this.btnText = '未知'
      }
    },
    clickHandle () {
      if (typeof this.$listeners['beforeProcessSend'] === 'function') {
        this.$listeners['beforeProcessSend'](this.beforeProcessSendCallback)
      } else {
        this.beforeProcessSendCallback()
      }
    },
    beforeProcessSendCallback () {
      let loading = this.$loading({
        lock: true,
        text: '运行中...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      if (this.btnText === '发起流程') {
        this.beforeSendProcess()
        loading.close()
      } else if (this.btnText === '被删除' || this.btnText === '已撤销') {
        loading.close()
        this.$confirm(`${this.btnText}，是否再次发起新的流程？`, this.$t('prompt.title'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
          this.beforeSendProcess()
          loading.close()
        }).catch(e => e)
      } else {
        loading.close()
        if (this.processStatus === 4) {
          this.$message({
            message: '请起草人到待办流程中处理该流程。',
            type: 'error',
            duration: 5000
          })
        }
        this.showProcessDetail()
      }
    },
    beforeSendProcess () {
      if (!this.businessKey) {
        return this.$message.error('属性缺失（biz），无法发起流程！')
      }
      let menuRoute = this.$route.path
      if (menuRoute.startsWith('/')) {
        menuRoute = menuRoute.substring(1, menuRoute.length)
      }
      let loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 查询绑定的所有流程模板
      this.$http.get(`/flowable/process/processTemplates/${menuRoute}`).then(({ data: res }) => {
        loading.close()
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (!res.data || res.data.length === 0) {
          return this.$message.error('没有可用的流程模板！')
        } else {
          this.templateList = res.data
          // 打开流程模板选择窗口，并且填写流程主题
          this.bindingDefinitionsVisible = true
          this.$nextTick(() => {
            this.$refs.sponsorProcess.dataForm.processTitle = this.procTitle
          })
        }
      }).catch(() => {
        loading.close()
      })
    },
    // 发起流程
    sendProcessHandle: debounce(function () {
      let form = this.$refs.sponsorProcess.getForm()
      let processUser = this.$refs.sponsorProcess.getProcessUser()
      this.$refs.sponsorProcess.validate((valid) => {
        if (valid) {
          let uneditedNodeCount = this.$refs.sponsorProcess.getUneditedNodeCount()
          if (uneditedNodeCount > 0) {
            this.$confirm(`有${uneditedNodeCount}个节点未指定办理人，是否继续发起流程？`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.doSend(form, processUser)
            })
          } else {
            this.doSend(form, processUser)
          }
        } else {
          this.$message.warning("请完善表单信息!")
        }
      })
    }, 1000, {'leading': true, 'trailing': false}),

    doSend(form, processUser) {
      let loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$http.post(`/flowable/process/start`, {
        processDefinitionId: form.processDefinitionId,
        processTitle: form.processTitle,
        menuId: form.menuId,
        businessKey: this.businessKey,
        processUsers: processUser,
        // startUserInfo: this.$store.state.user, // 后端自动获取当前登录人信息，所以前端不用传递登录人信息到后端了。
      }).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.processStatus = 1
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 1000,
          onClose: () => {
            this.$emit('refreshDataList', {processInstanceId: res.data, processStatus: 1})
            this.bindingDefinitionsVisible = false
          }
        })
      }).finally(() => {
        loading.close()
      })
    },

    // 查看流程详情（没有表单的）
    showProcessDetail () {
      if (this.processInstanceId) {
        this.processDetailComponentVisible = true
        this.$nextTick(() => {
          this.$refs.processDetail.dataForm.processInstanceId = this.processInstanceId
          this.$refs.processDetail.init()
        })
      }
    },
    closeProcessDetailHandle () {
      this.processDetailComponentVisible = false
    },
  }
}
</script>
<style scoped>
</style>