<template>
  <div>
<!--    流程模板的发起人权限设置挪到了基础信息中，此处不再能设置 -->
  </div>
</template>

<script>

export default {
  name: "RootConfig",
  props:{
    config:{
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="less" scoped>
.desc{
  font-size: small;
  color: #8c8c8c;
}
.org-item{
  margin: 5px;
}
</style>
