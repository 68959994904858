<template>
  <el-button size="mini" @click="openSignDialog()">
    {{ name }}
    <vxe-modal width="95%" height="95%" v-model="signVisible" destroy-on-close showFooter transfer>
      <template #default>
        <pdf-sign ref="sign" :ossId="ossId" @signSuccess="signVisible = false"></pdf-sign>
      </template>
      <template #footer>
        <vxe-button content="取消" @click="signVisible = false"></vxe-button>
        <vxe-button content="签署" status="primary" @click="sign()"></vxe-button>
      </template>
    </vxe-modal>
  </el-button>

</template>

<script>
import PdfSign from '@/components/signature/pdf-sign'

export default {
  name: 'PdfSignButton',
  data() {
    return {
      signVisible: false,
    }
  },
  props: {
    ossId: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      default: '电子签名'
    }
  },
  components: {
    PdfSign
  },
  methods: {
    sign() {
      this.$refs.sign.signDocument()
    },
    openSignDialog() {
      if (this.ossId) {
        this.signVisible = true
      } else {
        this.$message.error("缺少必要参数，无法打开签名窗口")
      }
    },
  },
}


</script>