<template>
  <el-dialog top="5vh" width="60%" :visible.sync="visible" title="[形象单元选择]" :close-on-click-modal="false"
             :close-on-press-escape="false" v-on="$listeners">
    <el-row :gutter="30">
      <el-col :span="6">
        <el-tree
            class="leftTree"
            v-loading="treeLoading"
            :data="treeData"
            node-key="id"
            :props="treeProps"
            ref="tree"
            highlight-current
            @node-click="treeNodeClick">
        </el-tree>
      </el-col>
      <el-col :span="18">
        <vxe-table
            ref="xTable"
            stripe
            border
            show-header-overflow
            show-overflow
            :row-config="{keyField: 'id',isHover: true,isCurrent: true}"
            :column-config="{resizable: true}"
            :checkbox-config="{trigger: 'row',checkMethod: selectable,highlight: false,showHeader: false}"
            :row-style="rowFilter"
            :max-height="tableHeight"
            :data="listData">
          <vxe-column type="checkbox" width="60" align="center"></vxe-column>
          <vxe-column field="name" title="形象单元"></vxe-column>
          <vxe-column field="unitName" title="单位"></vxe-column>
          <vxe-column field="typeName" title="类型"></vxe-column>
        </vxe-table>
      </el-col>
    </el-row>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
// 形象单元多选
import debounce from 'lodash/debounce'

export default {
  name: 'ScheduleunitMultiSelect',
  data() {
    return {
      visible: false,
      dataForm: {
        prjId: '',
        typeId: '',
      },
      treeProps: {
        children: 'children',
        label: 'name',
      },
      treeData: [],
      listData: [],
      existIdList: [],
      existIdSet: new Set(),
      treeLoading: false,
      tableLoading: false,
      tableHeight: 500,
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.getTreeData()
        this.getListData()
      })
    },
    treeNodeClick(data, node, ele) {
      // 点击高亮节点则取消高亮，并查全部的数据
      if (this.dataForm.typeId == data.id) {
        this.dataForm.typeId = ''
        this.$refs.tree.setCurrentKey(null)
      } else {
        this.dataForm.typeId = data.id
      }
    },
    getTreeData() {
      this.treeLoading = true
      this.$http.get(
          '/opm/scheduleUnitType/list',
          {
            params: {
              prjId: this.dataForm.prjId,
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.treeData = res.data
        this.dataForm.typeId = ''
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.treeLoading = false
      })
    },
    getListData() {
      this.tableLoading = true
      this.$http.get(
          '/opm/scheduleUnit/list',
          {
            params: {
              prjId: this.dataForm.prjId,
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.listData = res.data
        this.existIdSet = new Set(this.existIdList)
        this.$nextTick(() => {
          if (this.existIdSet.size > 0) {
            for (let obj of this.listData) {
              if (this.existIdSet.has(obj.id)) {
                this.$refs.xTable.setCheckboxRow(obj, true)
              }
            }
          }
        })

      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.tableLoading = false
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      let result = []
      for (let row of this.$refs.xTable.getCheckboxRecords()) {
        if (!this.existIdSet.has(row.id)) {
          result.push(row)
        }
      }
      this.visible = false
      this.$emit('submit', result)
    }, 1000, {'leading': true, 'trailing': false}),

    rowFilter({row}) {
      if (!this.dataForm.typeId || row.typeId == this.dataForm.typeId) {
        return ''
      } else {
        return {display: 'none'}
      }
    },

    selectable({row}) {
      if (this.existIdSet.has(row.id)) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>
<style scoped>
</style>