<template>
  <el-card shadow="hover">
    <el-upload
      :file-list="fileList"
      :action="actionUrl"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-change="handleChange"
      :before-upload="beforeAvatarUpload"
      list-type="picture"
      accept="image/jpg,image/jpeg,image/png">
      <el-button size="mini" type="primary">点击上传</el-button>
      <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
    </el-upload>
  </el-card>
</template>
<script>
import Cookies from 'js-cookie'
export default {
  name: 'MyAvatar', // 组件中的name属性只是在开发者工具中起作用
  props: {
    avatarUrl: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      fileList: [],
      actionUrl: `${window.SITE_CONFIG['apiURL']}/oss/file/uploadPhoto?access_token=${Cookies.get('access_token')}&tenant_code_key=${Cookies.get('tenantCode')}`
    }
  },
  watch: {
    avatarUrl: function () {
      this.init()
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    init () {
      if (this.avatarUrl && this.avatarUrl.length > 0) {
        this.$http.get(`/oss/file/signedUrl?url=${this.avatarUrl}`)
          .then(({ data: res }) => {
            if (res.code === 0) {
              this.fileList = [{ name: '图片', url: res.data }]
            }
          })
          .catch(() => { })
      }
    },
    handleRemove (file, fileList) {
      this.$emit('setAvatarUrl', '')
    },
    handleSuccess (res, file, fileList) {
      this.$emit('setAvatarUrl', file.response.data)
    },
    handleChange (file, fileList) {
      // 只允许上传一个
      if (fileList.length > 1) {
        fileList.splice(0, 1)
      }
    },
    beforeAvatarUpload (file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg'
      const extension2 = testmsg === 'png'
      const extension4 = testmsg === 'jpeg'
      if (!extension && !extension2) {
        this.$message({
          message: '只能上传 jpg, jpeg, png格式的图片!',
          type: 'warning'
        })
        return false
      }
      const isLt3M = file.size / (1024 * 1024) < 3
      if (!isLt3M) {
        this.$message({
          message: '上传的头像大小不能超过 3MB',
          type: 'warning'
        })
      }
      return (extension || extension2 || extension4) && isLt3M
    }
  }
}
</script>
