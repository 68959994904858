import Vue from 'vue'
import Router from 'vue-router'
import http from '@/utils/request'
import { isURL } from '@/utils/validate'
import { HashMap } from '@/utils'
import Cookies from "js-cookie";
Vue.use(Router)

// 页面路由(独立页面)
export const pageRoutes = [
  {
    path: '/404',
    component: () => import('@/views/pages/404'),
    name: '404',
    meta: { title: '404未找到' },
    beforeEnter (to, from, next) {
      // 拦截处理特殊业务场景
      // 如果, 重定向路由包含__双下划线, 为临时添加路由
      if (/__.*/.test(to.redirectedFrom)) {
        return next(to.redirectedFrom.replace(/__.*/, ''))
      }
      next()
    }
  },
  {
    path: '/login',
    component: () => import('@/views/pages/login'),
    name: 'login',
    meta: { title: '登录' }
  },
  {
    path: '/dts',
    component: () => import('@/views/pages/dts'),
    name: 'dts',
    meta: { title: '三维数字孪生平台' }
  },
  {
    path: '/dts-bind',
    component: () => import('@/views/pages/dts-bind'),
    name: 'dts-bind',
    meta: { title: '模型挂接平台' }
  },
  {
    path: '/dts-draw-vehicle-waypoint',
    component: () => import('@/views/pages/dts-draw-vehicle-waypoint'),
    name: 'dts-draw-vehicle-waypoint',
    meta: { title: '车载路线绘制平台' }
  },
  {
    path: '/document-editor',
    component: () => import('@/views/pages/document-editor/document-editor.vue'),
    name: 'document-editor',
    meta: { title: '文档编辑器' }
  },
]

// 模块路由(基于主入口布局页面)
export const moduleRoutes = {
  path: '/',
  component: () => import('@/views/main'),
  name: 'main',
  redirect: { name: 'home' },
  meta: { title: '主入口布局' },
  children: [
    { path: '/home', component: () => import('@/views/modules/home'), name: 'home', meta: { title: '首页', isTab: true } },
    { path: '/iframe', component: null, name: 'iframe', meta: { title: 'iframe', isTab: true } }
  ]
}

export function addDynamicRoute (routeParams, router) {
  // 组装路由名称, 并判断是否已添加, 如是: 则直接跳转
  var routeName = routeParams.routeName
  var dynamicRoute = window.SITE_CONFIG['dynamicRoutes'].filter(item => item.name === routeName)[0]
  if (dynamicRoute) {
    return router.push({ name: routeName, params: routeParams.params })
  }
  // 否则: 添加并全局变量保存, 再跳转
  dynamicRoute = {
    path: routeName,
    component: () => import(`@/views/modules/${routeParams.path}`),
    name: routeName,
    meta: {
      ...window.SITE_CONFIG['contentTabDefault'],
      menuId: routeParams.menuId,
      title: `${routeParams.title}`
    }
  }
  router.addRoutes([
    {
      ...moduleRoutes,
      name: `main-dynamic__${dynamicRoute.name}`,
      children: [dynamicRoute]
    }
  ])
  window.SITE_CONFIG['dynamicRoutes'].push(dynamicRoute)
  router.push({ name: dynamicRoute.name, params: routeParams.params })
}

const originalPush = Router.prototype.push;
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err);
}

const router = new Router({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes: pageRoutes.concat(moduleRoutes)
})

// 全局前置路由守卫
router.beforeEach((to, from, next) => {
  let accessToken = to.query.at
  let refreshToken = to.query.rt
  if (accessToken && refreshToken) {
    Cookies.set('access_token', accessToken)
    Cookies.set('refresh_token', refreshToken)
    // 用来区分当前登录用户是从数汇门户登录的还是直接从我们登录页面登录的
    Cookies.set('opm_login_source', 'cas')
  }
  // 通过这个全局的变量，用来避免每次路由都重复执行下面的数据字典和菜单查询逻辑
  if (window.SITE_CONFIG['dynamicMenuRoutesHasAdded'] || fnCurrentRouteIsPageRoute(to, pageRoutes)) {
    return next()
  }
  // 只有登录的时候和全局刷新浏览器的时候才会执行以下查询代码
  // 获取字典列表, 添加并全局变量保存
  http.get('/sys/dict/type/openapi/all').then(({ data: res }) => {
    if (res.code !== 0) {
      return
    }
    window.SITE_CONFIG['dictList'] = res.data
  }).catch(() => {
    // do nothing
  })

  // 获取左侧模块菜单（注：后台已根据权限过滤，并借助redis加快查询速度）, 保存在window的全局变量中
  http.get('/sys/menu/nav').then(({ data: res }) => {
    if (res.code !== 0) {
      Vue.prototype.$message.error(res.msg)
      return next({ name: 'login' })
    }
    window.SITE_CONFIG['menuList'] = res.data
    window.SITE_CONFIG['subsystems'] = []
    fnAddDynamicMenuRoutes(window.SITE_CONFIG['menuList'])
    next({ ...to, replace: true })
  }).catch(() => {
    next({ name: 'login' })
  })
})

/**
 * 判断当前路由是否为页面路由
 * @param {*} route 当前路由
 * @param {*} pageRoutes 页面路由
 */
function fnCurrentRouteIsPageRoute (route, pageRoutes = []) {
  var temp = []
  for (var i = 0; i < pageRoutes.length; i++) {
    if (route.path === pageRoutes[i].path) {
      return true
    }
    if (pageRoutes[i].children && pageRoutes[i].children.length >= 1) {
      temp = temp.concat(pageRoutes[i].children)
    }
  }
  return temp.length >= 1 ? fnCurrentRouteIsPageRoute(route, temp) : false
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes (menuList = [], routes = []) {
  var temp = []
  for (var i = 0; i < menuList.length; i++) {
    // 解析子系统信息
    let subsystemMap = new HashMap()
    if (menuList[i].type == 3 && !subsystemMap.containsKey(menuList[i].id)) {
      let s = {
        id: menuList[i].id,
        name: menuList[i].name,
        isActive: false
      }
      subsystemMap.put(menuList[i].id, s)
      let subsystemArr = window.SITE_CONFIG['subsystems']
      if (!subsystemArr) {
        subsystemArr = []
      }
      subsystemArr.push(s)
      // 将解析的子系统信息保存在全局变量中
      window.SITE_CONFIG['subsystems'] = subsystemArr
    }
    if (menuList[i].children && menuList[i].children.length >= 1) { // 文件夹不创建路由，因此跳过
      for (let child of menuList[i].children) {
        child.subsystemId = menuList[i].subsystemId
      }
      temp = temp.concat(menuList[i].children)
      continue
    }
    // 组装路由
    var route = {
      path: '',
      component: null,
      name: '',
      meta: {
        ...window.SITE_CONFIG['contentTabDefault'],
        menuId: menuList[i].id,
        title: menuList[i].name,
        subsystemId: menuList[i].subsystemId,
      }
    }
    // eslint-disable-next-line
    let URL = (menuList[i].url || '').replace(/{{([^}}]+)?}}/g, (s1, s2) => eval(s2)) // URL支持{{ window.xxx }}占位符变量
    if (isURL(URL)) {
      route['path'] = route['name'] = `i-${menuList[i].id}`
      route['meta']['iframeURL'] = URL
    } else {
      URL = URL.replace(/^\//, '').replace(/_/g, '-')
      route['path'] = route['name'] = URL.replace(/\//g, '-')
      if (URL.indexOf('?') > 0) {
        let paramStr = URL.substring(URL.indexOf('?') + 1)
        let entryStrArr = paramStr.split('&')
        route['meta']['params'] = {}
        entryStrArr.forEach((entryStr) => {
          let entry = entryStr.split("=")
          route['meta']['params'][entry[0]] = entry[1]
        })
        URL = URL.substring(0, URL.indexOf('?'))
      }
      route['component'] = () => import(`@/views/modules/${URL}`)
    }
    routes.push(route)
  }
  if (temp.length >= 1) {
    return fnAddDynamicMenuRoutes(temp, routes)
  }
  // 添加路由
  router.addRoutes([
    {
      ...moduleRoutes,
      name: 'main-dynamic-menu',
      children: routes
    },
    { path: '*', redirect: { name: '404' } }
  ])
  window.SITE_CONFIG['dynamicMenuRoutes'] = routes
  window.SITE_CONFIG['dynamicMenuRoutesHasAdded'] = true
}

export default router
