<template>
  <div v-show="uploadCardVisible">
    <el-upload
        v-if="!view"
        ref="uploader"
        multiple
        :data="dataForm"
        :disabled="viewOnly"
        :action="actionUrl"
        :file-list="fileList"
        :show-file-list="false"
        :before-upload="beforeFileUpload"
        :on-progress="onProgressHandle"
        :on-success="handleSuccess"
        :auto-upload="true">
      <el-button v-if="!viewOnly" slot="trigger" size="mini" type="primary" style="margin-bottom: 5px !important;">上传文件</el-button>
      <el-button v-if="!viewOnly" size="mini" type="danger" @click="abortUpload" style="margin-left: 10px; margin-bottom: 5px !important;">取消上传</el-button>
    </el-upload>
    <el-table
        v-loading="dataListLoading"
        :element-loading-text="loadingText"
        :data="dataList"
        highlight-current-row border
        @selection-change="dataListSelectionChangeHandle"
        @sort-change="dataListSortChangeHandle"
        style="width: 100%;"
        :max-height="tableMaxHeight"
        size="mini"
        :header-cell-style="{color:'#000000'}">
      <el-table-column prop="name" label="附件名称" align="left" show-overflow-tooltip sortable="custom">
        <template slot-scope="scope">
          <el-button type="text" @click="handleDownload(scope.row.url,scope.row)">{{ scope.row.name }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="createDate" label="上传时间" header-align="center" align="center" show-overflow-tooltip sortable="custom" width="140"></el-table-column>
      <el-table-column prop="creatorName" label="上传人" header-align="center" align="center" show-overflow-tooltip width="110"></el-table-column>
      <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="120">
        <template slot-scope="scope">
          <el-button v-if="!viewOnly && canDelete(scope.row)" type="text" size="mini" @click="deleteHandle(scope.row.id)">{{ $t('delete') }}</el-button>
          <el-button type="text" size="mini" @click="openUploadDownloadRecordHandle(scope.row.id)">下载记录</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
    </el-pagination>
    <my-upload-download-record v-if="uploadDownloadRecordVisible" ref="uploadDownloadRecord" :ossId="currentClickId" @close="closeUploadDownloadRecordDialogHandle"></my-upload-download-record>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import mixinViewModule from '@/mixins/view-module'

export default {
  name: 'MyUpload',
  mixins: [mixinViewModule],
  props: {
    // 关联主表ID
    refId: String,
    // 附件分类：自定义的字符串
    typeId: {
      type: String,
      default: ''
    },
    // 附件状态：0: 临时文件、 1：永久文件、 3: 其他业务状态。比如：质检资料等
    statusId: {
      type: Number,
      default: 1
    },
    // 是否用在view页面（不可编辑）
    view: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    // 文件上传前校验函数，返回false,则取消本次上传
    beforeFileUploadFunc: {
      type: Function,
      default: null
    },
    // 列表最大高度:合法的值为数字或者单位为 px 的高度
    tableMaxHeight: {
      type: [String, Number],
      default: 300,
      required: false
    },
    // 附件删除的URL,用于需要特别的删除方法时使用（比如档案的删除操作）
    deleteUrl: {
      type: String,
      default: '',
      required: false
    },
  },
  data() {
    return {
      actionUrl: '',
      fileList: [],
      // 上传后的
      successFileList: [],

      loadingText: '',
      mixinViewModuleOptions: {
        createdIsNeed: false,
        getDataListURL: '/oss/file/page',
        getDataListIsPage: true,
        deleteURL: '/oss/file',
        deleteSuccessCallback: this.deleteSuccessCallback,
      },
      dataForm: {
        refId: '',
        statusId: '',
        typeId: ''
      },

      // 附件下载记录
      uploadDownloadRecordVisible: false,
      currentClickId: '',
    }
  },
  computed: {
    viewOnly() {
      return this.view || this.readonly
    },
    uploadCardVisible() {
      return !this.viewOnly || (this.viewOnly && this.dataList.length > 0)
    }
  },
  watch: {
    refId: function () {
      this.init()
    },
    typeId: function () {
      this.init()
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    init() {
      if (this.deleteUrl) {
        this.mixinViewModuleOptions.deleteURL = this.deleteUrl
      }
      this.fileList = []
      this.successFileList = []
      this.dataForm.typeId = this.typeId
      if (this.refId) {
        this.dataForm.refId = this.refId
        this.dataForm.statusId = this.statusId
        this.actionUrl = `${window.SITE_CONFIG['apiURL']}/oss/file/upload?access_token=${Cookies.get('access_token')}&tenant_code_key=${Cookies.get('tenantCode')}`
        this.getDataList()
      } else {
        this.actionUrl = ''
        // refId不存在时（新增操作时），需要临时获取一个UUID（分布式的）
        // 由于雪花算法需要指定workerId和dataCenterId，而这两个参数无法在前端确定，因此从服务端获取。
        this.$http.get(`/oss/file/uuid`).then(({data: res}) => {
          if (res.code === 0) {
            if (this.actionUrl === '') {
              this.dataForm.refId = res.data
              this.dataForm.statusId = ''
              this.actionUrl = `${window.SITE_CONFIG['apiURL']}/oss/file/upload?access_token=${Cookies.get('access_token')}&tenant_code_key=${Cookies.get('tenantCode')}`
            }
          }
        }).catch()
      }
    },
    setRefId(newRefId) {
      if (this.successFileList.length > 0) {
        let ossIds = []
        this.successFileList.forEach(item => {
          ossIds.push(item.id ? item.id : item.response.data.id)
        })
        this.$http.post(`/oss/file/setRefId/${newRefId}`, ossIds)
            .then(({data: res}) => {
              if (res.code !== 0) {
                this.refId = ''
                return this.$message.error(res.msg)
              }
            }).catch()
      }
    },

    // 上传前，让表格loading，百分比展示
    beforeFileUpload(file) {
      let isOk = true
      if (typeof this.beforeFileUploadFunc == 'function') {
        isOk = this.beforeFileUploadFunc(file)
        // 函数无返回值，默认通过校验 isOk=true
        if (isOk == null || isOk == undefined) {
          isOk = true
        }
      }
      if (isOk) {
        this.dataListLoading = true
        this.loadingText = ''
      }
      return isOk
    },
    // 上传中，显示上传进度
    onProgressHandle(event, file, fileList) {
      let progress = parseInt(event.percent)
      if (progress >= 100) {
        progress = 98
      }
      // 动态显示百分比
      this.loadingText = progress + "%"
    },

    // 上传后，表格loading结束
    handleSuccess(res, file, fileList) {
      this.successFileList = fileList
      // 每个文件上传完成（出现对号）后都会调用 handleSuccess
      this.loadingText = '100%'
      this.dataListLoading = false
      this.loadingText = ''
      this.$emit('uploadSuccess', file)
      this.getDataList()
    },

    // 取消上传
    abortUpload() {
      this.$refs.uploader.abort()
      this.dataListLoading = false
      this.loadingText = ''
    },

    // 点击附件名称，下载附件
    handleDownload(url, row) {
      // 保存用户下载记录
      if (row) {
        let downloadDataForm = {
          realName: this.$store.state.user.realName,
          ossId: row.id
        }
        this.$http['post']('/oss/download/', downloadDataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
        }).catch()
      }
      // 如果url为externalUrl则不需要转换为signedUrl
      if (row && row.props) {
        let props = JSON.parse(row.props)
        if (props.externalUrl == 'yes') {
          //window.open(`${window.SITE_CONFIG['apiURL']}/oss/file/stream?url=${url}&access_token=${Cookies.get('access_token')}`)
          window.open(url)
          return
        }
      }
      this.$http.get(`/oss/file/signedUrl?url=${url}`)
          .then(({data: res}) => {
            if (res.code === 0 && res.data) {
              window.open(res.data)
            } else {
              this.$message({
                message: '对不起，未查询到相关文件！',
                type: 'error',
                duration: 5000
              })
            }
          }).catch()
    },

    // 打开附件下载记录弹窗
    openUploadDownloadRecordHandle(ossId) {
      this.uploadDownloadRecordVisible = true
      this.$nextTick(() => {
        this.currentClickId = ossId
        this.$refs.uploadDownloadRecord.init()
      })
    },

    // 关闭附件下载记录弹窗
    closeUploadDownloadRecordDialogHandle() {
      this.uploadDownloadRecordVisible = false
      this.currentClickId = ''
    },
    // 删除附件成功后调用
    deleteSuccessCallback() {
      this.$emit('delete')
    },
    // 根据props中的externalUrl属性判断是否可以删除
    canDelete(row) {
      if (row.props) {
        let props = JSON.parse(row.props)
        if (props.externalUrl == 'yes') {
          return false
        }
      }
      return true
    }
  }
}
</script>
<style scoped>
::v-deep .el-upload {
  display: inline !important;
}
</style>