<template>
  <div class="dialog-container">
    <el-row>
      <el-col :span="24">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
          <el-row>
            <el-col :span="24">
              <el-form-item label="流程模板" prop="templateId">
                <el-select v-model="dataForm.templateId" @change="handleChange" placeholder="请选择一个流程模板" style="width: 100%">
                  <el-option :label="item.templateName" v-for="item in templateList" :key="item.templateId" :value="item.templateId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="流程主题" prop="processTitle">
                <el-input v-model="dataForm.processTitle"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-timeline style="padding-top: 10px;">
          <el-timeline-item v-for="(node, index) in nodeList" :key="index" :icon="node.icon" size="large" class="task">
            <!-- 主干 -->
            <div class="process-node-render" v-if="!node.hasOwnProperty('options')">
              <el-divider content-position="left">
                <span class="branch-title">{{ node.title }}</span>
                <el-tooltip v-if="node.isEdit" :content="node.desc != null ? node.desc : '添加'" placement="top">
                  <span class="add-user" v-if="node.isEdit && (node.multiple || 0 === node.users.length)" @click="addUser(node)" >
                    <i class="el-icon-plus"></i>
                  </span>
                </el-tooltip>
              </el-divider>
              <span class="node-desc" v-if="!node.isEdit && (node.users == null || node.users.length == 0)">
                {{ node.desc }}
              </span>
              <!--
                <div>
                  <div class="node-name">{{ node.title }}</div>
                  <span style="color: rgb(168, 173, 175)">{{ node.desc }}</span>
                </div>
              -->
              <div>
                <div class="avatar node-user" v-for="(user, index) in node.users" :key="index">
                  <div class="a-img">
                    <el-avatar style="height: 38px; width: 38px; line-height: 38px" :src="user.headSignedUrl"></el-avatar>
                    <i class="close el-icon-close" v-if="node.isEdit" @click="delUser(node, index)"></i>
                    <i class="status" style="display: none"></i>
                  </div>
                  <span class="name line">{{ user.realName }}</span>
                </div>
                <!--
                  <span class="add-user" v-if="node.isEdit && (node.multiple || 0 === node.users.length)" @click="addUser(node)" >
                    <i class="el-icon-plus"></i>
                    <div>添加</div>
                  </span>
                -->
              </div>
            </div>
            <!-- 并行网关 -->
            <div v-else>
              <el-card class="my-card" v-for="d in node.options" :key="d.id">
                <div slot="header" class="clearfix node-name">
                  <span>{{ d.title }}</span>
                </div>
                <div>
                  <div class="process-node-render" v-for="child in node.branchs[d.id]" :key="child.id">
                    <el-divider content-position="left">
                      <span class="branch-title">{{ child.title }}</span>
                      <el-tooltip v-if="child.isEdit" :content="child.desc != null ? child.desc : '添加'" placement="top">
                        <span class="add-user" v-if="child.isEdit && (child.multiple || 0 === child.users.length)" @click="addUser(child)" >
                          <i class="el-icon-plus"></i>
                        </span>
                      </el-tooltip>
                    </el-divider>
                    
                    <span class="node-desc" v-if="!child.isEdit && (child.users == null || child.users.length == 0)">
                      {{ child.desc }}
                    </span>
                    
                    <div>
                      <div class="avatar node-user" v-for="(user, index) in child.users" :key="user.id">
                        <div class="a-img">
                          <el-avatar style="height: 30px; width: 30px; line-height: 30px" :src="user.headSignedUrl"></el-avatar>
                          <i class="close el-icon-close" v-if="child.isEdit" @click="delUser(child, index)"></i>
                          <i class="status" style="display: none"></i>
                        </div>
                        <span class="name line">{{ user.realName }}</span>
                      </div>
                      <!--
                        <span class="add-user" v-if="child.isEdit && (child.multiple || 0 === child.users.length)" @click="addUser(child)" >
                          <i class="el-icon-plus"></i>
                          <div>添加</div>
                        </span>
                      -->
                    </div>
                  </div>
                </div>
              </el-card>
            </div>
          </el-timeline-item>
        </el-timeline>
      </el-col>
    </el-row>
    <user-multi-select v-if="userMultiSelectVisible" ref="userMultiSelect" :singleSelect="!this.selectedNode.multiple"
      @close="closeDialogHandle" 
      @submitAllData="userMultiSelectSubmit">
    </user-multi-select>
  </div>
</template>
 
<script>
import avatar from '@/assets/img/avatar.png'
export default {
  name: "SponsorProcess",
  props: {
    templateList: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      loading: false,
      select: [],
      selectedNode: {},
      processUsers: {},
      templateForm: {
        templateId: "",
        templateName: "",
        process: {},
        remark: "",
      },
      dataForm: {
        processTitle: '', // 流程发起时默认的流程主题
        templateId: '',
        menuId: '',
      },
      loginUser: {},
      conditionFormItem: new Set(),
      nodeList: [],
      userMultiSelectVisible: false,
      // 记录该模版中需要发起人指定审批人的节点个数，用于在用户点击发送时，校验是否全部指定，防止发起人漏选
      needEditNodeCount: 0,
    }
  },
  mounted() {
    this.loadLoginUser()
  },
  computed: {
    _value: {
      get: function () {
        return this.value
      },
      set: function (e) {
        this.$emit("input", e)
      },
    },
    dataRule () {
      return {
        processTitle: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        templateId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    loadLoginUser() {
      this.loginUser = this.$deepCopy(this.$store.state.user)
    },
    // 选择流程模板事件
    handleChange (templateId) {
      this.needEditNodeCount = 0
      let item = this.templateList.find(obj => obj.templateId == templateId)
      this.dataForm.menuId = item.menuId
      this.loadTemplateInfo(templateId)
    },
    loadTemplateInfo(templateId) {
      this.loading = true
      this.$http.get(`/flowable/process/template`, {
        params: {
          templateId,
        }
      }).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.loading = false
        let templateForm = res.data
        templateForm.process = JSON.parse(templateForm.process)
 
        // 设置默认流程主题
        if (this.dataForm.processTitle === null || this.dataForm.processTitle === '') {
          this.dataForm.processTitle = templateForm.templateName
        }
        this.templateForm = {
          ...templateForm,
          ...this.dataForm
        }
 
        // 构建表单及校验规则
        this.$store.state.design = templateForm
        this.showProcessNodes()
      }).catch(() => {
        this.loading = false
      })
    },
    showProcessNodes() {
      this.nodeList = []
      this.getProcess(this.nodeList, this.templateForm.process)
 
      this.nodeList.push({
        title: "结束",
        name: "END",
        icon: "el-icon-success",
        isEdit: false,
      })
    },
    getProcess(nodeList, process) {
      if (null != process && undefined != process) {
        if ("ROOT" === process.type) {
          // 发起人节点
          this.getRootNode(nodeList, process)
        } else if ("APPROVAL" === process.type) {
          // 审批节点
          this.getApprovalNode(nodeList, process)
        } else if ("CC" === process.type) {
          this.getCcNode(nodeList, process)
        } else if ("CONDITIONS" === process.type) {
          // 判断是否符合条件符合走条件分支,否则继续递归子分支
          if (null != process.branchs && undefined != process.branchs) {
            this.getConditionNode(nodeList, process)
          }
        } else if ("CONCURRENTS" === process.type) {
          this.getConcurrentNode(nodeList, process)
        }
        if (null != process.children && undefined != process.children) {
          this.getProcess(nodeList, process.children)
        }
      }
    },
    // 封装开始节点
    getRootNode(nodeList, process) {
      let data = {
        id: process.id,
        title: process.name,
        name: "发起人",
        icon: "el-icon-user-solid",
        isEdit: false,
        users: [this.loginUser],
      }
      this.convertUsersHeadUrlToSignedUrl(data.users)
      nodeList.push(data)
    },
    // 封装审批节点
    getApprovalNode(nodeList, process) {
      let data = {
        id: process.id,
        title: process.name,
        name: "审批人",
        icon: "el-icon-s-check",
        isEdit: false,
        multiple: false,
        mode: process.props.mode,
        users: [],
        desc: '',
      }
      // 判断审批人类型
      switch (process.props.assignedType) {
        case "ASSIGN_USER":
          data.users = this.$deepCopy(process.props.assignedUser)
          // 将user中的headUrl转换为signedUrl
          this.convertUsersHeadUrlToSignedUrl(data.users)
          data.desc = "指定审批人"
          break
        case "ASSIGN_LEADER":
          data.desc = "指定部门的领导"
          break
        case "SELF":
          data.users = [this.loginUser]
          // 将user中的headUrl转换为signedUrl
          this.convertUsersHeadUrlToSignedUrl(data.users)
          data.desc = "发起人自己审批"
          break
        case "SELF_SELECT":
          data.isEdit = true
          data.multiple = process.props.selfSelect.multiple || false
          data.desc = "自选审批人"
          this.needEditNodeCount++
          break
        case "LEADER_TOP":
          data.desc = "连续多级主管审批"
          break
        case "LEADER":
          data.desc = 1 === process.props.leader.level ? "直接主管审批" : "第".concat(process.props.leader.level, "级主管审批")
          break
        case "ROLE":
          data.desc = "由角色【".concat((process.props.role || []).map(function (e) {return e.name}),"】审批")
          break
        case "REFUSE":
          data.desc = "流程此处将被自动驳回"
          break
      }
      nodeList.push(data)
    },
    getCcNode(nodeList, process) {
      let data = {
        id: process.id,
        title: process.name,
        icon: "el-icon-s-promotion",
        name: "抄送人",
        isEdit: process.props.shouldAdd,
        type: "org",
        multiple: true,
        desc: process.props.shouldAdd ? "可添加抄送人" : "",
        users: this.$deepCopy(process.props.assignedUser),
      }
      nodeList.push(data)
    },
    getConditionNode(nodeList, process) {
      for (let r = null, s = 0; s < process.branchs.length; s++) {
        for (let a = process.branchs[s], n = false, o = 0, i = 0; i < a.props.groups.length; i++) {
          if (((n = this.getConditionResultByGroup(a.props.groups[i])), "OR" === a.props.groupsType && n)) {
            r = a
            break
          }
          "AND" === a.props.groupsType && n && o++
        }
        if (r) {
          break
        }
        if (o === a.props.groups.length) {
          r = a
          break
        }
      }
      r ? this.getProcess(nodeList, r) : console.log("条件节点 ".concat(process.id, " => ").concat(process.name, " 均不满足，无法继续"), process)
    },
    getConcurrentNode(nodeList, process) {
      let data = {
        id: process.id,
        title: process.name,
        name: "并行分支",
        icon: "el-icon-s-operation",
        isEdit: false,
        active: process.branchs[0].id,
        options: [],
        desc: "切换分支可显示对应执行流程",
        branchs: {},
      }
      nodeList.push(data)
      process.branchs.forEach((b) => {
        data.options.push({ id: b.id, title: b.name })
        this.$set(data.branchs, b.id, [])
        this.getProcess(data.branchs[b.id], b.children)
      })
    },
    getConditionResultByGroup(e) {
      let t = this,
        r = !1,
        s = 0
      e.conditions.forEach(function (e) {
        return t.conditionFormItem.add(e.id)
      })
 
      for (let a = 0; a < e.conditions.length; a++) {
        let n = e.conditions[a]
        switch (n.valueType) {
          case "Number":
            r = this.numberCompare(n)
            break
          case "String":
            break
          case "Date":
            break
          case "User":
            break
          case "Array":
            break
        }
        if (r && "OR" === e.groupType) break;
        r && "AND" === e.groupType && s++;
      }
      return "AND" === e.groupType && (r = s === e.conditions.length), r;
    },
    addUser(node) {
      this.selectedNode = node
      this.userMultiSelectVisible = true
      this.$nextTick(() => {
        this.$refs.userMultiSelect.init()
      })
    },
    delUser(node, index) {
      let users = node.users
      users.splice(index, 1)
      this.processUsers[node.id] = users
    },
    userMultiSelectSubmit(selectedList) {
      this.processUsers[this.selectedNode.id] = []
      selectedList.forEach((val) => {
        let index = this.selectedNode.users.findIndex((selectedNodeUser) => {
          return selectedNodeUser.id === val.id
        })
        if (index === -1) {
          this.selectedNode.users.push(val)
          this.processUsers[this.selectedNode.id].push(val)
          // 将user中的headUrl转换为signedUrl
          this.convertUsersHeadUrlToSignedUrl([val])
        }
      })
    },
    numberCompare(e) {
      // formData原为流程表单相关属性，现表单功能已阉割，暂时将t写死为0
      // let t = this.formData[e.id]
      let t = 0
      switch (e.compare) {
        case ">":
          return t > parseFloat(e.value[0])
        case "<":
          return t < parseFloat(e.value[0])
        case "=":
          return t === parseFloat(e.value[0])
        case ">=":
          return t >= parseFloat(e.value[0])
        case "<=":
          return t <= parseFloat(e.value[0])
        case "B":
          return t > parseFloat(e.value[0]) && t < parseFloat(e.value[1])
        case "AB":
          return t >= parseFloat(e.value[0]) && t < parseFloat(e.value[1])
        case "BA":
          return t > parseFloat(e.value[0]) && t <= parseFloat(e.value[1])
        case "ABA":
          return t >= parseFloat(e.value[0]) && t <= parseFloat(e.value[1])
        case "IN":
          return e.value.indexOf(String(t)) > -1
        default:
          return !1
      }
    },
    // 关闭用户选择弹窗
    closeDialogHandle() {
      this.userMultiSelectVisible = false
    },
    // // 将users中的headUrl转换为signedUrl
    convertUsersHeadUrlToSignedUrl(users) {
      if (!users || users.length == 0) {
        return
      }
      users.forEach((user) => {
        if (user.headUrl) {
          this.$http.get(`/oss/file/signedUrl?url=${user.headUrl}`).then(({ data: res }) => {
            if (res.code === 0) {
              this.$set(user, 'headSignedUrl', res.data)
            } else {
              this.$set(user, 'headSignedUrl', avatar)
            }
          })
        } else {
          this.$set(user, 'headSignedUrl', avatar)
        }
      })
    },
    getProcessUser() {
      // 因为this.processUsers在后台接收时是一个map，但是前端的空map会被识别为array，
      // 在作为参数传递到后端时会出现数据类型转换错误，所以增加此判断
      if (Object.keys(this.processUsers).length === 0) {
        return null
      } else {
        return this.processUsers
      }
    },
    getForm() {
      return {...this.templateForm, ...this.dataForm}
    },
    // 返回未制定办理人的节点个数，用于发起时校验并提醒用户
    getUneditedNodeCount() {
      return this.needEditNodeCount - Object.keys(this.processUsers).length
    },
    validate(call) {
      this.$refs.dataForm.validate(call)
    },
  },
}
</script>
 
<style lang="less" scoped>
.dialog-container{
  height: calc(100vh - 280px);
  min-height: 500px;
  overflow: auto;
}
::v-deep .my-card .el-card__header {
  background-color: #F5F5F5;
}
 
.node-name {
  color: dodgerblue;
  font-weight: bold;
}
.node-user {
  //float: right;
  padding: 5px 5px;
}
.node-desc {
  color: #8c8c8c;
}
.task {
  min-height: 40px;
}
.branch-title {
  color: dodgerblue;
  font-weight: bold;
}
 
.el-timeline-item .el-timeline-item__content .process-node-render > div {
  // position: absolute;
  display: inline-block;
}
 
.el-timeline-item
  .el-timeline-item__content
  .process-node-render
  > div:last-child {
  right: 0;
  top: -10px;
}
 
.avatar {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
}
 
.show-y {
  justify-content: center;
  // flex-direction: column !important;
}
 
.show-y,
.w-h-center {
  display: flex;
  align-items: center;
}
 
.avatar .a-img {
  display: flex;
  border-radius: 50%;
  // flex-direction: column;
  justify-content: center;
  background: #fff;
  position: relative;
}
 
.close {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  background: #000;
}
 
.status {
  position: absolute;
  bottom: -4px;
  right: -8px;
  border-radius: 50%;
  font-size: 15px;
  background: #fff;
  border: 2px solid #fff;
}
 
.avatar .name {
  text-align: center;
  color: #19191a;
  font-size: 14px;
  margin-left: 10px;
}
 
.show-y .name {
  margin-left: 0 !important;
}
 
.line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
 
.add-user {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  padding-left: 5px;
}
 
.add-user i {
  padding: 5px;
  font-size: 14px;
  border-radius: 50%;
  border: 1px dashed #8c8c8c;
  cursor: pointer;
}
 
.el-timeline-item .el-timeline-item__icon {
  color: #babcc1;
  font-size: 16px;
}
 
.border .el-dialog__header {
  border-bottom: 1px solid #e8e8e8;
}
</style>