import Vue from 'vue'
import Vuex from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import user from './modules/user'
// Vue.use(Vuex)只能在该文件中调用
// 如果在main.js中use，由于所有的use都始终在所有import之后才会被执行，这就导致了 new Vuex.Store 在 Vue.use(Vuex) 之前被调用，也就出现了错误。
// 所以 Vue.use(Vuex) 不能统一放在main.js中，必须放在当前文件中。
Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    // 顶部导航条, 布局风格, defalut(白色) / colorful(鲜艳)
    // navbarLayoutType: localStorage.getItem('navbarLayoutType') || 'colorful',
    // 【2023年9月改造】东莞路桥与数汇门户系统风格保持一致
    navbarLayoutType: 'colorful',
    // 左侧侧边栏, 布局皮肤, default(白色) / dark(黑色)
    // 【2023年9月改造】东莞路桥与数汇门户系统风格保持一致
    //sidebarLayoutSkin: localStorage.getItem('sidebarLayoutSkin') || 'default',
    sidebarLayoutSkin: 'default',
    // 侧边栏, 折叠状态
    sidebarFold: false,
    // 侧边栏, 菜单
    sidebarMenuList: [],
    sidebarMenuActiveName: '',
    // 内容, 是否需要刷新
    contentIsNeedRefresh: false,
    // 内容, 标签页(默认添加首页)
    contentTabs: [
      {
        ...window.SITE_CONFIG['contentTabDefault'],
        'name': 'home',
        'title': 'home'
      }
    ],
    contentTabsActiveName: 'home',
    lastOperationTime: 0,// 记录上一次用户请求后台的时间（用于定时器中判断用户是否已长时间未操作系统，从而做出是否自动退出登录的判断）

    // 以下为流程相关组件页面使用到的变量
    nodeMap: new Map(),
    selectedNode: {},
    selectFormItem: null,
    design:{
      logo: {},
      process: {
        props: {
          assignedUser: [],
          formPerms: []
        },
        children: {}
      },
    },
    runningList: [],
    noTakeList: [],
    endList: [],
    diagramMode: 'design',
  },
  modules: {
    user
  },
  mutations: {
    // 重置vuex本地储存状态（例如：退出登录时）
    resetStore (state) {
      Object.keys(state).forEach((key) => {
        state[key] = cloneDeep(window.SITE_CONFIG['storeState'][key])
      })
    },
    // 皮肤切换时，设置顶部导航条样式
    setNavbarLayoutType (state, val) {
      state.navbarLayoutType = val
      // 缓存起来，确保下次打开时仍然使用该皮肤样式
      localStorage.setItem('navbarLayoutType', val)
    },
    // 皮肤切换时，设置左侧侧边栏样式
    setSidebarLayoutSkin (state, val) {
      state.sidebarLayoutSkin = val
      // 缓存起来，确保下次打开时仍然使用该皮肤样式
      localStorage.setItem('sidebarLayoutSkin', val)
    },
    // 更新用户最新的操作时间（用于定时器中判断用户是否已长时间未操作系统，从而做出是否自动退出登录的判断）
    setLastOperationTime(state, val) {
      state.lastOperationTime = val
    },
    // ============以下为flowable-dingding流程相关组件使用==============
    // 流程图中选中的当前节点
    selectedNode(state, val) {
      state.selectedNode = val
    },
    // 流程设计器各组件用到的流程模版数据
    templateDesignInfo(state, val){
      state.design = val
    },
  }
})
