<template>
  <el-dialog width="80%" :visible.sync="visible" :title="title" :close-on-click-modal="false"
             :close-on-press-escape="false" v-on="$listeners">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" label-width="80px">
      <el-form-item>
        <el-input v-model="dataForm.name" placeholder="属性名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="dataForm.code" placeholder="属性编号" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="dataForm.typeId" clearable placeholder="请选择属性分类">
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group style="margin-top: -3px;">
          <el-button @click="getDataList()">{{ $t('query') }}</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table ref="wbsPropTable" v-loading="dataListLoading" :row-key="getRowKeys" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="handleSelectionChange" style="width: 100%;">
      <el-table-column type="selection" :reserve-selection="true" header-align="center" align="center" width="50"></el-table-column>
      <el-table-column prop="name" label="属性名称" min-width="200" header-align="center" align="center" show-overflow-tooltip sortable="custom"></el-table-column>
      <el-table-column prop="code" label="属性编号" width="180" header-align="center" align="center" show-overflow-tooltip sortable="custom"></el-table-column>
      <el-table-column prop="typeId" label="属性分类" width="120" header-align="center" align="center" show-overflow-tooltip sortable="custom">
        <template slot-scope="scope">
          {{getTypeName(scope.row.typeId)}}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="limit"
      :total="total"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="pageSizeChangeHandle"
      @current-change="pageCurrentChangeHandle">
    </el-pagination>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="wbsPropSubmitdHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import debounce from 'lodash/debounce'
export default {
  name: 'WbsPropSelect',
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/wbs/wbsPropPage',
        getDataListIsPage: true,
        createdIsNeed: false
      },
      visible: false,
      dataForm: {
        code: '',
        name: '',
        typeId: ''
      },

      // 构件属性分类
      typeOptions: [
        {
          value: 1,
          label: '施工信息'
        },{
          value: 2,
          label: '试验信息'
        },{
          value: 3,
          label: '原材信息'
        }
      ],
      title: "选择构件属性",
      getRowKeys (row) { // 记录每行的key值
        return row.id;
      },
      currentSelectIds: [], // 表格select复选框选中的id
      multipleSelection: [], // 表格选中
      lastSelectedIds: [], // 记录上次选中的id集合
      deleteIds: [] // 删除选中的id集合
    }
  },
  created: function () {
  },
  props: {
    selectedIds: { // 已选中的构件属性对象的id集合
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.getDataList()
    },

    wbsPropSubmitdHandle: debounce(function () {
      let addWbsProps = []; // 新增构件属性项
      for (let i = 0; i < this.multipleSelection.length; i++) {
        let selectObj = this.multipleSelection[i]
        if (!this.isStr(this.selectedIds,selectObj.id)) {
          addWbsProps.push(selectObj)
        }
      }
      this.$emit('selectChange',{
        newItems: addWbsProps,
        deleteIds: this.deleteIds
      })
      this.$message({
        message: this.$t('prompt.success'),
        type: 'success',
        duration: 500,
        onClose: () => {
          this.visible = false
        }
      })
    },1000, {'leading': true, 'trailing': false}),

    handleSelectionChange (rows) {
      // 这里rows是用户页面勾选或取消后的结果项，
      // 这里难点就是如何记录用户在页面上取消勾选项
      this.multipleSelection = rows
      this.currentSelectIds = []
      if (rows) {
        for (let i = 0; i < rows.length; i++) {
          if (rows[i]) {
            this.currentSelectIds.push((rows[i].id))
          }
        }
      }

      //  计算用户在页面勾选删除Id集合
      for (let i = 0; i < this.lastSelectedIds.length; i++) {
        let lastSelectId = this.lastSelectedIds[i]
        //  上次勾选项是否已取消
        if (!this.isStr(this.currentSelectIds,lastSelectId)) {
          if (this.isStr(this.selectedIds,lastSelectId)) {
            if (!this.isStr(this.deleteIds,lastSelectId)) {
              this.deleteIds.push(lastSelectId)
            }
          }
        }
      }

      for (let i = 0; i < this.currentSelectIds.length; i++) {
        let selectId = this.currentSelectIds[i]
        //  删除已经被记录的删除项
        if (this.isStr(this.deleteIds,selectId)) {
          this.deleteIds = this.deleteIds.filter(item => item != selectId)
        }
      }

      this.lastSelectedIds = [].concat(this.currentSelectIds)

    },

    // 根据列表中属性分类ID,显示属性分类名称
    getTypeName (typeId) {
      const val = this.typeOptions.find((element) => (element.value + '' === typeId + ''))
      if (val) {
        return val.label
      } else {
        return typeId || ''
      }
    },

    // 数组是否包含部分字符串
    isStr (arr,str) {
      let n = arr.length;
      for (let i = 0; i < n; i++) {
        if (arr[i].indexOf(str) != -1) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    dataList: function (val,oldVal) {
      if (this.selectedIds && this.selectedIds.length > 0) {
        if (val && val.length > 0) {
          for (let i = 0; i < val.length; i++) {
            if (this.isStr(this.selectedIds,val[i].id)) {
              if (!this.isStr(this.deleteIds,val[i].id)) {
                if (!this.isStr(this.currentSelectIds,val[i].id)) {
                  this.$refs.wbsPropTable.toggleRowSelection(val[i],true);
                }
              }
            }
          }
        }
      }
    },
  }
}
</script>
