<template>
  <vxe-modal v-model="visible" title="角色选择" width="600" showFooter @confirm="confirm">
    <el-transfer v-model="value" :data="options" :titles="['未选择','已选择']" :props="props"></el-transfer>
  </vxe-modal>
</template>
<script>
export default {
  name: 'RoleSelectTransfer',
  data () {
    return {
      value: [],
      visible: false,
      options: [],
      props: {
        key: 'id',
        label: 'name',
      }
    }
  },
  created: function () {
    this.$http.get('/sys/role/openapi/briefs')
      .then(({ data: res }) => {
        if (res.code === 0) {
          this.options = res.data
        }
      })
      .catch(() => {})
  },
  props: {
    // 点击确认触发的事件中包含的参数默认为id组成的数组
    // 如果此参数为true，则返回值是role对象组成的数组
    fullData: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    confirm() {
      let result = []
      if (this.fullData) {
        result = this.options.filter((option) => {
          return this.value.includes(option.id)
        })
      } else {
        result = this.value
      }
      this.$emit('confirm', result)
    }
  },
}
</script>
