import Cookies from 'js-cookie'
import store from '@/store'

/**
 * 权限
 * @param {*} key
 */
export function hasPermission (key) {
  return window.SITE_CONFIG['permissions'].indexOf(key) !== -1 || false
}

/**
 * 获取字典数据列表
 * @param dictType  字典类型
 */
export function getDictDataList (dictType) {
  if (!window.SITE_CONFIG['dictList']) {
    return []
  }
  const type = window.SITE_CONFIG['dictList'].find((element) => (element.dictType === dictType))
  if (type) {
    return type.dataList
  } else {
    return []
  }
}

/**
 * 获取字典名称
 * @param dictType  字典类型
 * @param dictValue  字典值
 */
export function getDictLabel (dictType, dictValue) {
  if (!window.SITE_CONFIG['dictList']) {
    return []
  }
  const type = window.SITE_CONFIG['dictList'].find((element) => (element.dictType === dictType))
  if (type) {
    let val = ''
    if (type.dataList && type.dataList.length > 0) {
      val = type.dataList.find((element) => (element.dictValue === dictValue + ''))
    }
    if (val) {
      return val.dictLabel
    } else {
      return dictValue
    }
  } else {
    return dictValue
  }
}

/**
 * 清除登录信息
 */
export function clearLoginInfo () {
  store.commit('resetStore')
  Cookies.remove('access_token')
  Cookies.remove('refresh_token')
  window.SITE_CONFIG['dynamicMenuRoutesHasAdded'] = false
}

/**
 * 获取uuid
 */
export function getUUID () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}

/**
 * 获取svg图标(id)列表
 */
export function getIconList () {
  var res = []
  document.querySelectorAll('svg symbol').forEach(item => {
    res.push(item.id)
  })
  return res
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate (data, id = 'id', pid = 'pid') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (!temp[data[k][pid]] || data[k][id] === data[k][pid]) {
      res.push(data[k])
      continue
    }
    if (!temp[data[k][pid]]['children']) {
      temp[data[k][pid]]['children'] = []
    }
    temp[data[k][pid]]['children'].push(data[k])
    data[k]['_level'] = (temp[data[k][pid]]._level || 0) + 1
  }
  return res
}

export function themeColorChangeHandle (val) {
  var styleList = [
    {
      id: 'J_elementTheme',
      url: `${process.env.BASE_URL}element-theme/${val}/index.css?t=${new Date().getTime()}`
    },
    {
      id: 'J_auiTheme',
      url: `${process.env.BASE_URL}element-theme/${val}/aui.css?t=${new Date().getTime()}`
    }
  ]
  for (var i = 0; i < styleList.length; i++) {
    var el = document.querySelector(`#${styleList[i].id}`)
    if (el) {
      el.href = styleList[i].url
      continue
    }
    el = document.createElement('link')
    el.id = styleList[i].id
    el.href = styleList[i].url
    el.rel = 'stylesheet'
    document.querySelector('head').appendChild(el)
  }
}

/**
* HashMap对象
*/
export function HashMap() {
  /** Map 大小 **/
  var size = 0;
  /** 对象 **/
  var entry = new Object();

  /** 存 **/
  this.put = function (key, value) {
      if (!this.containsKey(key)) {
          size++;
      }
      entry[key] = value;
  }

  /** 取 **/
  this.get = function (key) {
      return this.containsKey(key) ? entry[key] : null;
  }

  /** 删除 **/
  this.remove = function (key) {
      if (this.containsKey(key) && (delete entry[key])) {
          size--;
      }
  }

  /** 是否包含 Key **/
  this.containsKey = function (key) {
      return (key in entry);
  }

  /** 是否包含 Value **/
  this.containsValue = function (value) {
      for (var prop in entry) {
          if (entry[prop] == value) {
              return true;
          }
      }
      return false;
  }

  /** 所有 Value **/
  this.values = function () {
      var values = new Array();
      for (var prop in entry) {
          values.push(entry[prop]);
      }
      return values;
  }

  /** 所有 Key **/
  this.keys = function () {
      var keys = new Array();
      for (var prop in entry) {
          keys.push(prop);
      }
      return keys;
  }

  /** Map Size **/
  this.size = function () {
      return size;
  }

  /* 清空 */
  this.clear = function () {
      size = 0;
      entry = new Object();
  }
  /** 根据 Value 获取key**/
  this.getKeyByValue = function (value) {
      for (var prop in entry) {
          if (entry[prop] == value) {
              return prop;
          }
      }
      return null;
  }
}

export function typeOf(obj) {
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  return map[toString.call(obj)]
}

export function deepCopy(data) {
  const t = typeOf(data)
  let o

  if (t === 'array') {
    o = []
  } else if (t === 'object') {
    o = {}
  } else {
    return data
  }

  if (t === 'array') {
    for (let i = 0; i < data.length; i++) {
      o.push(deepCopy(data[i]))
    }
  } else if (t === 'object') {
    for (const i in data) {
      o[i] = deepCopy(data[i])
    }
  }
  return o
}

// 导出的流Blob,filename 导出的文件名
export function downloadFileBlob(data,filename) {
  if (!data) {
    return
  }
  let url = window.URL.createObjectURL(new Blob([data]))
  let link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', filename)

  document.body.appendChild(link)
  link.click()
}