import { render, staticRenderFns } from "./ApprovalNodeConfig.vue?vue&type=template&id=476a0748&scoped=true"
import script from "./ApprovalNodeConfig.vue?vue&type=script&lang=js"
export * from "./ApprovalNodeConfig.vue?vue&type=script&lang=js"
import style0 from "./ApprovalNodeConfig.vue?vue&type=style&index=0&id=476a0748&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "476a0748",
  null
  
)

export default component.exports