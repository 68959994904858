<!--
  布局简单的附件上传组件：比如在流程审批提交时，审批意见填写弹窗中，提供附件上传功能
-->
<template>
  <el-card>
    <el-upload
      v-if="!view"
      ref="uploader"
      multiple
      :data="dataForm"
      :disabled="view"
      :action="actionUrl"
      :file-list="fileList"
      :show-file-list="false"
      :before-upload="beforeFileUpload"
      :on-progress="onProgressHandle"
      :on-success="handleSuccess"
      :auto-upload="true">
      <el-button slot="trigger" size="mini" type="primary" style="padding: 5px 10px">上传文件</el-button>
      <el-button size="mini" type="danger" @click="abortUpload" style="margin-left: 10px; padding: 5px 10px">取消上传</el-button>
    </el-upload>
    <el-table
      v-loading="dataListLoading"
      :element-loading-text="loadingText"
      :data="dataList"
      highlight-current-row
      :show-header="true"
      style="width: 100%; margin-top: 2px;"
      :max-height="tableMaxHeight"
      size="mini"
      :header-cell-style="{color:'#000000'}">
      <el-table-column prop="name" label="文件名称" header-align="center" align="left" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-button type="text" @click="handleDownload(scope.row.url,scope.row)">{{ scope.row.name }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="createDate" label="上传时间" header-align="center" align="center" show-overflow-tooltip width="140"></el-table-column>
      <el-table-column prop="creatorName" label="上传人" header-align="center" align="center" show-overflow-tooltip width="120"></el-table-column>
      <el-table-column :label="$t('handle')" header-align="center" align="center" width="120">
        <template slot-scope="scope">
          <el-button v-if="!view && canDelete(scope.row)" type="text" size="mini" @click="deleteHandle(scope.row.id)">{{ $t('delete') }}</el-button>
          <el-button type="text" size="mini" @click="openUploadDownloadRecordHandle(scope.row.id)">下载记录</el-button>
        </template>
      </el-table-column>
    </el-table>
    <my-upload-download-record v-if="uploadDownloadRecordVisible" ref="uploadDownloadRecord" :ossId="currentClickId" @close="closeUploadDownloadRecordDialogHandle"></my-upload-download-record>
  </el-card>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  name: 'MyUploadPlain',
  props: {
    // 关联主表ID
    refId: String,
    // 附件分类：自定义的字符串
    typeId: {
      type: String,
      default: ''
    },
    // 附件状态：0: 临时文件、 1：永久文件、 3: 其他业务状态。比如：质检资料等
    statusId: {
      type: Number,
      default: 1
    },
    // 是否用在view页面（不可编辑）
    view: {
      type: Boolean,
      default: false
    },
    // 文件上传前校验函数。返回false,则取消本次上传
    beforeFileUploadFunc: {
      type: Function,
      default: null
    },
    // 列表最大高度:合法的值为数字或者单位为 px 的高度。
    tableMaxHeight: {
      type: [String,Number],
      default: 200,
      required: false
    },
  },
  data () {
    return {
      dataList: [],
      actionUrl: '',
      fileList: [],
      // 上传后的
      successFileList: [],
      dataListLoading: false,
      loadingText: '',
      dataForm: {
        refId: '',
        statusId: '',
        typeId: ''
      },
      // 附件下载记录
      uploadDownloadRecordVisible: false,
      currentClickId: '',
    }
  },
  watch: {
    refId: function () {
      this.init()
    },
    typeId: function () {
      this.init()
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    init () {
      this.fileList = []
      this.successFileList = []
      this.dataForm.typeId = this.typeId
      if (this.refId) {
        this.dataForm.refId = this.refId
        this.dataForm.statusId = this.statusId
        this.actionUrl = `${window.SITE_CONFIG['apiURL']}/oss/file/upload?access_token=${Cookies.get('access_token')}&tenant_code_key=${Cookies.get('tenantCode')}`
        this.getDataList()
      } else {
        return this.$message({
          message: '缺少关键属性：refId',
          type: 'error',
          duration: 3000
        })
      }
    },
    getDataList() {
      this.dataListLoading = true
      this.$http.get('/oss/file/list',
        {
          params: {
            ...this.dataForm
          }
        }
      ).then(({ data: res }) => {
        this.dataListLoading = false
        if (res.code !== 0) {
          this.dataList = []
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
      }).catch(() => {
        this.dataListLoading = false
      })
    },

    setRefId (newRefId) {
      if (this.successFileList.length > 0) {
        let ossIds = []
        this.successFileList.forEach(item => {
          ossIds.push(item.id ? item.id : item.response.data.id)
        })
        this.$http.post(`/oss/file/setRefId/${newRefId}`,ossIds)
          .then(({data: res}) => {
            if (res.code !== 0) {
              this.refId = ''
              return this.$message.error(res.msg)
            }
          }).catch(() => {
            // do nothing
          })
      }
    },

    // 上传前，让表格loading，百分比展示
    beforeFileUpload (file) {
      let isOk = true
      if (typeof this.beforeFileUploadFunc == 'function') {
        isOk = this.beforeFileUploadFunc(file)
        // 函数无返回值，默认通过校验 isOk=true
        if (isOk == null || isOk == undefined) {
          isOk = true
        }
      }
      if (isOk) {
        this.dataListLoading = true
        this.loadingText = ''
      }
      return isOk
    },
    // 上传中，显示上传进度
    onProgressHandle (event,file,fileList) {
      let progress = parseInt(event.percent)
      if (progress >= 100) {
        progress = 98
      }
      // 动态显示百分比
      this.loadingText = progress + "%"
    },

    // 上传后，表格loading结束
    handleSuccess (res,file,fileList) {
      this.successFileList = fileList
      // 每个文件上传完成（出现对号）后都会调用 handleSuccess
      this.loadingText = '100%'
      this.dataListLoading = false
      this.loadingText = ''
      this.getDataList()
    },

    // 取消上传
    abortUpload () {
      this.$refs.uploader.abort()
      this.dataListLoading = false
      this.loadingText = ''
    },

    // 点击附件名称，下载附件
    handleDownload (url,row) {
      // 保存用户下载记录
      if (row) {
        let downloadDataForm = {
          realName: this.$store.state.user.realName,
          ossId: row.id
        }
        this.$http['post']('/oss/download/',downloadDataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
        }).catch(() => {
          // do nothing
        })
      }
      // 如果url为externalUrl则不需要转换为signedUrl
      if (row && row.props) {
        let props = JSON.parse(row.props)
        if (props.externalUrl == 'yes') {
          window.open(url)
          return
        }
      }
      this.$http.get(`/oss/file/signedUrl?url=${url}`)
        .then(({data: res}) => {
          if (res.code === 0 && res.data) {
            window.open(res.data)
          } else {
            this.$message({
              message: '对不起，未查询到相关文件！',
              type: 'error',
              duration: 5000
            })
          }
        }).catch(() => {
          // do nothing
        })
    },

    // 打开附件下载记录弹窗
    openUploadDownloadRecordHandle (ossId) {
      this.uploadDownloadRecordVisible = true
      this.$nextTick(() => {
        this.currentClickId = ossId
        this.$refs.uploadDownloadRecord.init()
      })
    },

    // 关闭附件下载记录弹窗
    closeUploadDownloadRecordDialogHandle () {
      this.uploadDownloadRecordVisible = false
      this.currentClickId = ''
    },

    // 删除
    deleteHandle (id) {
      this.$confirm(this.$t('prompt.info', { 'handle': this.$t('delete') }), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$http.delete('/oss/file/',
          {data: [id]}
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.getDataList()
            }
          })
        }).catch(() => { 
          // do nothing
        })
      }).catch(() => { 
        // do nothing
      })
    },
    // 根据props中的externalUrl属性判断是否可以删除
    canDelete(row) {
      if (row.props) {
        let props = JSON.parse(row.props)
        if (props.externalUrl == 'yes') {
          return false
        }
      }
      return true
    }
  }
}
</script>
<style scoped>
  ::v-deep .el-upload {
    display: inline !important;
  }
</style>