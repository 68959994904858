<template>
  <el-dialog top="2vh" :visible.sync="visible" title="流程审批记录" width="95%" @closed="visible = false" append-to-body>
    <el-tabs v-model="activeTabName" class="tabs-height">
      <el-tab-pane label="审批记录" name="tab1" class="tab1">
        <el-table :data="processInfoTableData" border style="width: 100%" :show-header="false" :row-style="{height: '0'}" :cell-style="{padding: '3px'}">
          <el-table-column prop="k" label="k" width="150"></el-table-column>
          <el-table-column prop="v" label="v" width="*"></el-table-column>
        </el-table>
        <div class="timeline-container">
          <el-timeline>
            <el-timeline-item v-for="item in dataList" :timestamp="item.endTime" placement="top" :key="item.commentId" icon="el-icon-s-custom" color='#0bbd87'>
              <el-card>
                <el-descriptions class="margin-top" :column="4" border>
                  <template slot="title">
                    <span>
                      <!-- 注意：此处的businessStatus是节点任务的办理状态，与流程实例的状态并不是完全对应的 -->
                      <el-tag v-if="item.businessStatus == 2" type="success">通过</el-tag>
                      <el-tag v-if="item.businessStatus == 3" type="error">不通过</el-tag>
                      <el-tag v-if="item.businessStatus == 4" type="warning">驳回发起人</el-tag>
                      <el-tag v-if="item.businessStatus == 5" type="warning">退回上一步</el-tag>
                      <el-tag v-if="item.businessStatus == 7" type="error">撤回</el-tag>
                      <el-tag v-if="item.businessStatus == 10">加签</el-tag>
                      <el-tag v-if="item.businessStatus == 11" type="info">减签</el-tag>
                      <el-tag v-if="item.businessStatus == 12">转办</el-tag>
                      <el-tag v-if="item.businessStatus == 13">委派他人</el-tag>
                      <el-tag v-if="item.businessStatus == 14" type="success">委派人完成</el-tag>
                      <el-tag v-if="item.businessStatus == 15" type="success">发起人修改</el-tag>
                    </span>
                    <span class="task-name">{{ item.taskName }}</span>
                  </template>
                  <el-descriptions-item label="办理人员">{{ item.realName }}</el-descriptions-item>
                  <el-descriptions-item label="办理时间">{{ item.endTime }}</el-descriptions-item>
                  <el-descriptions-item label="接收时间">{{ item.beginTime }}</el-descriptions-item>
                  <el-descriptions-item label="耗时">{{ item.duration }}</el-descriptions-item>
                  <el-descriptions-item label="办理意见" :span="4">{{ item.comment }}</el-descriptions-item>
                </el-descriptions>
                <!-- <el-divider v-if="item.uploads != null && item.uploads.length > 0" content-position="left">附件信息</el-divider> -->
                <ul>
                  <li v-for="file in item.uploads" :key="file.id">
                    <i class="el-icon-paperclip"></i>
                    <el-button type="text" @click="handleDownload(file.signedUrl, file)">{{ file.name }}</el-button>
                    <el-button style="padding-left: 10px;" type="text" size="mini" @click="openUploadDownloadRecordHandle(file.id)">下载记录</el-button>
                  </li>
                </ul>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-tab-pane>
      <el-tab-pane label="流程图示" name="tab2">
        <!-- <img :src="diagramSrc" v-if="diagramVisible"> -->
        <process-diagram-viewer :processInstanceId="dataForm.processInstanceId"/>
      </el-tab-pane>
    </el-tabs>
    <my-upload-download-record v-if="uploadDownloadRecordVisible" ref="uploadDownloadRecord" :ossId="currentOssId" @close="closeUploadDownloadRecordDialogHandle"></my-upload-download-record>
  </el-dialog>
</template>
<script>
import Cookies from 'js-cookie'
import ProcessDiagramViewer from "@/views/modules/flowable/admin/layout/ProcessDiagramViewer"
export default {
  name: 'ProcessDetail',
  components: { ProcessDiagramViewer },
  data () {
    return {
      visible: false,
      taskId: '',
      dataForm: {
        processInstanceId: '',
      },
      diagramSrc: '',
      diagramVisible: false,
      dataList: [],
      processInfoTableData: [],
      activeTabName: 'tab1',
      // 附件下载记录
      uploadDownloadRecordVisible: false,
      currentOssId: ''
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.getProcessInfo()
        this.getTaskDetails()
        //this.getDiagramSrc()
      })
    },
    // 查询流程基本信息
    getProcessInfo() {
      this.$http.get(`/flowable/process/info/${this.dataForm.processInstanceId}`).then(({ data: res }) => {
        this.processInfoTableData = []
        this.processInfoTableData.push({k: '流程主题', v: res.data.processTitle})
        this.processInfoTableData.push({k: '流程状态', v: this.getBusinessStatus(res.data.businessStatus)})
        this.processInfoTableData.push({k: '发起用户', v: res.data.startUser.realName})
        this.processInfoTableData.push({k: '发起时间', v: res.data.startTime})
      }).catch()
    },

    // 流程状态
    getBusinessStatus(businessStatus) {
      if (businessStatus == null) {
        return '无'
      } else if (businessStatus === -1) {
        return '无'
      } else if (businessStatus === 0) {
        return '运行中'
      } else if (businessStatus === 1) {
        return '审批中'
      } else if (businessStatus === 2) {
        return '已通过'
      } else if (businessStatus === 3) {
        return '未通过'
      } else if (businessStatus === 4) {
        return '驳回起草人'
      } else if (businessStatus === 5) {
        return '退回上一步'
      } else if (businessStatus === 7) {
        return '已撤回'
      } else if (businessStatus === 8) {
        return '已挂起'
      } else if (businessStatus === 9) {
        return '被删除'
      } else {
        return '未知状态'
      }
    },

    // 查询审批记录
    getTaskDetails() {
      const loading = this.$loading({
        lock: true,
        target: '.tab1',
        text: '正在查询审批记录...',
        customClass: 'create-isLoading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.get(`/flowable/process/taskDetails/${this.dataForm.processInstanceId}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        // 头像暂时没有合理的排版样式，暂不显示
        //this.convertUsersHeadUrlToSignedUrl(res.data)
        this.dataList = res.data
      }).finally(() => {
        loading.close()
      })
    },

    // 获取流程(xml/image)url地址
    // 作废：流程图不再通过图片显示
    getDiagramSrc() {
      this.diagramSrc = `${window.SITE_CONFIG['apiURL']}/flowable/process/diagram/${this.dataForm.processInstanceId}?access_token=${Cookies.get('access_token')}&_t=${new Date().getTime()}`
      this.diagramVisible = true
    },
    // 点击附件名称，下载附件
    handleDownload(url, row) {
      // 保存用户下载记录
      if (row) {
        let downloadDataForm = {
          realName: this.$store.state.user.realName,
          ossId: row.id
        }
        this.$http['post']('/oss/download/', downloadDataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
        }).catch()
      }
      window.open(url)
    },

    // 打开附件下载记录弹窗
    openUploadDownloadRecordHandle(ossId) {
      this.uploadDownloadRecordVisible = true
      this.$nextTick(() => {
        this.currentOssId = ossId
        this.$refs.uploadDownloadRecord.init()
      })
    },

    // 关闭附件下载记录弹窗
    closeUploadDownloadRecordDialogHandle() {
      this.uploadDownloadRecordVisible = false
      this.currentOssId = ''
    },
    // 将users中的headUrl转换为signedUrl
    convertUsersHeadUrlToSignedUrl(taskDetails) {
      if (!taskDetails || taskDetails.length == 0) {
        return
      }
      taskDetails.forEach((taskDetail) => {
        let userInfo = taskDetail.userInfo
        if (userInfo && userInfo.headUrl) {
          this.$http.get(`/oss/file/signedUrl?url=${userInfo.headUrl}`).then(({data: res}) => {
            if (res.code === 0) {
              this.$set(userInfo, 'headSignedUrl', res.data)
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped>
.tabs-height {
  overflow: auto;
  height: calc(100vh - 100px);
}
.tab1 {
  overflow: auto;
  height: calc(100vh - 180px);
}
.timeline-container {
  padding-top: 10px;
}
ul, li {
  list-style: none;
  padding-left: 2px;
}
.task-name {
  font-size: 14px;
  padding-left: 10px;
}
</style>