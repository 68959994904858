import Cookies from 'js-cookie'
import qs from 'qs'
import {Encrypt} from "@/utils/encryption";
export default {
  data () {
    /* eslint-disable */
    return {
      // 设置属性
      mixinViewModuleOptions: {
        createdIsNeed: true,      // 此页面是否在创建时，调用查询数据列表接口？
        activatedIsNeed: false,   // 此页面是否在激活（进入）时，调用查询数据列表接口？
        getDataListURL: '',       // 数据列表接口，API地址
        getDataListIsPage: false, // 数据列表接口，是否需要分页？
        deleteURL: '',            // 删除接口，API地址
        deleteBatchKey: 'id',     // 删除接口，列表多选删除时，决定了根据选中项的哪个字段进行删除，默认为"id"
        exportURL: '',            // 导出接口，API地址
        showEmptyDataWarningMessage: false, // 查询结果为空时，是否显示提示信息
        fetchDataSuccessCallback: null, // 查询数据成功后的回调方法
        fetchDataFailCallback: null,    // 查询数据失败后的回调方法
        deleteSuccessCallback: null, //删除数据成功后的回调方法
        deleteWithRelatedFiles: true, // 删除时是否同时删除关联的附件
      },
      // 默认属性
      dataForm: {},               // 查询条件
      dataList: [],               // 数据列表
      order: '',                  // 排序，asc／desc
      orderField: '',             // 排序，字段
      page: 1,                    // 当前页码
      limit: 10,                  // 每页数
      total: 0,                   // 总条数
      dataListLoading: false,     // 数据列表，loading状态
      dataListSelections: [],     // 数据列表，多选项
      viewVisible: false,         // 查看，弹窗visible状态
      addOrUpdateVisible: false,  // 新增／更新，弹窗visible状态
      excelImportVisible: false   // 导入弹窗visible状态
    }
    /* eslint-enable */
  },
  created () {
    // 在页面创建时，自动切换至当前路由对应的子系统（主要是将左侧菜单列表加载为当前子系统的菜单）
    if (this.$route && this.$route.meta) {
      let subsystemId = this.$route.meta.subsystemId
      if (subsystemId) {
        this.$bus.$emit('refreshSidebarMenus', subsystemId)
      }
    }

    if (this.mixinViewModuleOptions.createdIsNeed) {
      this.query()
    }
  },
  activated () {
    if (this.mixinViewModuleOptions.activatedIsNeed) {
      this.query()
    }
  },
  methods: {
    // 获取数据列表
    query () {
      if (!this.mixinViewModuleOptions.getDataListURL) {
        return
      }
      this.dataListLoading = true
      this.$http.get(
        this.mixinViewModuleOptions.getDataListURL,
        {
          params: {
            order: this.order,
            orderField: this.orderField,
            page: this.mixinViewModuleOptions.getDataListIsPage ? this.page : null,
            limit: this.mixinViewModuleOptions.getDataListIsPage ? this.limit : null,
            ...this.dataForm
          }
        }
      ).then(({ data: res }) => {
        this.dataListLoading = false
        if (res.code !== 0) {
          this.dataList = []
          this.total = 0
          if (typeof this.mixinViewModuleOptions.fetchDataFailCallback === 'function') {
            this.mixinViewModuleOptions.fetchDataFailCallback()
          }
          return this.$message.error(res.msg)
        }
        this.dataList = this.mixinViewModuleOptions.getDataListIsPage ? res.data.list : res.data
        this.total = this.mixinViewModuleOptions.getDataListIsPage ? res.data.total : 0
        if (this.mixinViewModuleOptions.showEmptyDataWarningMessage) {
          if (!this.dataList || this.dataList.length === 0) {
            return this.$message({
              message: '未查询到相关数据',
              type: 'warning',
              duration: 1000
            })
          }
        }
        if (typeof this.mixinViewModuleOptions.fetchDataSuccessCallback === 'function') {
          this.mixinViewModuleOptions.fetchDataSuccessCallback(this.mixinViewModuleOptions.getDataListIsPage ? res.data.list : res.data)
        }
      }).catch(() => {
        this.dataListLoading = false
        if (typeof this.mixinViewModuleOptions.fetchDataFailCallback === 'function') {
          this.mixinViewModuleOptions.fetchDataFailCallback()
        }
      })
    },
    // 多选
    dataListSelectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 排序
    dataListSortChangeHandle (data) {
      if (!data.order || !data.prop) {
        this.order = ''
        this.orderField = ''
        return false
      }
      this.order = data.order.replace(/ending$/, '')
      this.orderField = data.prop.replace(/([A-Z])/g, '_$1').toLowerCase()
      this.query()
    },
    // 分页, 每页条数
    pageSizeChangeHandle (val) {
      this.page = 1
      this.limit = val
      this.query()
    },
    // 分页, 当前页
    pageCurrentChangeHandle (val) {
      this.page = val
      this.query()
    },
    getDataList: function () {
      this.page = 1
      this.query()
    },
    // 查看
    viewHandle (id) {
      this.viewVisible = true
      this.$nextTick(() => {
        this.$refs.view.dataForm.id = id
        this.$refs.view.init()
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.init()
      })
    },
    // 关闭弹窗时将其销毁
    closeDialogHandle () {
      this.addOrUpdateVisible = false
      this.viewVisible = false
    },
    // 关闭当前窗口
    closeCurrentTab (data) {
      var tabName = this.$store.state.contentTabsActiveName
      this.$store.state.contentTabs = this.$store.state.contentTabs.filter(item => item.name !== tabName)
      if (this.$store.state.contentTabs.length <= 0) {
        this.$store.state.sidebarMenuActiveName = this.$store.state.contentTabsActiveName = 'home'
        return false
      }
      if (tabName === this.$store.state.contentTabsActiveName) {
        this.$router.push({ name: this.$store.state.contentTabs[this.$store.state.contentTabs.length - 1].name })
      }
    },
    /**
     * 删除
     * @param id 待删除的数据行id，类型：String | Number | Array
     * @param deleteSuccessCallbackArgs 删除成功后回调方法的参数,默认为id,如果没有配置mixinViewModuleOptions.deleteSuccessCallback,则此参数无效
     * @param promptMessage 删除前的弹窗提示信息，默认为'确定进行[删除]操作?'
     * @param promptTitle 删除前的弹窗提示信息，默认为'确定进行[删除]操作?'
     * @param autoQuery 删除成功后是否自动查询列表数据，默认为true
     */
    deleteHandle (id, {deleteSuccessCallbackArgs, promptMessage, promptTitle, autoQuery} = {autoQuery: true}) {
      if (!id && this.dataListSelections.length <= 0) {
        return this.$message({
          message: this.$t('prompt.deleteBatch'),
          type: 'warning',
          duration: 1500
        })
      }
      this.$confirm(promptMessage ?? this.$t('prompt.info', { 'handle': this.$t('delete') }), promptTitle ?? this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          text: '正在删除...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.delete(
          `${this.mixinViewModuleOptions.deleteURL}`,
          {
            'data': id ? [id] : this.dataListSelections.map(item => item[this.mixinViewModuleOptions.deleteBatchKey])
          }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          // 删除关联的附件
          if (this.mixinViewModuleOptions.deleteWithRelatedFiles) {
            this.$http.delete(
              '/oss/file/deleteByRefIds',
              {
                'data': id ? [id] : this.dataListSelections.map(item => item[this.mixinViewModuleOptions.deleteBatchKey])
              }
            )
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 1500,
            onClose: () => {
            }
          })
          if (autoQuery) {
            this.query()
          }
          if (typeof this.mixinViewModuleOptions.deleteSuccessCallback === 'function') {
            this.mixinViewModuleOptions.deleteSuccessCallback(deleteSuccessCallbackArgs === undefined ? id : deleteSuccessCallbackArgs)
          }
        }).catch(() => {
          // do nothing
        }).finally(() => {
          loading.close()
        })
      }).catch(() => { 
        // do nothing
      })
    },
    // 导入（TODO 缺陷是组件的模板代码需要在各个页面中重复出现）
    importHandle () {
      this.excelImportVisible = true
      let _this = this
      this.$nextTick(() => {
        this.$refs.excelImport.init(() => {
          // 解决导入组件实时获取到页面中动态变化的参数
          return qs.stringify(_this.dataForm)
        })
      })
    },
    // 导出
    exportHandle () {
      const loading = this.$loading({
        text: '正在导出，请耐心等待...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      var params = qs.stringify({
        'access_token': Cookies.get('access_token'),
        'tenant_code_key': Cookies.get('tenantCode'),
        ...this.dataForm
      })
      window.location.href = `${window.SITE_CONFIG['apiURL']}${this.mixinViewModuleOptions.exportURL}?${params}`
      window.setTimeout(function (){
        loading.close()
      }, 2000)
    },
    /**
     * 打开附件上传抽屉组件
     * @param {*} refId 附件关联ID
     * @param {*} types typeId数组
     * @param {*} readOnly 是否只读
     * @param {*} title 抽屉标题
     * @param {*} onclose 抽屉关闭时回调函数
     * @param {*} hideDefaultTypeMenu 是否隐藏 “全部”类型（即：只能按类型分别查看附件，不显示全部选项）
     */
    uploadDrawerHandle (refId, types, readOnly, title, onclose, hideDefaultTypeMenu) {
      this.$bus.$emit('showUploadDrawer', refId, types, readOnly, title, onclose, hideDefaultTypeMenu);
    },
    /**
     * 在新窗口中打开文档编辑器
     * @param ossId oss表id
     * @param action "edit"或"view"
     */
    openDocumentEditor(ossId, action) {
      let query = JSON.stringify({
        ossId: ossId,
        action: action,
        type: 'desktop',
      })
      let pathHerf = this.$router.resolve({
        path:'/document-editor', // 你自己要跳转的路径
        query:{
          param: Encrypt(query)
        }
      })
      window.open(pathHerf.href, '_blank');
    }
  }
}
