<template>
  <el-tooltip placement="top" :content="dictTypeTooltip" effect="light" :enterable="enterable" :open-delay="500">
    <el-select @change="handleChange" @clear="handleChange" :value="value" @input="$emit('input', $event)" :placeholder="(!placeholder && disabled) ? '' : placeholder" :disabled="disabled" clearable>
      <el-option :label="data.dictLabel" v-for="data in dataList" :key="data.dictValue" :value="data.dictValue">{{ data.dictLabel }}</el-option>
    </el-select>
  </el-tooltip>
</template>
<script>
// 数据字典下拉选组件
import {getDictDataList} from '@/utils'

export default {
  name: 'DictSelect',
  data() {
    return {
      dataList: getDictDataList(this.dictType),
      enterable: false,
      hover: false,
    }
  },
  computed: {
    dictTypeTooltip() {
      return this.dictType ? '数据字典:' + this.dictType : ''
    }
  },
  props: {
    value: String,
    dictType: String,
    placeholder: {
      type: String,
      default: '请选择'
    },
    disabled: Boolean
  },
  methods: {
    getItemById(id) {
      let item = this.dataList.find((item) => {
        return item.dictValue === id
      })
      if (item) {
        return item
      } else {
        return null
      }
    },
    handleChange(event) {
      this.$emit('change', this.getItemById(event))
    }
  }
}
</script>
