<template>
  <div style="height: 35px !important;padding-bottom: 5px !important;">
    <div style="padding-bottom: 5px; display: inline;">
      <!-- 用于起草人提交被驳回的流程 -->
      <el-button-group v-if="handleState == 3">
        <el-button size="mini" type="primary" @click="drafterSubmitHandle()">提交</el-button>
        <el-button size="mini" @click="showProcessDetail()">查看审批记录</el-button>
      </el-button-group>

      <el-button-group v-if="handleState == 2 && delegationState != 'PENDING'">
        <el-button size="mini" type="primary" @click="completeTask()">通过</el-button>
        <el-button size="mini" type="danger" @click="terminationTask()">不通过</el-button>
        <el-button size="mini" @click="rejectTask()">驳回发起人</el-button>
        <el-button size="mini" @click="rollbackTask()">退回上一步</el-button>
        <el-button size="mini" @click="delegateTask()">委派</el-button>
        <el-button size="mini" @click="assignTask()">转办</el-button>
        <el-button size="mini" @click="addMultiTask()">加签</el-button>
        <el-button size="mini" @click="deleteMultiTask()">减签</el-button>
        <el-button size="mini" @click="showProcessDetail()">查看审批记录</el-button>
        <!-- 电子签名组件 -->
        <pdf-sign-button v-if="pdfOssId" :ossId="pdfOssId"></pdf-sign-button>
      </el-button-group>

      <!-- 特别注意：委派人办理时，不能操作通过、不通过、退回、驳回等按钮，被委派人只允许点击委派完成按钮 -->
      <el-button-group v-if="handleState == 2 && delegationState == 'PENDING'">
        <el-button v-if="delegationState == 'PENDING'" size="mini" type="primary" @click="resolveTask()">完成</el-button>
        <el-button size="mini" @click="showProcessDetail()">查看审批记录</el-button>
      </el-button-group>

      <el-button-group v-if="handleState == 1">
        <el-button size="mini" @click="showProcessDetail()">查看审批记录</el-button>
      </el-button-group>
      
      <!-- 在加签弹窗中可查看到签上的人 -->
      <!-- <el-button size="mini" @click="queryMultiUsersInfo()">查询签上的人</el-button> -->

      <!-- 流程标题（改为通过Notification显示） -->
      <!-- <div style="position: absolute; top:45px; left: 4px; display: inline; font-size: 12px; font-weight: bold;">
        <span>【流程主题】</span>
        <el-input v-if="handleState == 3" size="mini" v-model="processInstanceTitle" style="width: 380px"></el-input>
        <span v-else>{{ processInstanceTitle }}</span>
      </div> -->

    </div>

    <!-- 流程审批记录和流程图 -->
    <process-detail v-if="processDetailComponentVisible" ref="processDetail" @close="closeProcessDetailHandle()"></process-detail>

    <!-- 通过 -->
    <agree-modal :visible.sync="modalConfig.agreeVisible" :processInfo="processInfo" @success="taskHandleSuccessCallback"/>
    <!-- 不通过 -->
    <refuse-modal :visible.sync="modalConfig.refuseVisible" :processInfo="processInfo" @success="taskHandleSuccessCallback"/>
    <!-- 委派 -->
    <delegate-modal :visible.sync="modalConfig.delegateVisible" :processInfo="processInfo" @success="taskHandleSuccessCallback"/>
    <!-- 委派人完成 -->
    <resolve-modal :visible.sync="modalConfig.resolveVisible" :processInfo="processInfo" @success="taskHandleSuccessCallback"/>
    <!-- 转办 -->
    <assign-modal :visible.sync="modalConfig.assignVisible" :processInfo="processInfo" @success="taskHandleSuccessCallback"/>
    <!-- 加签 -->
    <add-multi-modal :visible.sync="modalConfig.addMultiVisible" :processInfo="processInfo" @success="taskHandleSuccessCallback"/>
    <!-- 查到签上的人 -->
    <query-multi-user-modal :visible.sync="modalConfig.queryMultiUserVisible" :processInfo="processInfo" />
    <!-- 减签 -->
    <delete-multi-modal :visible.sync="modalConfig.deleteMultiVisible" :processInfo="processInfo" @success="taskHandleSuccessCallback"/>
    <!-- 退回上一步 -->
    <rollback-modal :visible.sync="modalConfig.rollbackVisible" :processInfo="processInfo" @success="taskHandleSuccessCallback"/>
    <!-- 驳回发起人 -->
    <reject-modal :visible.sync="modalConfig.rejectVisible" :processInfo="processInfo" @success="taskHandleSuccessCallback"/>

  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import AgreeModal from './modal/AgreeModal'
import RefuseModal from './modal/RefuseModal'
import DelegateModal from './modal/DelegateModal'
import ResolveModal from './modal/ResolveModal'
import AssignModal from './modal/AssignModal'
import AddMultiModal from './modal/AddMultiModal'
import QueryMultiUserModal from './modal/QueryMultiUserModal'
import DeleteMultiModal from './modal/DeleteMultiModal'
import RollbackModal from './modal/RollbackModal'
import RejectModal from './modal/RejectModal'
import PdfSignButton from "@/components/signature/pdf-sign-button";

export default {
  name: 'ProcessRunning',
  data () {
    return {
      // 用于标识出当前页面的状态（1.查看，2.正常办理待办流程，3.发起人编辑被驳回的流程）
      handleState: 1,
      // 流程审批记录组件
      processDetailComponentVisible: false,
      processInfo: {
        processInstanceId: '',
        taskId: '',
      },
      processInstanceTitle: '', // 流程主题
      processTitleNotification: null,
      drafterNotification: null,
      businessStatus: null,
      delegationState: '', // 用于标识出当前任务是否为委派任务(委派任务不能使用正常的"同意"，需要使用"委派人同意")
      modalConfig: {
        agreeVisible: false,
        delegateVisible: false,
        assignVisible: false,
        resolveVisible: false,
        refuseVisible: false,
        rollbackVisible: false,
        rejectVisible: false,
        addMultiVisible: false,
        queryMultiUserVisible: false,
        revokeVisible: false,
        deleteMultiVisible: false
      },
    }
  },
  components: {
    AgreeModal,
    RefuseModal,
    DelegateModal,
    ResolveModal,
    AssignModal,
    AddMultiModal,
    QueryMultiUserModal,
    DeleteMultiModal,
    RollbackModal,
    RejectModal,
    PdfSignButton
  },
  props: {
    // 用于签名的pdf文件的ossId
    pdfOssId:{
      type: [String, Number],
    }
  },
  created () {
    this.$nextTick(() => {
      // 标识出当前页面的状态（1.查看，2.正常办理待办流程，3.发起人编辑被驳回的流程）
      if (this.$route.params.processShowType === 'taskHandle') {
        this.handleState = 2
      } else if (this.$route.params.processShowType === 'draftTaskHandle') {
        this.handleState = 3
      } else {
        this.handleState = 1
      }
      this.processInstanceTitle = this.$route.params.processTitle
      this.delegationState = this.$route.params.delegationState ? this.$route.params.delegationState : ''
      this.processInfo = {
        processInstanceId: this.$route.params.processInstanceId,
        taskId: this.$route.params.taskId,
      }
      this.businessStatus = this.$route.params.businessStatus
      if (this.handleState === 3) {
        // 起草人在待办流程中打开被驳回的流程时，弹出提示
        if (this.drafterNotification) {
          this.drafterNotification.close()
        }
        this.drafterNotification = this.$notify({
          title: '重要提示',
          type: 'warning',
          dangerouslyUseHTMLString: true,
          message: `当前流程被驳回，请参考<span style="color: red">[审批意见]</span>，尽快按审核意见修改并提交流程！`,
          onClick: () => {
            this.showProcessDetail()
          },
          duration: 120000, // 提示2分钟
        })
      }
      if (this.processTitleNotification) {
        this.processTitleNotification.close()
      }
      this.processTitleNotification = this.$notify({
        title: '流程主题',
        type: 'info',
        dangerouslyUseHTMLString: true,
        message: this.processInstanceTitle,
        duration: 0
      })
    })
  },
  beforeDestroy() {
    if (this.processTitleNotification) {
      this.processTitleNotification.close()
    }
    if (this.drafterNotification) {
      this.drafterNotification.close()
    }
  },
  methods: {
    // 通过
    completeTask () {
      this.modalConfig.agreeVisible = true;
    },
    // 不通过
    terminationTask () {
      this.modalConfig.refuseVisible = true
    },
    // 委派
    delegateTask() {
      this.modalConfig.delegateVisible = true
    },
    // 委派人完成
    resolveTask() {
      this.modalConfig.resolveVisible = true
    },
    // 转办
    assignTask() {
      this.modalConfig.assignVisible = true
    },
    // 加签
    addMultiTask() {
      this.modalConfig.addMultiVisible = true
    },
    // 查询签上的人
    queryMultiUsersInfo() {
      this.modalConfig.queryMultiUserVisible = true
    },
    // 减签
    deleteMultiTask() {
      this.modalConfig.deleteMultiVisible = true
    },
    // 退回
    rollbackTask() {
      this.modalConfig.rollbackVisible = true
    },
    // 驳回
    rejectTask () {
      this.modalConfig.rejectVisible = true
    },
    // 提交：起草人提交被驳回的流程
    drafterSubmitHandle: debounce(function () {
      return this.$confirm('您确定已完成修改，并重新提交该流程吗？').then(() => {
        // 1、设置默认审核意见为“提交”，审核结果为“通过”。
        // 2、业务表单校验并提交保存。
        if (typeof this.processInstanceTitle == 'string' && this.processInstanceTitle.length > 0) {
          this.$emit('submitCallback', (fn) => {
            let data = {
              ...this.processInfo,
              comments: '发起人提交',// 注意：此处comments固定为“发起人提交”，不要改动，后台微服务端会根据该字符串判断
              newProcessInstanceTitle: this.processInstanceTitle
            }
            
            this.$http.post(`/flowable/process/agree`, data).then(({ data: res }) => {
              if (res.code !== 0) {
                return this.$message.error(res.msg)
              }
              this.$message.success("提交成功")
              if (typeof fn === 'function') {
                fn()
              }
              this.taskHandleSuccessCallback()
            })
          })
        } else {
          return this.$message.error('请填写流程主题！')
        }
      }).catch(e => e)
    }, 1000, { 'leading': true, 'trailing': false }),
    // 成功时的回调函数
    taskHandleSuccessCallback (res) {
      this.$emit('successCallback', res)
      // 触发首页绑定的事件总线，用户处理掉某流程后刷待办列表（包括新首页）
      this.$bus.$emit('refreshHomeUnFinishedWf')
      this.$bus.$emit('refreshTodoWfList')
    },
    closeProcessDetailHandle () {
      this.processDetailComponentVisible = false
    },
    showProcessDetail () {
      this.processDetailComponentVisible = true
      this.$nextTick(() => {
        this.$refs.processDetail.dataForm.processInstanceId = this.processInfo.processInstanceId
        this.$refs.processDetail.init()
      })
    },
  }
}
</script>
<style scoped>
::v-deep .custom_button{
  background-color: #A5DEE4;
}
</style>