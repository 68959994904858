<template>
  <el-dialog top="5vh" v-dialog-drag append-to-body width="60%" :visible.sync="visible" title="[人员选择]" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <my-container>
      <template v-slot:left>
        <div class="tree-container">
          <el-tree ref="deptTree"
              lazy
              class="left-tree"
              node-key="id"
              v-loading="treeLoading"
              :data="treeData"
              :props="defaultProps"
              :load="loadTreeNode"
              :expand-on-click-node="true"
              @node-click="treeNodeClick"
              :default-expanded-keys="expandedKeys"
              :highlight-current="true">
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
          <el-form-item>
            <el-input v-model="dataForm.realName" placeholder="姓名" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="dataForm.mobile" placeholder="手机号" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button-group style="margin-top: -3px;">
              <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            </el-button-group>
          </el-form-item>
        </el-form>

        <el-table
          ref="userTable"
          :data="dataList"
          border
          :max-height="tableHeight"
          @select-all="selectAllChange"
          @select="selectChange"
          highlight-current-row
          v-loading="dataListLoading">
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column prop="realName" label="姓名" width="150"></el-table-column>
          <el-table-column prop="mobile" label="手机号" width="120"></el-table-column>
          <el-table-column prop="deptName" label="部门"></el-table-column>
        </el-table>
        <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
        </el-pagination>
      </template>
    </my-container>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
// 人员多选（左侧组织树，右侧人员列表）
import debounce from 'lodash/debounce'
import mixinViewModule from '@/mixins/view-module'
export default {
  mixins: [mixinViewModule],
  name: 'UserMultiSelect',
  data() {
    return {
      visible: false,
      mixinViewModuleOptions: {
        createdIsNeed: false,
        getDataListURL: '/sys/user/openapi/dialogPage',
        getDataListIsPage: true,
        fetchDataSuccessCallback: this.fetchDataSuccessCallback
      },
      dataForm: {
        deptId: '',
        realName: '',
        mobile: ''
      },
      tableHeight: 450,
      treeLoading: false,
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        isLeaf: 'isLeaf'
      },
      selectedList: [],
      selectedMap: new Map(),
      expandedKeys: [],
      selectedRow: null, // 选中的行（用于单选模式）
    }
  },
  props: {
    // 是否单选（默认多选）
    singleSelect: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.getTreeData()
        this.getDataList()
      })
    },
    // 加载组织部门根节点
    getTreeData() {
      this.treeLoading = true
      this.treeData = []
      this.$http.get(`/sys/dept/openapi/children/0`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.treeData = res.data || []
        // 实现默认展开第一级节点
        if (res.data && res.data.length > 0) {
          this.expandedKeys = [res.data[0].id]
        }
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.treeLoading = false
      })
    },
    // 异步加载部门
    loadTreeNode(node, resolve) {
      if (node.level >= 1) {
        if (node && node.data) {
          let nodeData = node.data;
          this.$http.get(`/sys/dept/openapi/children/${nodeData.id}`).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            resolve(res.data);
          }).catch(() => {
            return this.$message.error('出错了')
          })
        } else {
          return resolve([])
        }
      }
    },
    // 点击组织部门查询对应人员
    treeNodeClick (data, node, ele) {
      // 点击高亮节点则取消高亮
      if (this.dataForm.deptId == data.id) {
        this.dataForm.deptId = ''
        this.$refs.deptTree.setCurrentKey(null)
      } else {
        this.dataForm.deptId = data.id
      }
      // 查询数据
      this.getDataList()
    },
    fetchDataSuccessCallback() {
      if (this.dataList && this.dataList.length > 0) {
        this.$nextTick(() => {
          if (this.selectedList && this.selectedList.length > 0) {
            for (let obj of this.dataList) {
              if (this.selectedList.findIndex(s=>s == obj.id) > -1) {
                this.selectedMap.set(obj.id, obj)
                this.$refs.userTable.toggleRowSelection(obj, true)
              }
            }
          }
        })
      }
    },
    // checkbox点击时
    selectChange(selection, row) {
      this.changeSelectData(selection, row);
    },
    // 全选
    selectAllChange(selection) {
      if (this.singleSelect) {
        this.$refs.userTable.clearSelection()
        return this.$message.error('只允许选择一个用户')
      }
      if (this.selectedList.length == 0) {
        this.selectedList = selection.map(s=>s.id)
        selection.forEach((obj) => {
          this.selectedMap.set(obj.id, obj)
        })
      } else {
        if (selection.length == 0) {
          this.dataList.forEach(res => {
            const index = this.selectedList.findIndex(s=>s == res.id)
            if (index > -1) {
              this.selectedList.splice(index, 1);
              this.selectedMap.delete(res.id)
            }
          })
        } else {
          selection.forEach(res => {
            const index = this.selectedList.findIndex(s=>s == res.id)
            if (index == -1) {
              this.selectedList.push(res.id);
              this.selectedMap.set(res.id, res)
            }
          })
        }
      }
    },
    // 选中存储方法
    changeSelectData(selection, row) {
      if (this.singleSelect) {// 单选
        this.selectedList.shift()
        this.selectedMap.clear()
        if (selection.length > 1) {
          // 移除选中行数据
          selection.shift()
          // 修改选中图标为未选中状态
          this.$refs.userTable.clearSelection()
          // 将当前选中行改为选中状态
          this.$refs.userTable.toggleRowSelection(selection[0])
        }
        this.selectedList.push(row.id)
        this.selectedMap.set(row.id, row)
      } else {
        let exist = false
        const index = this.selectedList.findIndex(s=>s == row.id)
        if (index > -1) {
          exist = true;
          this.selectedList.splice(index, 1)
          this.selectedMap.delete(row.id)
        } else {
          this.selectedList.push(row.id)
          this.selectedMap.set(row.id, row)
        }
        this.$refs.userTable.toggleRowSelection(row, !exist)
      }
    },
    // 提交数据
    dataFormSubmitHandle: debounce(function () {
      this.$emit('submit', this.selectedList)
      let allDataArr = []
      this.selectedMap.forEach((value, key) => {
        allDataArr.push(value)
      })
      this.$emit('submitAllData', allDataArr)
      this.visible = false
    }, 1000, {'leading': true, 'trailing': false}),
  }
}
</script>
<style scoped>
.tree-container {
    overflow: auto;
    height: 500px;
  }
</style>
