<template>
  <div>
    <component :is="(selectNode.type||'').toLowerCase()" :config="selectNode.props"/>
  </div>
</template>

<script>
import Approval from './ApprovalNodeConfig.vue'
import Task from './TaskNodeConfig.vue'
import Condition from './ConditionNodeConfig.vue'
import Inclusive from './InclusiveNodeConfig.vue'
import Delay from './DelayNodeConfig.vue'
import Cc from './CcNodeConfig.vue'
import Trigger from './TriggerNodeConfig.vue'
import Root from './RootNodeConfig.vue'
import Subprocess from './SubprocessNodeConfig.vue'
import Concurrent from './ConcurrentNodeConfig.vue'

export default {
  name: "NodeConfig",
  components: {
    Approval,
    Task,
    Condition,
    Inclusive,
    Trigger,
    Delay,
    Root,
    Cc,
    Subprocess,
    Concurrent
  },
  data() {
    return {
      active: 'properties',
    }
  },
  computed: {
    selectNode() {
      return this.$store.state.selectedNode
    },
  },
  methods: {}
}
</script>

<style lang="less" scoped>

</style>
