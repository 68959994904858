<template>
  <vxe-modal width="800px" v-model="modalOpen" title="创建手写签名" destroy-on-close showFooter>
    <div style="width: 720px;padding-left: 40px;">
      <div style="padding-bottom: 20px;">
        <div class="signature-box" :class="!startSignature?'signature-bg':''">
          <VueSignaturePad ref="signaturePad" :images="[{ src: 'A.png', x: 0, y: 0 }, { src: 'B.png', x: 0, y: 10 }, { src: 'C.png', x: 0, y: 20 }]" :options="options"/>
        </div>
      </div>
    </div>
    <template #footer>
      <div style="text-align: center;">
        <el-button-group>
          <el-button @click="undoSignature" size="mini" type="info">撤销</el-button>
          <el-button @click="clearSignature" size="mini" type="danger">重写</el-button>
        </el-button-group>
        <el-button-group style="margin-left: 20px">
          <el-button @click="modalOpen = false" size="mini">取消</el-button>
          <el-button @click="handleOk" size="mini" type="primary">使用</el-button>
        </el-button-group>
      </div>
    </template>
  </vxe-modal>
</template>

<script>
import {VueSignaturePad} from 'vue-signature-pad'

export default {
  name: 'SignatureModal',
  data() {
    return {
      modalOpen: false,
      //手写面板是否已经开始了  如果开始把前面面板的背景色去掉
      startSignature: false,
      //签名面板配置
      options: {
        penColor: "#000",
        dotSize: (1 + 4) / 2,
        minWidth: 1,
        maxWidth: 4,
        throttle: 16,
        minDistance: 5,
        backgroundColor: 'rgba(0,0,0,0)',
        velocityFilterWeight: 0.5,
        onBegin: () => {
          this.startSignature = true;
        },
        onEnd: () => {
        }
      }
    }
  },
  components: {
    VueSignaturePad
  },
  props: {
    modalVisible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    //监听父页面的modalVisible 是否需要打开弹窗
    modalVisible() {
      if (this.modalVisible) {
        this.modalOpen = true
      }
    },
  },
  methods: {
    /**
     * 提交手写签名
     */
    handleOk() {
      //获取签名图片
      const {isEmpty, data} = this.$refs.signaturePad.saveSignature()
      if (isEmpty) {
        return this.$message({
          message: '签名为空，请书写后再点击使用',
          type: 'warning',
          duration: 2000
        })
      }
      const resultImg = data.split(",")
      //将签名图片返给父组件
      const result = {image: resultImg[1]}
      this.$emit('success', result)
      this.modalOpen = false
    },
    /**
     * 撤销
     */
    undoSignature() {
      this.$refs.signaturePad.undoSignature()
    },
    /**
     * 重写
     */
    clearSignature() {
      this.$refs.signaturePad.clearSignature()
      this.startSignature = false
    }
  },
}


</script>

<style lang="less" scoped>
.signature-box {
  width: 100%;
  height: 360px;
  border: 1px solid #dedede;
}

.signature-bg {
  background: url('/src/assets/images/signature-bg.png') no-repeat;
  background-size: 70% 70%;
  background-position: 50% 50%;
}
</style>