<template>
  <el-tooltip placement="top-start" :disabled="tooltipDisabled || cascaderValue.length == 0" :content="tooltipContent" effect="light" enterable :open-delay="800">
    <el-cascader
      v-model="cascaderValue"
      :options="options"
      :props="props"
      :filterable="filterable"
      :placeholder="disabled ? '' : placeholder"
      :disabled="disabled"
      :show-all-levels="JSON.parse(showAllLevels)"
      style="width: 100%"
    >
    
    <template slot-scope="{ data }">
      <span>{{ data.shortName }}</span>
    </template>

    </el-cascader>
  </el-tooltip>
</template>
<script>
export default {
  /**
   * 通过change事件传递所选内容
   * 事件参数:
   * {
   *  prjId: 项目id
   *  contractId: 标段id
   *  subcontractorId: 工区id
   *  project: 项目对象
   *  contract: 标段对象
   *  subcontractor: 工区对象
   * }
   * 注：
   * 1.如果不需要选工区，则没有工区id和工区对象属性
   * 2.几个对象中，包含了部分重要属性，例如名称，标段对象中包含了计量方式、是否有工区等
   */
  name: 'ContractCascader',
  data () {
    return {
      op: false,
      options: [],
      cascaderValue: [],
      props: {
        expandTrigger: 'hover',
        value: 'id',
        label: 'shortName',
        // emitPath: false,
      },
      // 以下3个map，用于快速的通过id获取到对应的对象
      // 因为el-cascader组件在选中某一项后，只能将对应的id返回
      projectMap: new Map(),
      contractMap: new Map(),
      subcontractorMap: new Map(),
      projectName: '',
      contractName: '',
      subcontractorName: '',
      // 若只有一个项目，则跳过项目选择，并用此属性存储唯一的项目id
      prjId: '',
    }
  },
  created: function () {
    let projectList = []

    let projectUrl = ''
    if (this.type && this.type == 'all') {
      projectUrl = '/mps/project/openapi/briefsForMe'
    } else {
      projectUrl = '/mps/project/openapi/briefsForUser'
    }
    this.$http.get(projectUrl).then(({ data: res }) => {
      if (res.code === 0) {
        projectList = res.data
        projectList.forEach((project) => {
          this.projectMap.set(project.id, project)
          // 因为el-cascader组件默认是只能选中叶节点，有一种极端情况是，如果某用户有某项目的权限，而没有项目下的标段权限
          // 那么在上述情况下，就会出现此项目是叶节点，从而能被用户选中，与此组件本身的需求自相矛盾了（此组件是必须选到标段或工区的）
          // 因此为了避免这种bug，在遍历项目时，先将其设为不可被选中（在此状态下，就算有子节点，也是无法选中的），
          // 然后在之后遍历权限内标段时，如果某项目下确实有标段子节点，再将此项目的状态设为可选中（虽然设为了可选中，但因为有子节点，所以只能选中其子节点）
          // 注：后面对于标段的处理与此处对项目的处理，是基于类似的原因，不再详述
          project.disabled = true
        })
        let contractUrl = ''
        if (this.type && this.type == 'all') {
          contractUrl = '/mps/contract/openapi/briefs'
        } else {
          contractUrl = '/mps/contract/openapi/briefsForUser'
        }
        this.$http.get(contractUrl).then(({ data: res }) => {
          if (res.code === 0) {
            let contractList = res.data
            contractList.forEach((contract) => {
              this.contractMap.set(contract.id, contract)
              // 此处的处理原因已经在上面项目处详细解释，此处不再详述
              // 只有在此组件用于选择工区，且此标段的状态为“有工区”时，才需要进行处理
              if (this.includeSub && contract.hasSubcontractor) {
                contract.disabled = true
              }
              // 将标段放入对应的项目的children数组里，组成树结构
              let project = this.projectMap.get(contract.prjId)
              if (project) {
                if (!project.children) {
                  project.children = []
                }
                project.children.push(contract)
                project.disabled = false
              }
            })
            // 如果用于工区选择
            if (this.includeSub) {
              let subcontractorUrl = ''
              if (this.type && this.type == 'all') {
                subcontractorUrl = '/mps/subcontractor/openapi/briefs'
              } else {
                subcontractorUrl = '/mps/subcontractor/openapi/briefsForUser'
              }
              this.$http.get(subcontractorUrl).then(({ data: res }) => {
                if (res.code === 0) {
                  let subcontractorList = res.data
                  subcontractorList.forEach((subcontractor) => {
                    this.subcontractorMap.set(subcontractor.id, subcontractor)
                    // 将工区放入对应的标段的children数组里，组成树结构
                    let contract = this.contractMap.get(subcontractor.contractId)
                    if (contract) {
                      if (!contract.children) {
                        contract.children = []
                      }
                      contract.children.push(subcontractor)
                      contract.disabled = false
                    }
                  })
                  // 如果只有一个可选的项目，则隐藏项目选择，选项树第一级改为标段选择
                  if (projectList.length == 1) {
                    this.prjId = projectList[0].id
                    this.options = projectList[0].children
                  } else {
                    this.options = projectList
                  }
                  // 选项树组装完成后，调用自动选中的方法
                  this.defaultSelect()
                }
              }).catch(() => {
                // do nothing
              })
            } else {
              // 如果只有一个可选的项目，则隐藏项目选择，选项树第一级改为标段选择
              if (projectList.length == 1) {
                this.prjId = projectList[0].id
                this.options = projectList[0].children
              } else {
                this.options = projectList
              }
              // 选项树组装完成后，调用自动选中的方法
              this.defaultSelect()
            }
          }
        }).catch(() => {
          // do nothing
        })
      }
    }).catch(() => {
      // do nothing
    })
  },
  computed: {
    tooltipContent: function () {
      if (this.subcontractorName) {
        return this.projectName + ' / ' + this.contractName + ' / ' + this.subcontractorName
      } else {
        return this.projectName + ' / ' + this.contractName
      }
    }
  },
  props: {
    placeholder: {
      type: String,
      default: '加载中'
    },
    disabled: Boolean,
    filterable: Boolean,
    /**
     * 查询类型 (不传此参数则查询权限内数据)
     * all: 查询所有数据（包括无权限数据）
     */
    type: String,
    /**
     * 是否需要选择工区，默认为否
     */
    includeSub: {
      type: Boolean,
      default: false
    },

    /**
     * 是否显示名称路径（例如：XX项目/XX标段/XX工区）（默认不显示）
     */
    showAllLevels: {
      type: [String, Boolean],
      default: false
    },
    /**
     * 是否禁用tooltip,默认启用
     */
    tooltipDisabled: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    // 默认选中一项，优先级为：
    // 1.之前选中过某标段/工区并缓存至localStorage，则此处继续之前的选择
    // 2.localStorage查不到之前的缓存，或者之前的缓存数据有误（项目标段工区之间不是正确的上下级关系），则自动选中第一个可选的选项
    defaultSelect() {
      if (this.options.length <= 0) {
        return
      }
      let globalPrjId = localStorage.getItem('prjId')
      let globalContractId = localStorage.getItem('contractId')
      let globalSubcontractorId = localStorage.getItem('subcontractorId')
      // 1.如果不存在prjId；或prjId存在（即只有一个待选项目，项目选择被隐藏）,且与全局缓存相同
      // 2.如果全局缓存的项目、标段、工区id能构成合理的上下级关系
      // 如果同时满足上述两条，则自动选中，否则，默认选中第一条
      let validate = false
      if (!this.prjId || this.prjId == globalPrjId) {
        if (this.projectMap.has(globalPrjId)) {
          let contract = this.contractMap.get(globalContractId)
          if (contract && contract.prjId == globalPrjId) {
            if (this.includeSub && contract.hasSubcontractor == 1) {
              let subcontractor = this.subcontractorMap.get(globalSubcontractorId)
              if (subcontractor && subcontractor.contractId == globalContractId) {
                validate = true
              }
            } else {
              validate = true
            }
          }
        }
      }
      if (validate) {
        this.cascaderValue = []
        if (!this.prjId) {
          this.cascaderValue.push(globalPrjId)
        }
        this.cascaderValue.push(globalContractId)
        if (globalSubcontractorId) {
          this.cascaderValue.push(globalSubcontractorId)
        }
      } else {
        // 这里是默认选中第一条的逻辑
        let hasResult = false
        for (let project of this.options) {
          let contractList = project.children
          if (contractList && contractList.length > 0) {
            for (let contract of contractList) {
              // 如果不需要选择工区，或者此标段无工区，则直接得到结果
              if (!this.includeSub || !contract.hasSubcontractor) {
                if (this.prjId) {
                  this.cascaderValue = [contract.id]
                } else {
                  this.cascaderValue = [project.id, contract.id]
                }
                hasResult = true
                break
              } else {
                // 需要选工区，且此标段有工区的情况下，直接选中第一条
                if (contract.children && contract.children.length > 0) {
                  if (this.prjId) {
                    this.cascaderValue = [contract.id, contract.children[0].id]
                  } else {
                    this.cascaderValue = [project.id, contract.id, contract.children[0].id]
                  }
                  hasResult = true
                  break
                }
              }
            }
          }
          if (hasResult) {
            break
          }
        }
      }
    },
    // 反向更新项目数据
    updateProjectData(id, key, value) {
      let item = this.projectMap.get(id)
      if (item) item[key] = value
    },
    // 反向更新标段数据
    updateContractData(id, key, value) {
      let item = this.contractMap.get(id)
      if (item) item[key] = value
    },
    // 反向更新数据
    updateSubcontractorData(id, key, value) {
      let item = this.subcontractorMap.get(id)
      if (item) item[key] = value
    },
  },
  watch: {
    cascaderValue: function(data) {
      let result = {}
      let index = 0
      if (this.prjId) {
        result.prjId = this.prjId
      } else {
        result.prjId = data[index++]
      }
      result.project = this.projectMap.get(result.prjId)
      this.projectName = result.project.name
      result.contractId = data[index++]
      result.contract = this.contractMap.get(result.contractId)
      this.contractName = result.contract.name
      if (data.length > index) {
        result.subcontractorId = data[index]
        result.subcontractor = this.subcontractorMap.get(result.subcontractorId)
        if (result.subcontractor) {
          this.subcontractorName = result.subcontractor.name
        }
      }
      // 更新全局缓存
      localStorage.setItem("prjId", result.prjId)
      localStorage.setItem("contractId", result.contractId)
      localStorage.setItem("subcontractorId", result.subcontractorId || '')
      /*
      触发change事件
      事件参数:
      {
        prjId: 项目id
        contractId: 标段id
        subcontractorId: 工区id
        project: 项目对象
        contract: 标段对象
        subcontractor: 工区对象
      }
      其中：
      1.如果不需要选工区，则没有工区id和工区对象属性
      2.几个对象中，包含了部分重要属性，例如名称，标段对象中包含了计量方式、是否有工区等
       */
      this.$emit('change', result)
    }
  }
}
</script>