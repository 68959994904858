<template>
  <el-tooltip placement="bottom-start" :disabled="tooltipDisabled" :content="tooltip" effect="light" :enterable="enterable" :open-delay="1000">
    <el-select style="width: 100%" v-model="selectedId" :value="value" :placeholder="(!placeholder && disabled) ? '' : placeholder"
      @change="handleChange" @clear="handleChange" @input="$emit('input', $event)"
      :disabled="disabled" filterable :clearable="clearable">
      <el-option v-for="opt in dataList" :label="opt.name + ' ' + opt.abbr" :key="opt.id" :value="opt.id"></el-option>
    </el-select>
  </el-tooltip>
</template>
<script>
export default {
  name: 'MajorSelect',
  data () {
    return {
      dataList: [],
      tooltip: '请选择',
      enterable: false,
      tooltipDisabled: false,
      dataSourceUrl: '/mps/major/briefs',
      selectedId: ''
    }
  },
  props: {
    value: String,
    placeholder: String,
    disabled: Boolean,
    clearable: Boolean,
    // 是否默认选中第一个
    defaultSelect: false
  },
  mounted() {
    this.$nextTick(() => {
      this.loadData()
    })
  },
  watch: {
    // value改变时，修改tooltip的值
    value: function (data) {
      let item = this.getItemById(data)
      this.tooltip = item ? item.name : '请选择'
      this.selectedId = data
    }
  },
  methods: {
    loadData() {
      this.$http.get(this.dataSourceUrl).then(({ data: res }) => {
        if (res.code === 0) {
          this.dataList = res.data
          if (this.dataList.length > 0) {
            if (this.defaultSelect) {
              this.selectedId = this.dataList[0].id
              this.handleChange(this.selectedId)
            }
          }
        }
      }).catch(() => {
        // do nothing
      })
    },
    getItemById (id) {
      let item = this.dataList.find((item) => {
        return item.id === id
      })
      if (item) {
        this.tooltipDisabled = false
        return item
      } else {
        if (this.disabled) {
          this.tooltipDisabled = true
        }
        return null
      }
    },
    handleChange (event) {
      this.$emit('change', this.getItemById(event))
    }
  }
}
</script>
