/**
 * 邮箱
 * @param {*} s
 */
export function isEmail (s) {
  return /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile (s) {
  return /^1[0-9]{10}$/.test(s)
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone (s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL (s) {
  return /^http[s]?:\/\/.*/.test(s)
}

/**
 * 正整数
 * @param {*} s
 */
export function isPositiveInteger (s) {
  return /^\+?[1-9][0-9]*$/.test(s)
}

/**
 * 数字
 * @param {*} s
 */
export function isNumber (s) {
  //非负浮点数
  const regPos = /^\d+(\.\d+)?$/;
  //负浮点数
  const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/;
  return ((s == null) || (s == '') || regPos.test(s) || regNeg.test(s))
}

/**
 * 字符串是否为空
 * @param {*} s
 */
export function isStringEmpty (s) {
  return s == null || s == undefined || s == ''
}
