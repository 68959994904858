<template>
  <div class="layout-main" :style="'height:' + height + 'px;width: 100%'">
    <div class="layout-body">
      <div class="sign-configure">
        <div class="sign-mode">
          <div class="mode-location">
            <span>拖拽“签名图片”至右侧的文档中，指定对应的签署位置</span>
          </div>
        </div>
        <div class="sign-signer">
          <div class="signer-list">
            <ul>
              <draggable v-model="thisControlList" handle=".control-move" filter=".unmover" item-key="name"
                         :force-fallback="true" animation="300" @end="controlsDragOver"
                         :group="groupOut" :fallback-class="true" :fallback-on-body="true" :touch-start-threshold="50"
                         :fallback-tolerance="50" :clone="clone" :sort="false" drag-class="drag-class">
                <div v-for="(element, index) in thisControlList" :key="index">
                  <li :class="['li-entp-seal',controlClass(element)]">
                    <div class="person-seal item" @click="openModal(element)">
                      <img :src="'data:image/png;base64,'+element.value" v-if="element.value"/>
                      <span v-else>{{ loadingSignature ? '签名加载中' : '请先设置签名' }}</span>
                    </div>
                  </li>
                </div>
              </draggable>
            </ul>
          </div>
        </div>
      </div>
      <div class="sign-content">
        <c-scrollbar>
          <div class="document-content">
            <!-- 此处高度最后多加的 '(documentPDF.images.length + 2) * CanvasZoom.space' 部分,是为了让最后一张pdf图片下部还多余一定的空间，避免用户拖拽签名时超出容器，导致事件的y属性不正常，签名无法定位的问题 -->
            <div class="document-list" :style="`width:${CanvasZoom.width}px;` + 'height: '+ (CanvasZoom.height * documentPDF.images.length + (documentPDF.images.length + 2) * CanvasZoom.space) +'px;'"
                 v-if="documentPDF && documentPDF.images && documentPDF.images.length > 0">
              <template v-for="(item, idx) in documentPDF.images">
                <div class="document-page group" v-if="item" :key="idx"
                     :style="'transform: translate(0,' + (item.docPage * CanvasZoom.height + (item.docPage+1) * CanvasZoom.space) + 'px);'">
<!--/*                  <img style="width: 100%;" :src="item.image"/>*/-->
                  <img :style="calImgStyle(item)" :src="item.image"/>
                </div>
              </template>
              <draggable v-model="documentPDF.control" ghost-class="ghost" draggable=".test"
                         item-key="uid"
                         :group="groupEnter" :force-fallback="true" chosen-class="chosenClass" animation="300"
                         :fallback-class="true" :fallback-on-body="true" :touch-start-threshold="50"
                         :fallback-tolerance="50"
                         style="width: 100%;height: 100%;position: relative;">
                <ControlItem v-for="element in documentPDF.control" :key="element.key" :element="element" :isSign="false"
                             @controlDelete="controlDelete"/>
              </draggable>
            </div>
          </div>
        </c-scrollbar>
      </div>
      <div class="sign-description">
      </div>
    </div>
    <SignaturePadModal :modalVisible="signatureModalShow" @success="signatureModalSubmit"/>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {moveRange, currentPosition} from "@/components/signature/components/ControlerMoveRange"
import CScrollbar from "./components/scrollbar/c-scrollbar"
import ControlItem from "@/components/signature/components/control-item"
import SignaturePadModal from "@/components/signature/SignaturePadModal"
import {CanvasZoom} from "@/components/signature/components/ControlerMoveRange"

export default {
  name: "PdfSign",
  data() {
    return {
      thisControlList: [
        {
          icon: "signature",
          name: "手写签名",
          title: "个人签名",
          placeholder: "",
          value: "",
          zoom: false,
          move: true,
          size: {
            width: 70,
            height: 35,
            minWidth: 70,
            minHeight: 35,
          },
          position: {
            left: 0,
            top: 0,
            page: -1,
          },
          user: {
            index: -1,
            userName: "",
          }
        }
      ],
      //签署相关的属性
      signData: {
        entSeal: "",
        personalSeal: "",
        entPositionList: [],
        personalPositionList: [],
        ossId: this.ossId
      },
      //文档展示的数据  包含控件列表
      documentPDF: {
        images: [],
        control: []
      },
      //控件移出移入的配置
      groupOut: {
        name: "itxst",
        put: false,
        pull: 'clone', //允许拖出
      },
      groupEnter: {
        name: "itxst",
        put: true, //允许拖入
        pull: false, //允许拖出
      },
      //设置签名的弹窗开关
      signatureModalShow: false,
      height: 500,
      // 加载签名中
      loadingSignature: false,
    }
  },
  props: {
    ossId: {
      type: [String, Number],
      required: true
    },
  },
  components: {
    draggable,
    CScrollbar,
    ControlItem,
    SignaturePadModal
  },
  computed: {
    CanvasZoom() {
      return CanvasZoom
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.$nextTick(() => {
      //  高度设置
      this.height = window.innerHeight - 150
    })
  },
  destroyed() {
    CanvasZoom.width = CanvasZoom.originWidth
  },
  methods: {
    init() {
      this.getImages()
      this.getSignature()
    },
    // 获取pdf文件的图片版本
    getImages() {
      const loading = this.$loading({
        lock: true,
        text: '加载文件中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$http.get('/mps/pdfSign/images/' + this.ossId).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data.length > 0) {
          for (let pageIndex = 0; pageIndex < res.data.length; pageIndex++) {
            let dto = res.data[pageIndex]
            this.documentPDF.images.push({
              docPage: pageIndex,
              image: 'data:image/png;base64,' + dto.image,
              width: dto.width,
              height: dto.height
            })
            // 根据每一页pdf的宽高比调整页面容器（如果有宽高比较大的，则页面容器适度加宽，避免pdf图片过渡压缩导致看不清）
            if (dto.width / dto.height > CanvasZoom.width / CanvasZoom.height) {
              CanvasZoom.width = dto.width / dto.height * CanvasZoom.height
              if (CanvasZoom.width > CanvasZoom.maxWidth) {
                CanvasZoom.width = CanvasZoom.maxWidth
              }
            }
          }
        }
      }).catch(() => {
        this.$message.error('文件加载失败')
      }).finally(() => {
        loading.close()
      })
    },
    // 获取当前用户签名
    getSignature() {
      this.loadingSignature = true
      this.$http.get('/sys/user/openapi/mySignature').then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data) {
          // 如果存在签名，则获取signedUrl
          this.$http.get(`/oss/file/signedUrl?url=${res.data}`).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            // 将图片url转为base64字符串
            fetch(res.data).then(r => r.arrayBuffer()).then(arrBuffer => {
              const base64 = btoa(new Uint8Array(arrBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''))
              this.thisControlList[0].value = base64
              this.signData.personalSeal = base64
              this.loadingSignature = false
            })
          }).catch(() => {
            this.$message.error('签名加载失败')
          })
        } else {
          this.loadingSignature = false
        }
      }).catch(() => {
        this.$message.error('签名加载失败')
      })
    },
    // 计算pdf图片的img元素的样式
    calImgStyle(item) {
      let ratio = item.width / item.height
      let width = 0
      if (ratio > CanvasZoom.width / CanvasZoom.height) {
        width = CanvasZoom.width
      } else {
        width = CanvasZoom.height * ratio
      }
      return {
        width: width + 'px'
      }
    },
    //深度克隆控件对象
    clone(origin) {
      const data = JSON.parse(JSON.stringify(origin))
      data.position.x = 100
      data.position.y = 100
      data.uid = parseInt(new Date().getMilliseconds() + "" + Math.ceil(Math.random() * 100000)).toString(16)
      data.pageSize = this.documentPDF.images.length
      data.key = new Date().getMilliseconds()
      return data;
    },
    //控件完成拖动触发事件
    controlsDragOver(e) {
      const moveTarget = this.documentPDF.control[e.newIndex]
      if (e.pullMode) {
        const opt = {
          x: e.originalEvent.offsetX - (moveTarget.size.width / 2),
          y: e.originalEvent.offsetY - (moveTarget.size.height / 2),
          pageSize: this.documentPDF.images.length,
          size: moveTarget.size,
          currentPage: 0,
        }
        moveRange(opt)
        moveTarget.position.left = opt.x
        moveTarget.position.top = opt.y
        moveTarget.position.page = opt.currentPage
        moveTarget.controlClick = true
      }
    },
    // 删除已拖动至文档中的控件
    controlDelete(element) {
      this.documentPDF.control = this.documentPDF.control.filter((item) => {
        return item.uid !== element.uid
      })
    },
    // 接收手写签名图片
    signatureModalSubmit(data) {
      let temControlList = JSON.parse(JSON.stringify(this.thisControlList))
      temControlList[0].value = data.image
      this.signData.personalSeal = data.image
      this.thisControlList = temControlList
      // 将手写签名存储至用户信息
      this.uploadSignature(data.image)
      //替换控件中已经使用的手写签名
      this.documentPDF.control.forEach(item => {
        item.value = data.image
      })
    },
    // 将手写签名存储至用户信息
    uploadSignature(base64String) {
      let formData = new FormData()
      formData.append('file', this.base64ToFile(base64String, 'signature.png'))
      // 上传签名图片
      this.$http.post('/oss/file/uploadPhoto', formData).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$http.put(`/sys/user/openapi/mySignature?url=${res.data}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
        }).catch(() => {
          this.$message.error("上传签名图片失败，请重试！")
        })
      }).catch(() => {
        this.$message.error("上传签名图片失败，请重试！")
      })
    },
    base64ToFile(base64Data, filename) {
      // 将base64的数据部分提取出来
      const contentType = 'image/png'
      const raw = window.atob(base64Data)

      // 将原始数据转换为Uint8Array
      const rawLength = raw.length
      const uInt8Array = new Uint8Array(rawLength)
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }

      // 使用Uint8Array创建Blob，然后使用Blob创建File
      const blob = new Blob([uInt8Array], {type: contentType})
      const file = new File([blob], filename, {type: contentType})

      return file
    },
    // 判断控件的class  是否可进行拖动
    controlClass(element) {
      if (this.thisControlList[0].value) {
        return "control-move"
      } else {
        return "control-disabled"
      }
    },
    // 调用文件签署接口进行签署  并返回签署后的PDF文件
    signDocument() {
      if (this.documentPDF.control.length == 0) {
        return this.$message.error("您尚未为签名指定签署位置，请设置后再签署")
      }
      const loading = this.$loading({
        lock: true,
        text: '签署中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.signData.entPositionList = []
      this.signData.personalPositionList = []
      //将签名控件的位置转换为签署所需的坐标
      this.documentPDF.control.forEach(item => {
        let position = this.calRelativePosition(item)
        // 如果中心点处于pdf文件范围外，则此签名作废
        if (position.x < 0 || position.x > 10 || position.y < 0 || position.y > 1) {
          // do nothing
        } else {
          const temItem = {
            width: item.size.width,
            height: item.size.height,
            offsetX: item.position.left,
            offsetY: currentPosition(item.position.top, item.position.page),
            positionX: position.x,
            positionY: position.y,
            page: item.position.page + 1,
            //pageHeight: this.CanvasZoom.height,
            //pageWidth: this.CanvasZoom.width,
            pageWidth: this.documentPDF.images[item.position.page].width,
            pageHeight: this.documentPDF.images[item.position.page].height
          };
          this.signData.personalPositionList.push(temItem)
        }
      })
      this.$http.post('/mps/pdfSign', this.signData).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success('签署成功')
        this.$emit('signSuccess')
      }).catch(() => {
        this.$message.error('签署文件时出错')
      }).finally(() => {
        loading.close()
      })
    },
    // 计算签名图片的中心点的相对位置
    calRelativePosition(item) {
      let containerWidth
      let containerHeight
      if (this.documentPDF.images[item.position.page].width / this.documentPDF.images[item.position.page].height < CanvasZoom.width / CanvasZoom.height) {
        containerWidth = this.documentPDF.images[item.position.page].width / this.documentPDF.images[item.position.page].height * CanvasZoom.height
        containerHeight = CanvasZoom.height
      } else {
        containerWidth = CanvasZoom.width
        containerHeight = this.documentPDF.images[item.position.page].height / this.documentPDF.images[item.position.page].width * CanvasZoom.width
      }
      return {
        x: (item.position.left + item.size.width / 2) / containerWidth,
        y: (currentPosition(item.position.top, item.position.page) + item.size.height / 2) / containerHeight
      }
    },
    // 如果用户签名加载完毕，确认当前用户没有设置签名图片 打开相关的弹窗
    openModal(element) {
      if (!element.value && !this.loadingSignature) {
        // 如果之前弹窗被打开过并关闭了，本组件中的signatureModalShow不会被重置为false，需要此处手动重置
        if (this.signatureModalShow) {
          this.signatureModalShow = false
        }
        this.$nextTick(() => {
          this.signatureModalShow = true
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.layout-main {
  //max-width: 30rem;
  min-width: 16rem;
  display: flex;
  flex-direction: column;
  font-size: 0.14rem;
  overflow: hidden;
}

.layout-header {
  /* height: 62px; */
  width: 100%;
}

.header-menu {
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
  margin: 0;
  display: flex;
  padding: 0.04rem 0;
}

.header-menu li {
  list-style: none;
  height: 0.7rem;
}

.header-menu .header-logo {
  width: 4rem;
  justify-content: center;
  align-items: center;
  display: flex;
  box-sizing: border-box;

  img {
    height: 0.62rem;
  }
}

.header-menu .header-btn {
  flex: 1;
  min-width: 8rem;
  justify-content: center;
  align-items: center;
  display: flex;

}

.header-menu .header-external {
  width: 4rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.custom-ant-button {
  width: 1.40rem;
  font-size: 0.16rem !important;
  height: 0.4rem;
  padding: 0;
}


.layout-body {
  height: 100%;
  width: 100%;
  display: flex;

  .sign-configure {
    width: 195px;
    height: 100%;
    padding: 0.2rem 0.4rem 0 0.4rem;
    box-sizing: border-box;

    h3 {
      text-align: center;
    }

    .mode-location {
      padding: 0.2rem 0;
      font-size: 15px;
      //line-height: 0.25rem;
      //text-indent: 0.28rem;
    }

    .signer-list ul, .mode-keyword ul {
      padding: 0;
    }

    .signer-list li, .mode-keyword li {
      list-style: none;
      padding: 0.02rem 0;
    }

    .signer-list .li-style::before, .mode-keyword .li-style::before {
      content: "";
      width: 0.06rem;
      height: 0.06rem;
      display: inline-block;
      border-radius: 50%;
      background: #999;
      vertical-align: middle;
      margin-right: 0.1rem;
    }

    .signer-list .li-entp-seal {
      padding: 0.1rem 0.5rem;

      .entp-seal {
        width: 180px;
        height: 180px;
        border: 1px dashed #bbb;
      }
    }

    .signer-list .li-entp-seal {
      padding: 1rem 2rem;
    }
  }

  .sign-description ul li {
    list-style: none;
  }

  .sign-description .description-scrollbar {
    padding: 0 0.2rem 0.3rem 0.2rem;
    box-sizing: border-box;

  }

  .sign-description {
    width: 4rem;
    height: 100%;
    padding: 0.2rem 0;

    .description-title {
      line-height: 0.3rem;
    }

    .description-text {
      padding-left: 0.16rem;
      line-height: 0.20rem;
    }

    .description-title::before {
      content: "";
      width: 0.06rem;
      height: 0.06rem;
      display: inline-block;
      border-radius: 50%;
      background: #666;
      vertical-align: middle;
      margin-right: 0.1rem;
    }
  }

  .sign-configure .configure-title {
    font-weight: 600;
    line-height: 0.3rem;
  }

  .sign-content {
    flex: 1;
    height: 100%;
    min-width: 800px;
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    background-color: #f1f1f1;
    padding: 4px 0 20px 0;
    box-sizing: border-box;

    .document-content {
      flex: 1;
      height: 100%;
    }

    .document-list {
      position: relative;
      margin: 0 auto;
      //width: 800px;
    }

    .document-page {
      position: absolute;
    }
  }
}

.entp-seal {
  width: 170px;
  height: 170px;
  border: 1px dashed #bbb;
  background-color: #eee;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 5px;
  user-select: none;

  img {
    width: 160px;
  }
}

.person-seal {
  width: 75px;
  height: 40px;
  border: 1px dashed #bbb;
  background-color: #eee;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 5px;
  user-select: none;

  span {
    font-size: 15px
  }

  img {
    height: 35px;
  }
}


.drag-class .unmover, .ghost .unmover {
  display: none !important;
}

.drag-class li, .ghost li {
  list-style: none !important;
}
</style>