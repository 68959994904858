<template>
  <div>
    <el-form label-position="top" label-width="90px">
      <el-form-item label="⚙ 选择审批对象" prop="text" class="user-type">
        <el-radio-group v-model="nodeProps.assignedType">
          <el-radio v-for="t in approvalTypes" :label="t.type" :key="t.type">{{ t.name }}</el-radio>
        </el-radio-group>
        <div v-if="nodeProps.assignedType === 'ASSIGN_USER'">
          <el-button size="mini" icon="el-icon-plus" type="primary" @click="selectUser" round>选择人员</el-button>
          <org-items v-model="nodeProps.assignedUser"/>
        </div>
        <div v-else-if="nodeProps.assignedType === 'SELF_SELECT'">
          <el-radio-group size="mini" v-model="nodeProps.selfSelect.multiple">
            <el-radio-button :label="false">自选一个人</el-radio-button>
            <el-radio-button :label="true">自选多个人</el-radio-button>
          </el-radio-group>
        </div>
<!--        <div v-else-if="nodeProps.assignedType === 'LEADER_TOP'">-->
<!--          <el-divider/>-->
<!--          <el-form-item label="审批终点" prop="text" class="approve-end">-->
<!--            <el-radio-group v-model="nodeProps.leaderTop.endCondition">-->
<!--              <el-radio label="TOP">直到最上层主管</el-radio>-->
<!--              <el-radio label="LEAVE">不超过发起人的</el-radio>-->
<!--            </el-radio-group>-->
<!--            <div class="approve-end-leave" v-if="nodeProps.leaderTop.endCondition === 'LEAVE'">-->
<!--              <span>第 </span>-->
<!--              <el-input-number :min="1" :max="20" :step="1" size="mini" v-model="nodeProps.leaderTop.level"/>-->
<!--              <span> 级主管</span>-->
<!--            </div>-->
<!--          </el-form-item>-->
<!--        </div>-->
<!--        <div v-else-if="nodeProps.assignedType === 'LEADER'">-->
<!--          <el-divider/>-->
<!--          <el-form-item label="指定主管" prop="text">-->
<!--            <span>发起人的第 </span>-->
<!--            <el-input-number :min="1" :max="20" :step="1" size="mini"-->
<!--                             v-model="nodeProps.leader.level"></el-input-number>-->
<!--            <span> 级主管</span>-->
<!--            <div style="color: #409EFF; font-size: small;">👉 直接主管为 第 1 级主管</div>-->
<!--          </el-form-item>-->
<!--        </div>-->
        <div v-else-if="nodeProps.assignedType === 'ROLE'">
          <el-button size="mini" icon="el-icon-plus" type="primary" @click="selectRole" round>选择角色</el-button>
          <org-items v-model="nodeProps.role"/>
          <role-select-transfer ref="roleSelectTransfer" fullData @confirm="roleSelectTransferConfirm"></role-select-transfer>
        </div>
      </el-form-item>

      <el-divider></el-divider>
      <el-form-item label="👤 审批人为空时" prop="text" class="line-mode">
        <el-radio-group v-model="nodeProps.nobody.handler">
          <el-radio label="TO_PASS">自动通过</el-radio>
<!--          <el-radio label="TO_REFUSE">自动驳回</el-radio>-->
<!--          <el-radio label="TO_ADMIN">转交审批管理员</el-radio>-->
          <el-radio label="TO_USER">转交到指定人员</el-radio>
        </el-radio-group>
        <!--选择转交到指定人员选项时,显示选择人员-->
        <div style="margin-top: 10px" v-if="nodeProps.nobody.handler === 'TO_USER'">
          <el-button size="mini" icon="el-icon-plus" type="primary" @click="selectNoSetUser" round>选择人员</el-button>
          <org-items v-model="nodeProps.nobody.assignedUser"/>
        </div>

      </el-form-item>

      <div v-if="showMode">
        <el-divider/>
        <el-form-item label="👩‍👦‍👦 多人审批时审批方式" prop="text" class="approve-mode">
          <el-radio-group v-model="nodeProps.mode">
            <el-radio label="NEXT">会签 （按选择顺序审批，每个人必须同意）</el-radio>
            <el-radio label="AND">会签（可同时审批，每个人必须同意）</el-radio>
            <el-radio label="OR">或签（有一人同意即可）</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

<!--      <el-divider>高级设置</el-divider>-->
      <!-- todo 审批期限暂时注释，后续再实现 -->
<!--      <el-form-item label="⏱ 审批期限（为 0 则不生效）" prop="timeLimit">-->
<!--        <el-input style="width: 180px;" placeholder="时长" size="small" type="number"-->
<!--                  v-model="nodeProps.timeLimit.timeout.value">-->
<!--          <el-select style="width: 75px;" v-model="nodeProps.timeLimit.timeout.unit" slot="append" placeholder="请选择">-->
<!--            <el-option label="天" value="D"></el-option>-->
<!--            <el-option label="小时" value="H"></el-option>-->
<!--          </el-select>-->
<!--        </el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="审批期限超时后执行" prop="level" v-if="nodeProps.timeLimit.timeout.value > 0">-->
<!--        <el-radio-group v-model="nodeProps.timeLimit.handler.type">-->
<!--          <el-radio label="PASS">自动通过</el-radio>-->
<!--          <el-radio label="REFUSE">自动驳回</el-radio>-->
<!--          <el-radio label="NOTIFY">发送提醒</el-radio>-->
<!--        </el-radio-group>-->
<!--        <div v-if="nodeProps.timeLimit.handler.type === 'NOTIFY'">-->
<!--          <div style="color:#409EEF; font-size: small">默认提醒当前审批人</div>-->
<!--          <el-switch inactive-text="循环" active-text="一次" v-model="nodeProps.timeLimit.handler.notify.once"></el-switch>-->
<!--          <span style="margin-left: 20px" v-if="!nodeProps.timeLimit.handler.notify.once">-->
<!--							每隔-->
<!--							<el-input-number :min="0" :max="10000" :step="1" size="mini"-->
<!--                               v-model="nodeProps.timeLimit.handler.notify.hour"/>-->
<!--							小时提醒一次-->
<!--						</span>-->
<!--        </div>-->
<!--      </el-form-item>-->
    </el-form>
<!--    <org-picker :title="pickerTitle" multiple :type="orgPickerType" ref="orgPicker" :selected="orgPickerSelected"-->
<!--                @ok="selected"/>-->
    <user-multi-select v-if="userMultiSelectVisible" ref="userMultiSelect" @close="closeDialogHandle" @submitAllData="userMultiSelectSubmit"></user-multi-select>
  </div>
</template>

<script>
import OrgItems from "../OrgItems"
import RoleSelectTransfer from "@/components/role-select-transfer"
export default {
  name: "ApprovalNodeConfig",
  components: {OrgItems, RoleSelectTransfer},
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      userMultiSelectVisible: false,
      showOrgSelect: false,
      orgPickerSelected: [],
      orgPickerType: 'user',
      approvalTypes: [
        {name: '指定人员', type: 'ASSIGN_USER'},
        {name: '发起人自选', type: 'SELF_SELECT'},
        // {name: '连续多级主管', type: 'LEADER_TOP'},
        // {name: '主管', type: 'LEADER'},
        {name: '角色', type: 'ROLE'},
      ]
    }
  },
  computed: {
    nodeProps() {
      return this.$store.state.selectedNode.props
    },
    selectingAssignedUser() {
      return this.config.assignedUser || []
    },
    selectingRole() {
      return this.config.role || []
    },
    showMode() {
      switch (this.nodeProps.assignedType) {
        case "ASSIGN_USER":
          return this.nodeProps.assignedUser.length > 0;
        case "SELF_SELECT":
          return this.nodeProps.selfSelect.multiple;
        // case "LEADER_TOP":
        //   return this.nodeProps.formUser !== '';
        // case "FORM_USER":
        //   return true;
        case "ROLE":
          return true;
        default:
          return false;
      }
    }
  },
  methods: {
    selectUser() {
      this.orgPickerSelected = this.selectingAssignedUser
      this.orgPickerType = 'user'
      this.userMultiSelectVisible = true
      this.$nextTick(() => {
        this.$refs.userMultiSelect.selectedList = this.orgPickerSelected.map(obj => obj.id)
        this.$refs.userMultiSelect.init()
      })
    },
    selectNoSetUser() {
      this.orgPickerSelected = this.config.nobody.assignedUser
      this.orgPickerType = 'user'
      this.userMultiSelectVisible = true
      this.$nextTick(() => {
        this.$refs.userMultiSelect.selectedList = this.orgPickerSelected.map(obj => obj.id)
        this.$refs.userMultiSelect.init()
      })
    },
    selectRole() {
      this.orgPickerSelected = this.selectingRole
      this.orgPickerType = 'role'
      this.$refs.roleSelectTransfer.value = this.orgPickerSelected.map(obj => obj.id)
      this.$refs.roleSelectTransfer.show()
    },
    selected(select) {
      this.orgPickerSelected.length = 0
      select.forEach(val => this.orgPickerSelected.push(val))
    },
    userMultiSelectSubmit(selectedList) {
      selectedList.forEach((val) => {
        let index = this.orgPickerSelected.findIndex((obj) => {
          return obj.id == val.id
        })
        if (index == -1) {
          this.orgPickerSelected.push({
            id: val.id,
            realName: val.realName,
            type: this.orgPickerType,
            headUrl: val.headUrl
          })
        }
      })
    },
    closeDialogHandle() {
      this.userMultiSelectVisible = false
    },
    roleSelectTransferConfirm(selectedList) {
      this.orgPickerSelected.length = 0
      selectedList.forEach((val) => {
        this.orgPickerSelected.push({
          id: val.id,
          name: val.name,
          type: this.orgPickerType
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user-type {
  /deep/ .el-radio {
    width: 110px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

/deep/ .line-mode {
  .el-radio {
    width: 150px;
    margin: 5px;
  }
}

/deep/ .el-form-item__label {
  line-height: 25px;
}

/deep/ .approve-mode {
  .el-radio {
    float: left;
    width: 100%;
    display: block;
    margin-top: 15px;
  }
}

/deep/ .approve-end {
  position: relative;

  .el-radio-group {
    width: 160px;
  }

  .el-radio {
    margin-bottom: 5px;
    width: 100%;
  }

  .approve-end-leave {
    position: absolute;
    bottom: -5px;
    left: 150px;
  }
}

/deep/ .el-divider--horizontal {
  margin: 10px 0;
}
</style>
