import { render, staticRenderFns } from "./ApprovalNode.vue?vue&type=template&id=9336be64&scoped=true"
import script from "./ApprovalNode.vue?vue&type=script&lang=js"
export * from "./ApprovalNode.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9336be64",
  null
  
)

export default component.exports