<template>
  <el-dialog top="5vh" :visible.sync="visible" title="导入" :close-on-click-modal="false" :close-on-press-escape="false">
    <el-row v-if="thumbnail">
      <el-col :span="24">
        <img :src="imgSrc" style="width: 100%;">
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-upload
          name="file"
          :action="actionUrl"
          :file-list="fileList"
          drag
          :before-upload="beforeUploadHandle"
          :on-success="successHandle"
          class="text-center my-excel-import-dialog">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text" v-html="$t('upload.text')"></div>
        </el-upload>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  name: 'ExcelImport',
  data () {
    return {
      actionUrl: '',
      visible: false,
      fileList: [],
      importLoading: {},
      imgSrc: '',
    }
  },
  props: {
    url: String,
    // 导入格式的缩略图Url
    thumbnail: '',
  },
  methods: {
    init (getFormParamCallback) {
      this.visible = true
      this.fileList = []
      if (this.url) {
        if (this.url.startsWith("/")) {
          this.actionUrl = `${window.SITE_CONFIG['apiURL']}${this.url}`
        } else {
          this.actionUrl = `${window.SITE_CONFIG['apiURL']}/${this.url}`
        }

        this.actionUrl = `${this.actionUrl}?access_token=${Cookies.get('access_token')}&tenant_code_key=${Cookies.get('tenantCode')}`
        if (typeof getFormParamCallback === 'function') {
          let formParams = getFormParamCallback()
          this.actionUrl = `${this.actionUrl}&${formParams}`
        }
      }
      if (this.thumbnail) {
        this.imgSrc = require(`@/assets/img/import/${this.thumbnail}`)
      }
    },
    beforeUploadHandle (file) {
      this.importLoading = this.$loading({
        text: '正在导入，请稍等...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    successHandle (res, file, fileList) {
      this.importLoading.close()
      this.visible = false
      if (res.code !== 0) {
        return this.$alert(res.msg, '错误信息', {
          confirmButtonText: '关闭',
          callback: () => {
            this.$emit('refreshDataList')
          }
        })
      }
      
      return this.$alert('已导入结束！', '操作结果', {
        confirmButtonText: '关闭',
        callback: () => {
          this.$emit('refreshDataList')
        }
      })
    }
  }
}
</script>
<style scoped>
.my-excel-import-dialog {
  margin-top: 60px;
  margin-bottom: 90px;
}
</style>