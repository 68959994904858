<template>
  <el-dialog top="5vh" v-dialog-drag width="1250px" :visible.sync="visible" title="[物资明细选择]" :close-on-click-modal="false"
             :close-on-press-escape="false" v-on="$listeners">
    <el-row :gutter="30">
      <el-col :span="6">
        <el-tree ref="typeTree"
                 lazy
                 class="left-tree filter-tree"
                 node-key="id"
                 v-loading="typeTreeLoading"
                 :data="typeTreeData"
                 :props="defaultProps"
                 :load="loadTypeTreeNode"
                 :expand-on-click-node="false"
                 @node-click="typeTreeNodeClick"
                 :highlight-current="true">
        </el-tree>
      </el-col>
      <el-col :span="18">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
          <el-form-item>
            <el-input v-model="dataForm.code" placeholder="编号" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="dataForm.name" placeholder="名称" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="table"
          :data="dataList"
          border
          :max-height="tableHeight"
          @selection-change="dataListSelectionChangeHandle"
          highlight-current-row
          v-loading="dataListLoading">
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column prop="code" label="编号" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="name" label="名称" min-width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="unit1" label="单位" width="100" align="center" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="unit2" label="结算单位" width="80" align="center" show-overflow-tooltip></el-table-column> -->
        </el-table>
        <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
        </el-pagination>
      </el-col>
    </el-row>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
// 物资明细多选
import debounce from 'lodash/debounce'
import mixinViewModule from '@/mixins/view-module'

export default {
  mixins: [mixinViewModule],
  name: 'MaterialMoreSelect',
  data () {
    return {
      visible: false,
      mixinViewModuleOptions: {
        createdIsNeed: false,
        getDataListURL: '/mps/material/dialog/page',
        getDataListIsPage: true,
      },
      dataForm: {
        typeId: '',
        name: '',
        code: ''
      },
      tableHeight: 500,

      findChildrenUrl: '/mps/materialType/findChildren',
      typeTreeLoading: false,
      typeTreeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        isLeaf: 'isLeaf'
      },
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        this.getTypeTreeData()
        this.getDataList()
      })
    },
    getTypeTreeData () {
      this.typeTreeLoading = true
      // 清空物资分类数据
      this.typeTreeData = []
      this.$http.get(this.findChildrenUrl,
        {
          params: {
            'pid': 0
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.typeTreeData = res.data || []
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.typeTreeLoading = false
      })
    },
    // 异步加载物资分类子节点数据
    loadTypeTreeNode (node,resolve) {
      if (node.level >= 1) {
        if (node && node.data) {
          let nodeData = node.data;
          this.$http.get(this.findChildrenUrl,
            {
              params: {
                pid: nodeData.id
              }
            }
          ).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            resolve(res.data);
          }).catch(() => {
            return this.$message.error('出错了')
          })
        } else {
          return resolve([])
        }
      }
    },

    //  物资分类树节点点击事件
    typeTreeNodeClick (data,node,ele) {
      // 点击高亮节点则取消高亮
      if (this.dataForm.typeId == data.id) {
        this.dataForm.typeId = ''
        this.$refs.typeTree.setCurrentKey(null)
      } else {
        this.dataForm.typeId = data.id
      }
      // 查询数据
      this.getDataList()
    },

    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      let result = this.$refs.table.selection
      this.visible = false
      this.$emit('submit',result)
    },1000,{'leading': true,'trailing': false})
  }
}
</script>
<style lang="scss" scope>
  .filter-tree {
    height: 500px;
    overflow: auto;
  }
</style>