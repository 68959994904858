<template>
  <vxe-modal ref="vxeModal" width="1030" height="580" :position="position" :lockView="false" :mask="false" v-model="visible" :resize="false" v-on="$listeners">
    <template #title>
      <span>
        <div>{{ dialogTitle }}</div>
      </span>
    </template>
    <template #default>
      <div style="float: left; width: 1000px; text-align: center;">
        <div style="position: absolute; top: 0px; left: 10px; font-weight: bold;">[变更前模型]</div>
        <div style="position: absolute; top: 0px; left: 510px; font-weight: bold">[变更后模型]</div>
        <div v-if="ifcContainerVisible1" ref="ifcContainer1" style="width: 485px; height: 485px; padding-top: 10px; display:inline-block"></div>
        <div v-if="ifcContainerVisible2" ref="ifcContainer2" style="width: 485px; height: 485px; padding-top: 10px; display:inline-block"></div>
      </div>
    </template>
  </vxe-modal>
</template>
<script>
import Cookies from 'js-cookie'

export default {
  name: 'IfcCompareViewer',
  data () {
    return {
      dataForm: {
        prjId: '',
        refId: '',
      },
      visible: false,
      position: {
        top: 60,
        left: `calc(100% - 1100px)`
      },
      typeIdBefore: '变更前模型', // 附件类型
      typeIdAfter: '变更后模型',
      loading: null,
      ifcContainerVisible1: false,
      ifcContainerVisible2: false,
      dialogTitle: '模型对比',
      ifcInstance1: null,
      ifcInstance2: null,
    }
  },
  mounted () {
    this.ifcContainerVisible1 = true
    this.ifcContainerVisible2 = true
    this.$nextTick(() => {
      
    })
  },
  async beforeDestroy () {
    if(this.ifcInstance1) {
      await this.ifcInstance1.releaseMemory()
    }
    if(this.ifcInstance2) {
      await this.ifcInstance2.releaseMemory()
    }
  },
  methods: {
    init (refId, typeIdBefore, typeIdAfter) {
      if (typeIdBefore) {
        this.typeIdBefore = typeIdBefore
      }
      if (typeIdAfter) {
        this.typeIdAfter = typeIdAfter
      }
      this.dataForm.refId = refId
      this.visible = true
      this.$nextTick(async () => {
        if (!this.ifcInstance1) {
          this.ifcInstance1 = new threeIfcScene.Instance({
            ele: this.$refs.ifcContainer1,
            width: 485,
            height: 485,
            startLoading: (txt)=>{
              this.loading = this.$loading({
                lock: true,
                text: txt,
                spinner: 'el-icon-loading',
                customClass: 'my-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              })
            },
            stopLoading: ()=>{
              this.loading.close()
            },
            onPickCallback: (expressId, props, propSets)=>{
              this.openPropModal(expressId, props, propSets)
            }
          });

          this.ifcInstance2 = new threeIfcScene.Instance({
            ele: this.$refs.ifcContainer2,
            width: 485,
            height: 485,
            startLoading: (txt)=>{
              this.loading = this.$loading({
                lock: true,
                text: txt,
                spinner: 'el-icon-loading',
                customClass: 'my-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              })
            },
            stopLoading: ()=>{
              this.loading.close()
            },
            onPickCallback: (expressId, props, propSets)=>{
              this.openPropModal(expressId, props, propSets)
            }
          });
        }

        this.loading = this.$loading({
          text: '',
          spinner: "aircity-loading",
          customClass: 'aircity-loading',
          background: 'rgba(0, 0, 0, 0.4)'
        })
        
        // 从oss服务器获取最新的ifc文件地址URL
        let {data: before} = await this.$http.get(`/oss/file/list/${this.dataForm.refId}?typeId=${this.typeIdBefore}`)
        let {data: after} = await this.$http.get(`/oss/file/list/${this.dataForm.refId}?typeId=${this.typeIdAfter}`)
        if (before.code !== 0) {
          this.loading.close()
          return this.$message.error(before.msg)
        }
        if (after.code !== 0) {
          this.loading.close()
          return this.$message.error(after.msg)
        }
        let beforeIfcUrl = before.data && before.data.length > 0 ? before.data[0].url : ''
        let afterIfcUrl = after.data && after.data.length > 0 ? after.data[0].url : ''
        let waringMsg = ''
        if (!beforeIfcUrl) {
          waringMsg = '未上传变更前模型文件,'
        }
        if (!afterIfcUrl) {
          waringMsg = waringMsg + '未上传变更后模型文件'
        }
        // 渲染模型
        await this.renderModel(beforeIfcUrl, afterIfcUrl)
        if (waringMsg) {
          this.$message.warning(waringMsg)
        }
      })
    },

    /**
     * 渲染模型
     *
     * @param beforeIfcSignedUrl 变更前模型地址（已签名）
     * @param afterIfcSignedUrl 变更后模型地址（已签名）
     */
    async renderModel (beforeIfcSignedUrl, afterIfcSignedUrl) {
      if (beforeIfcSignedUrl || afterIfcSignedUrl) {
        if (beforeIfcSignedUrl) {
          await this.ifcInstance1.loadIfc(beforeIfcSignedUrl, null, () => {
            if (this.loading) {
              this.loading.close()
            }
          })
        }
        if (afterIfcSignedUrl) {
          await this.ifcInstance2.loadIfc(afterIfcSignedUrl, null, () => {
            if (this.loading) {
              this.loading.close()
            }
          })
        }
      } else {
        if (this.loading) {
          this.loading.close()
        }
      }
    },
  }
}
</script>
