<template>
<div class="my-container-box" :class="uuid">
  <div class="my-container-box-left" :class="uuid" v-bind:style="leftStyle">
    <!--左侧div内容-->
    <slot name="left"></slot>
  </div>
  <div class="my-container-box-handler" :class="uuid" title="左右拖动">
    ⋮
  </div>
  <div class="my-container-box-right" :class="uuid" v-bind:style="rightStyle">
    <!--右侧div内容-->
    <slot name="right"></slot>
  </div>
</div>
</template>
<script>
import { getUUID } from '@/utils'
export default {
  name: 'MyContainer',
  data() {
    return {
      leftStyle: {
        width: this.leftWidth + 'px',
        height: '100%'
      },
      // 为了防止根据样式选择器绑定的事件在不同页面中冲突
      uuid: getUUID()
    }
  },
  props: {
    leftWidth: {
      type: [String, Number],
      default: 270,
      required: false
    },
  },
  computed: {
    rightStyle: function () {
      return {width: `calc(100% - ${this.leftWidth + 10}px)`, height: '100%'}
    }
  },
  mounted() {
    // 绑定拖拽调整宽度的事件
    this.bindWidthResizeEvent()

    // 窗口尺寸改变时动态调整右侧窗口宽度
    var box = document.getElementsByClassName(`${this.uuid} my-container-box`)
    var left = document.getElementsByClassName(`${this.uuid} my-container-box-left`)
    var right = document.getElementsByClassName(`${this.uuid} my-container-box-right`)
    if((!box || box.length == 0)
      || (!left || left.length == 0)
      || (!right || right.length == 0)) {
      return
    }
    // 仅支持一个容器
    (function(box, left, right){          
      $(window).resize((e) => {
        right.style.width = (box.clientWidth - left.offsetWidth - 10) + 'px'
      })
    })(box[0], left[0], right[0])

    // 设置操作按钮的上下位置
    let clientHeight = document.documentElement.clientHeight
    document.getElementsByClassName(`${this.uuid} my-container-box-handler`)[0].style.top = clientHeight * 0.40 + 'px'
  },
  methods: {
    bindWidthResizeEvent: function () {
      // 左边区域的最小宽度为50px
      const leftMinWidth = 50
      var box = document.getElementsByClassName(`${this.uuid} my-container-box`)
      var left = document.getElementsByClassName(`${this.uuid} my-container-box-left`)
      var handler = document.getElementsByClassName(`${this.uuid} my-container-box-handler`)
      var right = document.getElementsByClassName(`${this.uuid} my-container-box-right`)

      if((!box || box.length == 0)
        || (!left || left.length == 0)
        || (!handler || handler.length == 0)
        || (!right || right.length == 0)) {
        return
      }
      // 仅支持一个容器
      box = box[0]
      left = left[0]
      handler = handler[0]
      right = right[0]

      // 鼠标按下事件
      handler.onmousedown = function (e) {
        // 颜色改变提醒
        handler.style.background = '#002240'
        var startX = e.clientX
        handler.left = handler.offsetLeft
        // 鼠标拖动事件
        document.onmousemove = function (e) {
          var endX = e.clientX;
          var moveLen = handler.left + (endX - startX) - 13
          var maxT = box.clientWidth - handler.offsetWidth // 容器宽度 - 左边区域的宽度 = 右边区域的宽度
          if (moveLen < leftMinWidth) moveLen = leftMinWidth; // 左边区域的最小宽度
          if (moveLen > maxT - 500) moveLen = maxT - 500 //右边区域最小宽度
          handler.style.left = moveLen // 设置左侧区域的宽度
          
          left.style.width = moveLen + 'px'
          right.style.width = (box.clientWidth - moveLen - 20) + 'px'
        }

        // 鼠标松开事件
        document.onmouseup = function (evt) {
          // 颜色恢复
          handler.style.background = '#002240'
          document.onmousemove = null
          document.onmouseup = null
          handler.releaseCapture && handler.releaseCapture() //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
        }
        handler.setCapture && handler.setCapture() //该函数在属于当前线程的指定窗口里设置鼠标捕获
        return false
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.my-container-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // box-shadow: -1px 9px 10px 3px rgba(0, 0, 0, 0.11);
}

/*左侧样式*/
.my-container-box-left {
  // background: #FFFFFF;
  float: left;
  box-shadow: -1px 4px 5px 3px rgba(0, 0, 0, 0.11);
}

/*拖拽区样式*/
.my-container-box-handler {
  margin-right: -2px;
  cursor: col-resize;
  float: left;
  position: relative;
  top: 45%;
  background-color: #002240;
  border-radius: 0 20px 20px 0;
  // margin-top: -10px;
  line-height: 35px;
  width: 6px;
  height: 35px;
  background-size: cover;
  background-position: center;
  /*z-index: 99999;*/
  font-size: 22px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*拖拽区鼠标悬停样式*/
.my-container-box-handler:hover {
  color: #409EFF;
}

/*右侧样式*/
.my-container-box-right {
  float: left;
  // width: calc(100% - 320px);
  // height: 100%;
  // background: #fff;
  // box-shadow: -1px 4px 5px 3px rgba(0, 0, 0, 0.11);
}
</style>
