<template>
  <el-tooltip placement="bottom-start" :disabled="tooltipDisabled" :content="tooltip" effect="light" :enterable="enterable" :open-delay="1000">
    <el-select style="width: 100%" v-model="selectedId" :loading="loading" :placeholder="(!placeholder && disabled) ? '' : placeholder" :disabled="disabled" filterable :clearable="clearable">
      <el-option v-for="opt in dataList" :label="opt.name" :key="opt.id" :value="opt.id"></el-option>
    </el-select>
  </el-tooltip>
</template>
<script>
export default {
  name: 'SubcontractorSelect',
  data () {
    return {
      dataList: [],
      tooltip: '请选择',
      enterable: false,
      tooltipDisabled: false,
      selectedId: this.value,
      loading: false,
    }
  },
  created: function () {
    this.getDataList()
  },
  watch: {
    // value改变时，修改tooltip的值
    value: function(data) {
      let item = this.getItemById(data)
      this.tooltip = item ? item.name : '请选择'
      this.selectedId = data
    },
    contractId: function() {
      this.dataList = []
      this.selectedId = ''
      if (this.contractId) {
        this.getDataList()
      }
    },
    selectedId: function(data) {
      // 触发input事件完成v-model的双向绑定
      this.$emit('input', data)
      this.$emit('change', this.getItemById(data))
    }
  },
  computed: {
  },
  props: {
    value: String,
    contractId: {
      type: String,
      required: true
    },
    placeholder: String,
    disabled: Boolean,
    clearable: Boolean,
    /*
     * 查询类型 (不传此参数则查询项目下权限内合同标段)
     * all: 查询项目下的所有合同标段
     */
    type: String,
    // 是否默认选中第一个
    defaultSelect: false,
    // 是否自动追加一个“全部”选项
    appendAllOption: false
  },
  methods: {
    getDataList() {
      this.loading = true
      let url = ''
      if (this.type && this.type == 'all') {
        url = '/mps/subcontractor/openapi/briefs'
      } else {
        url = '/mps/subcontractor/openapi/briefsForUser'
      }
      this.$http.get(
          url,
          {
            params: {
              contractId: this.contractId,
              appendAllOption: this.appendAllOption
            }
          }
      ).then(({ data: res }) => {
        if (res.code === 0) {
          this.dataList = res.data
          // 如果selectedId包含在数据列中，则不对selectedId进行处理（对应了向组件传入默认值的情况）
          // 除上述情况外，根据defaultSelect属性决定是默认选中第一项，或是将selectedId置为''
          let exist = false
          if (this.selectedId && this.dataList && this.dataList.length > 0) {
            for (let obj of this.dataList) {
              if (this.selectedId == obj.id) {
                exist = true
                break;
              }
            }
          }
          if (!exist) {
            if (this.defaultSelect && this.dataList.length > 0) {
              // 控制el-select选中
              this.selectedId = this.dataList[0].id
            } else {
              this.selectedId = ''
            }
          }
        }
        this.loading = false
      }).catch(() => {
        // do nothing
      })
    },
    getItemById(id) {
      let item = this.dataList.find((item) => {
        return item.id === id
      })
      if (item) {
        this.tooltipDisabled = false
        return item
      } else {
        if (this.disabled) {
          this.tooltipDisabled = true
        }
        return null
      }
    },
    // 反向更新数据
    updateData(id, key, value) {
      let item = this.getItemById(id)
      if (item) item[key] = value
    }
  }
}
</script>
