<template>
  <el-tooltip placement="bottom-start" :disabled="tooltipDisabled" :content="tooltip" effect="light" :enterable="enterable" :open-delay="1000">
    <el-select style="width: 100%" v-model="selectedId" :loading="loading" :placeholder="(!placeholder && disabled) ? '' : placeholder" :disabled="disabled" filterable :clearable="clearable">
      <el-option v-for="opt in dataList" :label="opt.name" :key="opt.id" :value="opt.id"></el-option>
    </el-select>
  </el-tooltip>
</template>
<script>
export default {
  name: 'PeriodSelect',
  data () {
    return {
      dataList: [],
      tooltip: '请选择',
      enterable: false,
      tooltipDisabled: false,
      selectedId: this.value,
      loading: false,
    }
  },
  created: function () {
    this.getDataList()
  },
  watch: {
    // value改变时，修改tooltip的值
    value: function(data) {
      let item = this.getItemById(data)
      this.tooltip = item ? item.name : '请选择'
      this.selectedId = data
    },
    contractId: function() {
      this.dataList = []
      this.selectedId = ''
      if (this.contractId) {
        this.getDataList()
      }
    },
    selectedId: function(data) {
      // 触发input事件完成v-model的双向绑定
      this.$emit('input', data)
      this.$emit('change', this.getItemById(data))
    }
  },
  props: {
    value: String,
    contractId: String,
    placeholder: String,
    disabled: Boolean,
    clearable: Boolean,
    // 控制是否只选择计量审批通过的期次
    hasMeas: Boolean,
    // 是否默认选中第一个或默认期次
    defaultSelect: Boolean
  },
  methods: {
    getDataList() {
      this.loading = true
      let url
      if (this.hasMeas) {
        url = '/mps/period/passedMeas'
      } else {
        url = '/mps/period/briefs'
      }
      this.$http.get(url, {
          params: {
            contractId: this.contractId,
          }
        }
      ).then(({ data: res }) => {
        if (res.code === 0) {
          this.dataList = res.data
          if (this.defaultSelect && this.dataList.length > 0) {
            // 如果集合中包含双向绑定的ID，则不需要运行后面的默认选中逻辑
            let idExist = false
            if (this.selectedId) {
              for (let obj of this.dataList) {
                if (this.selectedId == obj.id) {
                  idExist = true
                  break;
                }
              }
            }
            if (!idExist) {
              // 控制el-select选中
              let activePeriod = this.dataList.find((period) => {
                return period.isActive === 1 // 默认选中当前期次
              })
              if (activePeriod) {
                this.selectedId = activePeriod.id
              } else {
                if (this.dataList.length == 1) {// 如果只有一个，也默认选中
                  this.selectedId = this.dataList[0].id
                } else {
                  this.selectedId = ''
                }
              }
            }
          }
        }
        this.loading = false
      }).catch()
    },
    getItemById(id) {
      let item = this.dataList.find((item) => {
        return item.id === id
      })
      if (item) {
        this.tooltipDisabled = false
        return item
      } else {
        if (this.disabled) {
          this.tooltipDisabled = true
        }
        return null
      }
    }
  }
}
</script>
