<template>
  <el-tooltip placement="bottom-start" :disabled="tooltipDisabled" :content="tooltip" effect="light" :enterable="enterable" :open-delay="1000">
    <el-select :style="{width: width}" v-show="show" v-model="selectedId" :dataSourceUrl="dataSourceUrl" :placeholder="(!placeholder && disabled) ? '' : placeholder" :disabled="disabled" filterable :clearable="clearable">
      <el-option v-for="opt in dataList" :label="opt.name" :key="opt.id" :value="opt.id"></el-option>
    </el-select>
  </el-tooltip>
</template>
<script>
export default {
  name: 'PreProjectSelect',
  data () {
    return {
      dataList: [],
      tooltip: '请选择',
      enterable: false,
      tooltipDisabled: false,
      selectedId: this.value,
      show: true,
    }
  },
  created: function () {
    let url = this.dataSourceUrl
    this.$http.get(url).then(({ data: res }) => {
      if (res.code === 0) {
        this.dataList = res.data
        // 该组件支持是否默认选中（可通过defaultSelect属性控制）
        if (this.defaultSelect && this.dataList.length > 0) {
          // 若只有一个选项，则选中并隐藏此组件
          if (this.dataList.length == 1) {
            this.selectedId = this.dataList[0].id
            this.show = false
            // 触发hide事件，便于引用此组件的地方做出对应的处理
            this.$emit('hide')
          } else {
            // 如果全局变量存了前期项目Id(prePrjId)，则默认选中之前的id；如果没有，则选中第一项
            let globalPrePrjId = localStorage.getItem('prePrjId')
            if (globalPrePrjId && this.getItemById(globalPrePrjId)) {
              this.selectedId = globalPrePrjId
            } else {
              // 控制el-select选中
              this.selectedId = this.dataList[0].id
            }
          }
        }
      }
    }).catch()
  },
  watch: {
    // value改变时，修改selectedId和tooltip的值
    value: function (data) {
      let item = this.getItemById(data)
      this.tooltip = item ? item.name : '请选择'
      this.selectedId = data
    },
    selectedId: function(data) {
      // 缓存前期项目Id(prePrjId)
      localStorage.setItem('prePrjId', data);
      // 触发input事件完成v-model的双向绑定
      this.$emit('input', data)
      this.$emit('change', this.getItemById(data))
    }
  },
  
  props: {
    value: String,
    placeholder: String,
    disabled: Boolean,
    clearable: Boolean,
    // 默认查询租户内的前期项目，可根据具体业务功能点自己指定其他的url接口
    dataSourceUrl: {
      type: String,
      default: '/opm/preProject/briefs'
    },
    // 是否默认选中第一个
    defaultSelect: false,
    width: {
      type: String,
      default: '300px'
    },
  },
  methods: {
    getItemById (id) {
      let item = this.dataList.find((item) => {
        return item.id === id
      })
      if (item) {
        this.tooltipDisabled = false
        return item
      } else {
        if (this.disabled) {
          this.tooltipDisabled = true
        }
        return null
      }
    }
  }
}
</script>
