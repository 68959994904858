/*
功能：
    图表大小自适应组件
实现原理：
    使用echart + element-resize-detector插件实现，要求vue是2.X版本
使用说明：
    渲染 Echarts 的初始化容器元素上绑定 v-echart-resize 指令即可。
    例如：<div id="measScheChart"  v-echart-resize/>
 */
const echarts = require('echarts')
import Vue from 'vue'
import elementResizeDetectorMaker from 'element-resize-detector'

export var version = '0.0.1'
var compatible = /^2\./.test(Vue.version)
if (!compatible) {
  Vue.util.warn(
    'vue echarts resize directive ' + version + ' only supports Vue 2.x, and does not support Vue ' + Vue.version
  )
}
const HANDLER = '_vue_echarts_resize_handler'

function bind(el) {
  unbind(el)
  el[HANDLER] = function () {
    const chart = echarts.getInstanceByDom(el)
    if (!chart) {
      return
    }
    chart.resize()
  }
  // 监听绑定的div大小变化，更新echarts大小
  elementResizeDetectorMaker().listenTo(el, el[HANDLER])
}
function unbind(el) {
  elementResizeDetectorMaker().removeListener(el, el[HANDLER])
  delete el[HANDLER]
}
export default {
  bind,
  unbind
}

