<template>
  <el-dialog destroy-on-close title="委派" :visible.sync="show" width="500px" v-bind="$attrs" v-on="$listeners" @close="handleCancel">
    <el-form v-loading="loading" ref="formRef" :model="formValue" :rules="rules">
      <el-form-item label="请选择委派给谁" prop="delegateUserId">
        <el-tooltip content="被委派人完成后，该流程仍需要您再次办理。" placement="top-start" effect="light">
          <el-button size="mini" @click="showUserPicker"><i class="el-icon-user"></i> 选择人员</el-button>
        </el-tooltip>
        <el-tag v-if="userName" :style="{ marginLeft: '10px' }" size="small">{{ userName }}</el-tag>
      </el-form-item>
      <el-form-item prop="comments" required>
        <el-input type="textarea" v-model="formValue.comments" placeholder="请填写委派意见" maxlength="255" rows="4" show-word-limit/>
      </el-form-item>
      <file-upload :refId="processInfo.taskId"></file-upload>
    </el-form>
    <template #footer>
      <el-button size="mini" @click="handleCancel">取 消</el-button>
      <el-button size="mini" type="primary" :loading="loading" @click="handleConfirm">确 定</el-button>
    </template>
    <user-multi-select v-if="userMultiSelectVisible" ref="userMultiSelect" single-select @close="closeDialogHandle" @submitAllData="userMultiSelectSubmit"></user-multi-select>
  </el-dialog>
</template>

<script>
import FileUpload from "./FileUpload";
export default {
  components: {FileUpload },
  name: "DelegateModal",
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false,
    },
    processInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      formValue: {
        comments: "",
        delegateUserId: ''
      },
      userName: '',
      userMultiSelectVisible: false,
      rules: {
        delegateUserId: [
          { required: true, message: "请选择委派人", trigger: "change" },
        ],
        comments: [
          { required: true, message: "请填写委派意见", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      },
    },
  },
  methods: {
    // 确认操作
    handleConfirm() {
      this.$refs.formRef.validate((valid) => {
        if (!valid) return
        const params = {
          ...this.processInfo,
          ...this.formValue,
          currentUserInfo: this.$store.state.user
        }
        this.loading = true

        this.$http.post(`/flowable/process/delegateTask`, params).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success("委派成功")
          this.handleCancel()
          this.$emit("success")
        }).catch(e => {
          return this.$message.error("运行出错：" + e)
        }).finally(() => {
          this.loading = false
        })
      });
    },
    // 取消操作
    handleCancel() {
      this.$refs.formRef.resetFields();
      this.formValue = {
        comments: "",
        delegateUserId: ''
      };
      this.userName = '';
      this.show = false;
    },
    // 显示用户选择器
    showUserPicker() {
      this.userMultiSelectVisible = true;
      this.$nextTick(() => {
        this.$refs.userMultiSelect.init();
      })
    },
    closeDialogHandle() {
      this.userMultiSelectVisible = false;
    },
    // 选择用户确认操作
    userMultiSelectSubmit(users) {
      let [user] = users;
      if (user.id === this.$store.state.user.id) {
        this.$message.error("不允许选择本人");
        return;
      }
      this.formValue.delegateUserId = user.id;
      this.userName = user.realName;
    },
  },
};
</script>
