<template>
  <vxe-pulldown ref="xDown" transfer style="width: 100%">
    <template #default>
      <el-tooltip placement="top-start" :content="name" effect="light" :enterable="false" :open-delay="500">
        <vxe-input v-model="name" style="width: 100%;" size="medium" suffix-icon="vxe-icon-search" :placeholder="placeholder" @click="showDropdown" :clearable="clearable && !readonly" @clear="onClear" readonly></vxe-input>
      </el-tooltip>
    </template>
    <template #dropdown>
      <div class="select-dropdown">
        <vxe-table
          height="auto"
          show-overflow
          :row-config="{isHover: true, isCurrent: true, useKey: true}"
          :column-config="{resizable: true}"
          :tree-config="treeConfig"
          :data="tableData"
          size="mini"
          @cell-click="cellClickEvent"
          :scroll-y="{enabled: false}">
          <vxe-column field="name" min-width="200" title="名称" tree-node></vxe-column>
          <vxe-column field="unitName" title="单位" min-width="80"></vxe-column>
        </vxe-table>
      </div>
    </template>
  </vxe-pulldown>
</template>
<script>
export default {
  name: 'ScheduleunitSingleSelect',
  data () {
    return {
      treeConfig: {
        transform: true,
        rowField: 'id',
        parentField: 'typeId',
        line: true,
        lazy: false,// 形象单元数据量较小，直接采用同步加载即可
        hasChild: 'hasChildren',
        iconOpen: 'vxe-icon-square-minus',
        iconClose: 'vxe-icon-square-plus',
        trigger:'row'
      },
      tableData: [],
      name: '',
    }
  },
  created: function () {
    this.$http.get(
        '/opm/scheduleUnit/findChildren',
        {
          params: {
            prjId: this.prjId
          }
        }
    ).then(({ data: res }) => {
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.tableData = res.data
      // 查询好后台数据后进行数据回显
      this.name = this.getNameById(this.value)
    }).catch(() => {})
  },
  props: {
    value: String,
    placeholder: String,
    readonly: Boolean,
    // 默认不提供清空按钮，因为在目前后台系统的框架下，一个Long类型的字段在原本有值的情况下，无法将此字段清空并保存至数据库
    clearable: {
      type: Boolean,
      default: false
    },
    prjId: {
      type: [String, Number],
      required: true
    },
  },
  watch: {
    // 数据回显
    value: function (data) {
      if (this.tableData.length > 0) {
        this.name = this.getNameById(this.value)
      } else {
        this.name = ''
      }
    },
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  methods: {
    showDropdown() {
      if (!this.readonly) {
        this.$refs.xDown.showPanel()
      }
    },
    cellClickEvent({ row, triggerTreeNode }) {
      // 只允许选中叶子节点
      if (!triggerTreeNode && !row.hasChildren) {
        this.name = row.name
        this.$emit('change', row.id)
        this.$emit('changeScheduleUnit', row)
        this.$refs.xDown.hidePanel()
      }
    },
    onClear() {
      this.$emit('change', '')
      this.$emit('changeScheduleUnit', {})
    },
    getNameById(id) {
      if (id) {
        let row = this.tableData.find(obj => obj.id == id)
        if (row) {
          return row.name
        } else {
          return ''
        }
      } else {
        return ''
      }

    }
  },
}
</script>
<style scoped>
.select-dropdown {
  height: 300px;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}
</style>
