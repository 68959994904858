<template>
  <el-select style="width: 100%" v-model="selectedId" :loading="loading" filterable :placeholder="(!placeholder && disabled) ? '' : placeholder" :disabled="disabled" :clearable="clearable">
    <el-option v-for="opt in dataList" :label="opt.name" :key="opt.id" :value="opt.id"></el-option>
  </el-select>
</template>
<script>
export default {
  name: 'UnitSelect',
  data () {
    return {
      dataList: [],
      selectedId: this.value,
      loading: false,
    }
  },
  created: function () {
    this.getDataList()
  },
  watch: {
    value: function(data) {
      this.selectedId = data
    },
    contractId: function() {
      this.getDataList()
    },
    prjId: function() {
      this.getDataList()
    },
    selectedId: function(data) {
      // 触发input事件完成v-model的双向绑定
      this.$emit('input', data)
      // 因为在表单中，可能unitId和prjId是同时从后台获取的，
      // 在这种情况下，selectedId被改变时，此组件刚刚获取到prjId或contractId，并从后台获取选项，这时候不应该触发change事件
      // 因此此处加了一个判断
      if (this.dataList.length > 0) {
        this.$emit('change', this.getItemById(data))
      }
    }
  },
  computed: {
  },
  props: {
    value: String,
    contractId: String,
    prjId: String,
    placeholder: String,
    disabled: Boolean,
    clearable: Boolean,
  },
  methods: {
    getDataList() {
      this.dataList = []
      this.loading = true
      let params
      if (this.prjId) {
        params = {
          prjId: this.prjId
        }
      } else if (this.contractId) {
        params = {
          contractId: this.contractId
        }
      } else {
        return
      }
      this.$http.get(
          '/mps/unit/selectDataSource',
          {
            params: params
          }
      ).then(({ data: res }) => {
        if (res.code === 0) {
          this.dataList = res.data
        }
        this.loading = false
      }).catch(() => {
        // do nothing
      })
    },
    getItemById(id) {
      let item = this.dataList.find((item) => {
        return item.id === id
      })
      if (item) {
        return item
      } else {
        return null
      }
    },
  }
}
</script>
