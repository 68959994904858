export function moveRange(opt) {
  const allHeight = (CanvasZoom.height + CanvasZoom.space) * opt.pageSize
  if (opt.x < 0) {
    opt.x = 0
  } else if (opt.x > CanvasZoom.width - opt.size.width) {
    opt.x = CanvasZoom.width - opt.size.width
  }
  if (opt.y < CanvasZoom.space) {
    opt.y = CanvasZoom.space
  } else if (opt.y > allHeight) {
    opt.y = allHeight
  }
  currentPage(opt)
  isControlPage(opt, allHeight)
}

export const CanvasZoom = {
  space: 16,
  width: 800,
  originWidth: 800,
  maxWidth: 1000,
  height: 1131,
  zoom: 100
}

//计算控件所在每页的位置
export function currentPosition(top, pageSize) {
  return top - (pageSize + 1) * CanvasZoom.space - pageSize * CanvasZoom.height
}

//将每页的位置转换成为一大页的位置
export function currentPositionReverse(top, pageSize) {
  return top + (pageSize + 1) * CanvasZoom.space + pageSize * CanvasZoom.height
}

//计算控件所在的页面
function currentPage(opt) {
  for (let i = 0; i < opt.pageSize; i++) {
    //计算控件是否再空白区域
    let start = (i + 1) * CanvasZoom.space + i * CanvasZoom.height
    let end = (i + 2) * CanvasZoom.space + (i + 1) * CanvasZoom.height
    if (opt.y >= start && opt.y <= end) {
      opt.currentPage = i
      break
    }
  }
}

//判断控件是否再边界上
function isControlPage(opt, allHeight) {
  //var temHeight = 0
  let start = (opt.currentPage + 1) * CanvasZoom.space + opt.currentPage * CanvasZoom.height
  let end = (opt.currentPage + 1) * CanvasZoom.space + (opt.currentPage + 1) * CanvasZoom.height
  let controllerBottom = opt.y + opt.size.height
  if (opt.y >= start && controllerBottom <= end) {
    return
  }
  if (controllerBottom > allHeight) {
    opt.y = allHeight - opt.size.height
    return
  }

  let outSize = controllerBottom - end

  if (outSize > opt.size.height / 2) {
    opt.currentPage += 1
    opt.y = end + CanvasZoom.space
  } else {
    opt.y = end - opt.size.height
  }
}